const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Witaj,',
  [k.ENTER_NEW_PASSWORD]: 'Wprowadź tutaj swoje nowe hasło. Zostaniesz automatycznie zalogowany i przekierowany.',
  [k.MINIMUM_6_CHARACTERS]: 'Wpisz co najmniej 6 znaków.',
  [k.SAVE]: 'Zapisz',
  [k.UNABLE_TO_RESET_PASSWORD]: 'Nie udało nam się zresetować Twojego hasła.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Hasło zostało pomyślnie zmienione',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Twoje hasło zostało zmienione! Możesz teraz zalogować się przy użyciu nowego hasła. Przekierowujemy Cię teraz dalej.',
  [k.FUNCTIONS]: 'Funkcje',
  [k.DESIGNS]: 'Motywy',
  [k.PRICES]: 'Ceny',
  [k.FAQ]: 'FAQ',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Stwórz teraz za darmo',
  [k.PERFECT_PAGE_FOR_YOU]: 'Twoja strona jest dostosowana do Ciebie!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: 'Osiągnij idealny projekt za pomocą kilku kliknięć dzięki naszemu konfiguratorowi.',
  [k.DESIGN]: '-Design',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'Jak opisałbyś ślub swoich marzeń?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Kliknij na jeden z obrazków, aby uruchomić konfigurator projektu',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Stwórz teraz za darmo',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Często zadawane pytania',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Czy masz jakieś pytania lub uwagi?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Czekamy na wiadomość od Ciebie!',
  [k.CONTENT_AND_DESIGN]: 'ZAWARTOŚĆ I PROJEKT',
  [k.PHOTO_GALLERY]: 'Galerie zdjęć',
  [k.RSVP_CARD]: 'RSVP/karta odpowiedzi',
  [k.PLAYLIST]: 'Playlista',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'WIELOJĘZYCZNOŚĆ I WŁASNA DOMENA',
  [k.DURATION_PASSWORD_PROTECTION]: 'CZAS DZIAŁANIA I OCHRONA HASŁEM',
  [k.COST_SUPPORT]: 'KOSZTY I WSPARCIE',
  [k.WHAT_CONTENT_TYPICALLY]: 'Jakie treści są typowe dla strony ślubnej?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Czy mogę stworzyć stronę ślubną zanim wszystkie szczegóły (takie jak data ślubu) zostaną sfinalizowane?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Czy mogę dostosować wygląd mojej strony ślubnej?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    'Czy możliwe jest wprowadzanie zmian na stronie głównej bez ich natychmiastowej widoczności dla wszystkich?',
  [k.PHOTO_UPLOAD_LIMIT]: 'Czy istnieje ograniczenie liczby zdjęć, które możemy przesłać do galerii?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'Czy zdjęcia będą zapisywane w oryginalnym rozmiarze?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Czy goście mogą pobierać zdjęcia przesłane na stronę?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]:
    'Czy możemy użyć galerii zdjęć do pokazu slajdów na żywo podczas naszej imprezy?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'Jak działa funkcja RSVP?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Czy goście mogą dodawać własne utwory do listy odtwarzania?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: 'Czy nasz DJ może mieć dostęp do listy odtwarzania?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'Czy możliwe jest posiadanie strony głównej w różnych językach?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Czy możemy uzyskać własną domenę dla naszej strony ślubnej?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'Jak długo nasza strona ślubna będzie dostępna?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Czy możemy zabezpieczyć naszą stronę hasłem?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'Czy korzystanie z EureHochzeitOnline.com jest bezpłatne?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    'W jaki sposób mogę uzyskać pomoc w tworzeniu strony ślubnej na EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    'Zawartość Twojej strony ślubnej jest tak indywidualna, jak sam ślub. Oprócz podstawowych informacji, takich jak data i lokalizacja, możesz dodać kody ubioru, wskazówki, specjalne życzenia, dane kontaktowe urzędnika lub planowaną oś czasu. Dla gości, którzy podróżują z daleka i potrzebują zakwaterowania, możesz podać propozycje hoteli. Świetnym pomysłem jest również podzielenie się z gośćmi swoją historią miłosną lub napisanie osobistych słów o swoim partnerze. Twoja kreatywność nie ma granic.',
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Tak, jest to łatwe do zrobienia, a nawet oferuje znaczące korzyści. Możesz dodawać informacje i dostarczać aktualizacje w dowolnym momencie, nawet po wysłaniu zaproszeń ślubnych.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Tak, EureHochzeitOnline.com oferuje różnorodne opcje projektowania Twojej strony ślubnej. Możesz dostosować kolory, czcionki i dodatkowe elementy. Skontaktuj się z nami, jeśli masz jakieś pytania lub uwagi.',
  [k.INDEED_CHANGES_VISIBLE]:
    'W rzeczywistości wszystkie zmiany w Twojej witrynie będą natychmiast widoczne dla wszystkich odwiedzających w czasie rzeczywistym. Obecnie nie oferujemy funkcji planowania lub ukrywania zmian z wyprzedzeniem.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'W darmowej wersji istnieje ograniczona liczba zdjęć, które możesz przesłać do swojej galerii. Wynika to z kosztów związanych z przechowywaniem i ruchem. Jeśli jednak zakupisz pakiety Premium lub Domain, będziesz mógł przesłać nieograniczoną liczbę zdjęć do swojej galerii. Zapoznaj się z naszym blogiem, aby uzyskać wskazówki, jak uzyskać idealne zdjęcia ślubne.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Kiedy przesyłasz swoje zdjęcia, tworzymy różne rozmiary dla każdego obrazu. W zależności od rozmiaru ekranu odwiedzającego, automatycznie wybierany jest optymalny rozmiar, aby zapewnić szybki czas ładowania przy najlepszej możliwej jakości obrazu. Zapisujemy jednak również oryginalne pliki zdjęć, abyś mógł je również otrzymać.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Tak, goście mogą pobierać przesłane zdjęcia.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Jak najbardziej! W rzeczywistości jest to jedna z najpopularniejszych funkcji EureHochzeitOnline. Wszystko, czego potrzebujesz, to stabilne połączenie internetowe i oczywiście ekran lub projektor.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'Funkcja RSVP ułatwia udostępnianie gościom danych logowania do Twojej strony ślubnej. Gdy odwiedzą oni Twoją stronę ślubną, zostaną poproszeni o pozostawienie swojej opinii (np. ile osób będzie uczestniczyć w weselu, czy są to dzieci czy dorośli, a także wszelkie specjalne życzenia dotyczące jedzenia, alergie lub inne wymagania). W strefie dla gości możesz w dowolnym momencie śledzić liczbę zaakceptowanych i anulowanych rezerwacji. Instrukcje krok po kroku znajdziesz na naszym blogu.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Absolutnie! Twoi goście mogą zasugerować swoje ulubione piosenki i dodać je do listy odtwarzania. Możesz więc nie tylko celebrować różnorodność swoich gości, ale także cieszyć się muzyką, która jest ważna dla Ciebie i Twoich bliskich.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Oczywiście. Po prostu zaproś DJ-a na swoją stronę internetową, a wszystkie Twoje życzenia będą do jego dyspozycji - nawet na żywo podczas imprezy, jeśli chcesz.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Tak, możesz mieć swoją stronę w wielu językach. Ta funkcja jest dostępna w pakiecie Premium i możesz przetłumaczyć wszystkie informacje na różne języki. Skontaktuj się z nami, abyśmy mogli przygotować konfigurację dla Twojego wesela.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Tak, możesz łatwo zarezerwować własną domenę (np. www.juliaundromeo.de) dla swojej strony ślubnej z naszym pakietem Premium. Skontaktuj się z nami, abyśmy mogli zarezerwować dla Ciebie domenę i skonfigurować przekierowanie.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'Twoja strona ślubna będzie dostępna przez dwa lata po jej utworzeniu. Po tym czasie zostanie usunięta, aby zrobić miejsce dla nowych stron ślubnych. Przypomnimy Ci o tym z wyprzedzeniem. Odnowienia są dostępne na żądanie.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Wszystkie strony ślubne są chronione hasłem, które zostanie Ci pokazane po ich utworzeniu.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Tak, możesz stworzyć i zaprojektować stronę ślubną na EureHochzeitOnline.com za darmo. Jednak niektóre zaawansowane funkcje nie są darmowe, zwłaszcza te, które wymagają dodatkowych zasobów serwera. Nie ma żadnych ukrytych opłat.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'Jeśli potrzebujesz pomocy przy tworzeniu strony ślubnej, wyślij nam wiadomość na adres beeni@eurehochzeitonline.com. Skontaktujemy się z Tobą tak szybko, jak to możliwe, aby Ci pomóc.',
  [k.KEY_FEATURES_OVERVIEW]: 'Najważniejsze funkcje w skrócie!',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'Oto Twoja strona ślubna ze wszystkim, czego potrzebujesz, aby stworzyć ślub swoich marzeń.',
  [k.NEW_NOW]: 'Nowość:',
  [k.MULTILINGUAL_WEDDINGS]: 'Wielojęzyczne wesela!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Teraz dostępne: Strony internetowe wielojęzyczne!',
  [k.SCHEDULE]: 'Oś czasu ślubu',
  [k.LOCATIONS]: 'Lokalizacje ślubów',
  [k.RSVP]: 'Karta odpowiedzi',
  [k.GUESTMANAGEMENT]: 'Zarządzanie gośćmi',
  [k.HOTEL_RECOMMENDATION]: 'Rekomendacje dla hoteli',
  [k.PRESENT_TABLE]: 'Tabela prezentów',
  [k.OWN_DOMAIN]: 'Domena niestandardowa',
  [k.DIVERSE_LAYOUTS]: 'Niezliczone układy i projekty do wyboru i dostosowania.',
  [k.YOUR_WEDDING_DAY]: 'Twój dzień ślubu zorganizowany dla gości.',
  [k.CLEAR_DIRECTIONS]: 'Wyraźne wskazówki dojazdu do wszystkich lokalizacji weselnych.',
  [k.NO_GUESSWORK]: 'Nigdy więcej żmudnych zapytań o to, kto przyjdzie na wesele.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Zbierz adresy, alergie itp. za pomocą jednego kliknięcia!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Wybór hotelu dla Twoich gości, aby ułatwić im podróż!',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'Twój rejestr ślubny stał się łatwy.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'Twoje zdjęcia ślubne i zdjęcia Twoich gości w jednym miejscu.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Twoja własna playlista Spotify - stworzona przez Ciebie i Twoich gości.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Strona ślubna z Twoją własną domeną i ochroną hasłem.',
  [k.YOUR_WEDDING_WEBSITE]: 'TWOJA STRONA ŚLUBNA',
  [k.UNIQUE_LIKE_YOU]: '- TAK WYJĄTKOWA JAK TY!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Łatwa konfiguracja i personalizacja!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]:
    'Wszystkie szczegóły Twojego wydarzenia na pierwszy rzut oka - czy to na telefonie, czy na komputerze.',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Potwierdzenia i prośby gości na wyciągnięcie ręki',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]:
    'Twoje przyjęcie z każdej perspektywy - galeria zdjęć na żywo dla Ciebie i Twoich gości',
  [k.MULTILINGUAL_SUPPORT]: '... oczywiście także dla wielojęzycznych wesel.',
  [k.FREE_CREATE]: 'Stwórz teraz za darmo',
  [k.DEMO_WEBSITE]: 'Strona demonstracyjna',
  [k.OUR_PRICING]: 'Nasze ceny',
  [k.UNLOCK_ALL_FEATURES]: 'Odblokuj wszystkie funkcje, które chcesz za jednorazową opłatą',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Czy masz jakieś pytania lub prośby?',
  [k.WE_ARE_HERE_TO_HELP]: 'Czekamy na wiadomość od Ciebie!',
  [k.USER_TESTIMONIALS]: 'Głosy naszych użytkowników',
  [k.THOUSANDS_OF_PEOPLE]: 'Tysiące ludzi świętowało już swój ślub z EureHochzeitOnline!',
  [k.OUR_GIFT_TIP]: 'Nasz pomysł na prezent:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Podaruj parze młodej spersonalizowaną stronę ślubną!',
  [k.BRIDE]: 'Panna młoda',
  [k.F_WITNESS]: 'Druhna',
  [k.GROOM]: 'Pan Młody',
  [k.TESTIMONIAL_1]:
    'Stworzenie naszej strony było dziecinnie proste. Byliśmy naprawdę zaskoczeni, jak łatwo było dodać nawet skomplikowane informacje. Naprawdę byliśmy w stanie stworzyć WŁASNĄ stronę internetową. Nasi goście byli pod wrażeniem!',
  [k.TESTIMONIAL_2]:
    'Fantastyczne było to, że wszyscy nasi goście mogli korzystać ze strony w swoim własnym języku. Uwielbiali funkcję playlisty, która pozwalała im sugerować własne ulubione piosenki. W ten sposób różnorodność gości była również celebrowana poprzez muzykę na imprezie.',
  [k.TESTIMONIAL_3]:
    'Nasza strona internetowa była nieocenioną pomocą podczas planowania naszego ślubu. W każdej chwili mogliśmy aktualizować ważne informacje, a nawet prezentować zdjęcia w pięknej galerii. Nasi goście pokochali pokaz slajdów na żywo, a my pokochaliśmy go jeszcze bardziej.',
  [k.TESTIMONIAL_4]:
    'Ponad rok później nadal lubimy słuchać playlisty Spotify z naszego ślubu. Nasza strona ślubna pomogła nam mieć wspaniałe wesele z cudownymi wspomnieniami.',
  [k.THANK_YOU]: 'Dziękuję bardzo!',
  [k.ACTIVATED_MAIL]: 'Aktywowałeś swój adres e-mail!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Kliknij tutaj, aby przejść do strony ślubnej',
  [k.AND]: 'i',
  [k.BE_REDIRECTED]: 'aby zostać przekierowanym.',
  [k.SET_A_PASSWORD]: 'Ustaw hasło, aby zawsze móc się zalogować i edytować swój ślub!',
  [k.SET_A_PASSWORD_SHORT]: 'Wybierz hasło',
  [k.ERROR_OCCURED]:
    'Wystąpił błąd. Spróbuj ponownie. Jeśli błąd powtarza się, możesz wysłać nam wiadomość e-mail na adres:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Masz już hasło',
  [k.PASSWORD_ALREADY_EXISTING]: 'Zauważyliśmy, że utworzyłeś już hasło. Możesz',
  [k.GO_TO_WEDDING]: 'przejść do swojej strony ślubnej tutaj i zarejestrować się',
  [k.I_AGREE]: 'Akceptuję',
  [k.REGISTER_NOW]: 'Zarejestruj się teraz!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'Twoja darmowa strona ślubna!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Utworzyłeś już następujące strony ślubne. Prawdopodobnie wolisz edytować istniejące strony ślubne niż tworzyć nowe. Po prostu kliknij poniżej.',
  [k.WEDDING]: 'Ślubne strony internetowe',
  [k.ENTER_PASSWORD]: 'Wprowadź swoje hasło',
  [k.USER_ALREADY_EXISTING]: 'Mamy już zarejestrowanego użytkownika z tym adresem e-mail. Wprowadź swoje hasło.',
  [k.BACK]: 'Wróć',
  [k.LOGIN]: 'Zaloguj się',
  [k.FORGOT_PASSWORD]: 'Zapomniałeś hasła? Kliknij tutaj.',
  [k.MAIL_SENT]: 'Wysłaliśmy do Ciebie wiadomość e-mail. Kliknij link w wiadomości e-mail, aby zresetować hasło.',
  [k.OK]: 'OK',
  [k.PERFECT]: 'Doskonale,',
  [k.NAME_OF]: 'Jaka jest twoja nazwa?',
  [k.SPOUSE_M]: 'Twój wybraniec',
  [k.SPOUSE_F]: 'Twój wybraniec',
  [k.NEXT]: 'Następny',
  [k.WHATS_YOUR_NAME]: 'Super! Jak masz na imię?',
  [k.PREPARING_1]: 'Przygotowujemy Twoją aplikację ślubną...',
  [k.PREPARING_2]: 'Zapisujemy Twój wybrany projekt...',
  [k.PREPARING_3]: 'Kilka ostatnich poprawek...',
  [k.FINISHED_ENTER_MAIL]:
    'Gotowe! Podaj swój adres e-mail, abyśmy mogli zapisać Twoją aplikację ślubną i wysłać Ci dane logowania.',
  [k.ACCEPT_TERMS]: 'Zaakceptuj warunki korzystania z usługi.',
  [k.ENTER_VALID_MAIL]: 'Wprowadź poprawny adres e-mail.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'Jesteś panną młodą lub panem młodym?',
  [k.QUESTION_WEDDING_DATE]: 'Czy znasz datę swojego ślubu?',
  [k.YES_WE_KNOW]: 'Tak, znamy!',
  [k.NO_WE_DONT_KNOW]: 'Nie, jeszcze nie!',
  [k.MAKE_SELECTION]: 'Dokonaj wyboru.',
  [k.QUESTION_WEDDING_LOCATION]: 'Czy masz już lokalizację?',
  [k.YES_WE_HAVE]: 'Tak, znamy!',
  [k.ENTER_YOUR_LOCATION]: 'Wprowadź swoją lokalizację ślubu.',
  [k.LOCATION_CLASSIC]: 'Klasyczny',
  [k.LOCATION_COUNTRY]: 'Kraj',
  [k.LOCATION_OUTDOOR]: 'Plener',
  [k.LOCATION_PALACE_HOTEL]: 'Zamek',
  [k.QUESTION_WEDDING_STYLE]: 'Jaki styl preferujesz?',
  [k.HOTEL]: 'Hotele',
  [k.INFOS]: 'Informacje',
  [k.LOCATION]: 'Lokalizacje',
  [k.PHOTOS]: 'Zdjęcia',
  [k.NO_MUSIC_SAVED]: 'Nie masz jeszcze zapisanych utworów...',
  [k.SEARCH_FOR_SONGS]: 'Wyszukaj swój ulubiony utwór powyżej!',
  [k.LISTEN_TO_FULL_LIST]: 'Posłuchaj pełnej playlisty ślubnej',
  [k.OPEN_IN_SPOTIFY]: 'Otwórz w Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Posłuchaj poszczególnych utworów z playlisty',
  [k.NO_RESULT_FOUND]: 'Nie znaleziono żadnych wyników',
  [k.CANCEL]: 'Anuluj',
  [k.ADD_SONG]: 'Dodaj utwór',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Nie dodano jeszcze żadnych zdjęć...',
  [k.ADD_SOME_PHOTOS]: 'Kliknij powyżej i zrób zdjęcia!',
  [k.DROP_PHOTOS_HERE_OR]: 'Zapisz zdjęcia tutaj lub',
  [k.SHOOT_PHOTO]: 'Zrób zdjęcie',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Zobacz wszystkie prezenty i wpisy',
  [k.LOADING_BACKINGS]: 'Wpisy zostały załadowane...',
  [k.NO_PRESENTS_YET]: 'Nie dodałeś jeszcze żadnych prezentów do swojego rejestru prezentów.',
  [k.PRESENT]: 'Dodaj prezent',
  [k.E_MAIL_ADDRESS]: 'Twój adres e-mail',
  [k.DATE]: 'Data prezentu',
  [k.NO_BACKINGS_YET]: 'Nie masz jeszcze wpisów',
  [k.CLOSE]: 'zamknij',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Widoczne tylko dla pary młodej)',
  [k.SHOW_ALL_ENTRIES]: 'Pokaż wszystkie wpisy',
  [k.EUR]: 'KWOTA EUR',
  [k.ADD_BACKING]: 'Dołącz',
  [k.BUY]: 'Kup teraz',
  [k.YOUR_BACKING_SAVED]: 'Jesteś zalogowany!',
  [k.A_GUEST_ALREADY_BACKED]: 'Gość już się zarejestrował.',
  [k.DELETE_MY_BACKING]: 'Usuń mój wpis.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Przegląd wszystkich rejestracji',
  [k.LOADING_RSVPS]: 'Rejestracja się ładuje...',
  [k.NO_RSVPS_YET]: 'Nie otrzymano jeszcze żadnych kart odpowiedzi.',
  [k.RESPONSE]: 'Odpowiedz',
  [k.GUESTS_AND_MENU_WISH]: 'Goście i prośba o menu',
  [k.QUESTIONS_AND_REMARKS]: 'Pytanie/komentarz',
  [k.WILL_ATTEND]: 'Zobowiązanie',
  [k.WILL_NOT_ATTEND]: 'Odwołanie',
  [k.SHOW_ALL_RSVPS]: 'Wyświetl wszystkie prośby',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Wysłałeś już odpowiedź do',
  [k.CANNOT_CHANGE_ANY_MOR]: 'została już wysłana. Nie możesz zmienić szczegółów.',
  [k.MENU_WISH]: 'Menu żądań',
  [k.RSVP_LOADING]: 'Karta odpowiedzi się ładuje...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Sporządź poniższą listę wszystkich osób, których możemy się spodziewać',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Przygotuj poniższą listę wszystkich osób, których możemy się spodziewać, a także podaj nam swoje preferencje dotyczące menu.',
  [k.GUEST]: 'Goście',
  [k.ADD_GUEST]: 'Dodaj kolejnego gościa',
  [k.SUBMIT_RSVP]: 'Wyślij kartę odpowiedzi',
  [k.WEBSITE]: 'Strona internetowa',
  [k.OUR_PACKAGES]: 'Nasze pakiety',
  [k.PACKAGE_BASIC]: 'Podstawowy',
  [k.PACKAGE_COMPLETE]: 'Kompletny',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Najlepszy sprzedawca',
  [k.SELECTED]: 'Wybrany',
  [k.SELECT]: 'Wybierz',
  [k.CAN_UPGRADE_LATER]: 'Nie martw się, zawsze możesz uaktualnić później, po prostu płacąc różnicę.',
  [k.LOOK_IN_FAQ]: 'Wciąż masz pytania? Sprawdź nasze FAQ!',
  [k.PACKAGE_FEATURES_DESIGN]: 'Projekty, kolory, czcionki',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Twoja subdomena z hasłem',
  [k.PACKAGE_FEATURES_MOBILE]: 'Zoptymalizowany pod kątem komputerów i urządzeń mobilnych',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Plan dnia z rozkładem jazdy',
  [k.PACKAGE_FEATURES_LOCATION]: 'Planer lokalizacji i informacje',
  [k.PACKAGE_FEATURES_INFOS]: 'Strony informacyjne (FAQ, prezenty itp.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Rejestracja i zarządzanie gośćmi',
  [k.PACKAGE_FEATURES_MUSIC]: 'Prośby gości o muzykę',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Przesyłanie zdjęć gości',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Obsługa klienta przez e-mail',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Wielojęzyczna strona internetowa',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Twoja własna domena (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Niestandardowy projekt od profesjonalisty',
  [k.MAX_FIVE]: 'max. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Sprawdź status płatności',
  [k.PAYMENT_SUCCESSFULL]:
    'Dziękujemy! Płatność powiodła się, możesz teraz korzystać z aplikacji ślubnej w następującym pakiecie:',
  [k.PAYMENT_FAILED]:
    'Niestety, płatność nie powiodła się. Możesz spróbować ponownie lub wysłać nam wiadomość e-mail na adres',
  [k.ACTIVATE_PACKAGE_NOW]: 'Odblokuj teraz:',
  [k.PAY]: 'Zapłać',
  [k.EDITING_MODE]: 'Tryb edycji',
  [k.ACTIVE]: 'jest aktywny',
  [k.NOT_ACTIVE]: 'nie jest aktywny',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Wystąpił błąd. Techniczna informacja zwrotna:',
  [k.TRY_AGAIN_PLEASE]: 'Spróbuj ponownie. Jeśli błąd powtarza się, możesz wysłać do nas wiadomość e-mail na adres',
  [k.PACKAGE_UPGRADE]: 'Uaktualnij pakiet',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Zakupiłeś już pakiet na swój ślub.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Oczywiście możesz ulepszyć swoją stronę internetową do wyższego pakietu, po prostu płacąc różnicę. Wyślij nam krótką wiadomość e-mail, a my zajmiemy się wszystkim.',
  [k.REGISTRATION_NOT_COMPLETE]: 'Rejestracja nie została jeszcze zakończona. Kliknij na link, który Ci wysłaliśmy.',
  [k.YOU_HAVE_AN_EMAIL]: 'Otrzymałeś od nas wiadomość e-mail.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Musisz dokończyć rejestrację, aby móc edytować swoją stronę ślubną.',
  [k.CREATOR_MUST_REGISTER]:
    'To wszystko jest bezpłatne i bardzo szybkie: po prostu kliknij link w wiadomości e-mail, którą Ci wysłaliśmy. Nie możesz znaleźć wiadomości e-mail? Nie ma problemu, po prostu kliknij tutaj:',
  [k.HERE]: 'Tutaj',
  [k.E_MAIL_RESENT]: 'E-mail wysłany ponownie.',
  [k.CONFIRMATION_RESENT_TO]: 'Ponownie wysłaliśmy do Ciebie link potwierdzający:',
  [k.CAN_TAKE_SOME_MINUTES]:
    'Dotarcie wiadomości e-mail do Ciebie może potrwać kilka minut. Jeśli nadal masz problemy, napisz do nas, a my Ci pomożemy:',
  [k.RESENDING_MAIL]: 'E-mail zostanie wysłany ponownie.',
  [k.DEMO_WEDDING_HINT]: 'Strona demonstracyjna - stwórz własną stronę za darmo i wypróbuj ją!',
  [k.CREATE_OWN_WEBSITE]: 'Stwórz własną stronę internetową!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Niektóre funkcje są wyłączone na stronie demo.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Możesz zarejestrować się, stworzyć własną stronę ślubną i wypróbować wszystkie funkcje za darmo!',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    'Darmowa wersja podstawowa - uaktualnij teraz, aby uzyskać nieograniczoną liczbę zdjęć, gości i więcej funkcji.',
  [k.LETS_UPGRADE]: 'Uaktualnij.',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Osiągnąłeś maksymalną liczbę zdjęć dla darmowego pakietu podstawowego.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Możesz uaktualnić do jednego z naszych planów premium, aby przesłać nieograniczoną liczbę zdjęć.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Możesz uaktualnić do jednego z naszych planów premium, aby dodać nieograniczoną liczbę utworów.',
  [k.CONFIRM_DELETE_TITLE]: 'Potwierdź usunięcie',
  [k.CONFIRM_DELETE_TEXT]: 'Czy na pewno chcesz usunąć ten element?',
  [k.DELETE]: 'Usuń element',
  [k.ADD_ELEMENT]: 'Dodaj nowy element',
  [k.WHICH_ELEMENT_TO_ADD]: 'Jaki typ elementu chcesz dodać?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Wybierz ikonę dla lokalizacji',
  [k.IMAGE_POSITION]: 'Pozycja obrazu',
  [k.SELECT_IMAGE]: 'Wybierz obraz',
  [k.UPLOAD]: 'Prześlij obraz',
  [k.SELECTED_IMAGE]: 'Wybrany obraz',
  [k.NO_IMAGE]: 'Brak obrazu',
  [k.OTHER_IMAGE]: 'Inne zdjęcie',
  [k.EDIT_WEDDING_BASE_DATA]: 'Edytuj szczegóły ślubu',
  [k.ENTER_NAME_OF_BRIDE]: 'Wprowadź imię panny młodej.',
  [k.ENTER_NAME_OF_GROOM]: 'Wprowadź imię pana młodego.',
  [k.SELECT_DATE]: 'Wybierz datę.',
  [k.HINT_CHANGE_URL]:
    'Uwaga: Jeśli chcesz zmienić adres, pod którym będzie dostępna Twoja strona ślubna, wyślij nam wiadomość e-mail:',
  [k.EDIT_COUNTDOWN]: 'Edit Wedding Countdown',
  [k.EDIT_INFO_BOX]: 'Edytuj pole informacyjne',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Edytuj pole z dużym zdjęciem',
  [k.EDIT_LOCATION]: 'Edytuj lokalizację',
  [k.EDIT_PLACE]: 'Edytuj lokalizację',
  [k.ADD_LOCATION]: 'Dodaj lokalizację',
  [k.ADD_PLACE]: 'Dodaj lokalizację',
  [k.SELECT_SEARCH_RESULT]: 'Wybierz wynik wyszukiwania.',
  [k.EDIT_PAGE_TITLE]: 'Edytuj tytuł strony',
  [k.EDIT_PRESENTS]: 'Edytuj rejestr prezentów',
  [k.PRESENT_INTRO_TEXT]: 'Stwórz własny, spersonalizowany rejestr prezentów, aby zainspirować swoich gości.',
  [k.ADDITIONAL_ENTRY]: 'Więcej wpisów',
  [k.EDIT_RSVP]: 'Edytuj RSVP',
  [k.MENU_WISHES_ACTIVATED]: 'Czy chcesz, aby Twoi goście wskazali swoje preferencje dotyczące menu podczas RSVP?',
  [k.EDIT_SCHEDULE_ITEM]: 'Edytuj pozycję menu',
  [k.NAVIGATION]: 'Ustaw menu',
  [k.COUPLE]: 'Panna Młoda i Pan Młody',
  [k.MUSIC]: 'Menu muzyczne',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Jesteś zarejestrowana jako pan młody.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Jesteś zarejestrowana jako panna młoda.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Twój adres strony internetowej ślubu:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Kod weselny dla Twoich gości:',
  [k.CURRENT_PACKAGE]: 'Twój aktualny pakiet:',
  [k.UPGRADE]: 'Uaktualnij',
  [k.LOGOUT]: 'Wyloguj się',
  [k.FOR_GUESTS]: 'Dla gości',
  [k.INVISIBLE]: 'niewidoczny',
  [k.VISIBLE]: 'Widoczny',
  [k.WEDDING_NOT_FOUND]: 'Nie znaleziono strony ślubnej.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Przepraszamy, nie mogliśmy znaleźć strony ślubnej pod podanym adresem. Czy to możliwe, że się pomyliłeś? Spróbuj ponownie.',
  [k.IF_ERROR_REPEATS]: 'Jeśli błąd będzie się powtarzał, możesz wysłać do nas wiadomość e-mail na adres',
  [k.ERROR_GENERAL]: 'Wystąpił błąd',
  [k.SORRY_WRITE_MAIL]:
    'Przepraszamy. Spróbuj ponownie. Jeśli błąd będzie się powtarzał, możesz po prostu wysłać do nas e-mail, a my pomożemy Ci dalej.',
  [k.MOVE_UP]: 'Przejdź w górę',
  [k.MOVE_DOWN]: 'Przewiń w dół',
  [k.OH_SORRY]: 'Ojej... Popełniłem błąd...',
  [k.ERROR_REFRESH_PAGE]:
    'Bardzo nam przykro. Przeładuj stronę i spróbuj ponownie. Jeśli błąd będzie się powtarzał, możesz wysłać do nas e-mail na adres',
  [k.IF_YOU_WANT_TO_HELP]:
    'Jeśli chcesz pomóc nam szybko znaleźć i naprawić błąd, możesz skopiować poniższy tekst do wiadomości e-mail:',
  [k.ENTER_DISPLAY_NAME]:
    'Świetnie! Wpisz swoje imię i nazwisko - pseudonim też działa. Pojawi się on na przykład pod zdjęciami, które przesyłasz.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Wprowadź imię i nazwisko.',
  [k.WE_SENT_EMAIL]: 'Wysłaliśmy do Ciebie wiadomość e-mail. Kliknij łącze w wiadomości e-mail, aby zresetować hasło.',
  [k.WELCOME_BACK]: 'Witaj z powrotem na stronie,',
  [k.ALMOST_THERE]: 'Już prawie,',
  [k.PLEASE_SET_PASSWORD]: 'Wybierz teraz hasło.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Wybierz hasło',
  [k.WELCOME_TO_WEDDING_OF]: 'Witaj na stronie ślubnej',
  [k.PLEASE_ENTER_MAIL]: '! Wprowadź swój adres e-mail.',
  [k.WELCOME_TO_EHO]: 'Witamy na platformie ślubnej EureHochzeitOnline.com! Wprowadź swój adres e-mail.',
  [k.TO_WEDDING]: 'Dla ślubu',
  [k.ENTER_WEDDING_CODE]: '! Wprowadź kod ślubu.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Wprowadź kod ślubu.',
  [k.RESTART]: 'Uruchom ponownie',
  [k.UPLOAD_PREPARING]: 'Przesyłanie w przygotowaniu...',
  [k.UPLOAD_STARTED]: 'Przesyłanie w toku...',
  [k.UPLOAD_FINISHING]: 'Przesyłanie zakończone...',
  [k.UPLOAD_CANCELED]: 'Przesyłanie zostało anulowane...',
  [k.INVALID_FILE_TYPE]: 'Typ pliku jest nieprawidłowy...',
  [k.MAX_FILE_NUMBER]: 'Przekroczono maksymalną liczbę plików...',
  [k.FILE_TOO_LARGE]: 'Plik jest zbyt duży...',
  [k.DAYS_TO_GO]: 'Dni do końca!',
  [k.DAY_TO_GO]: 'Jeszcze jeden dzień!',
  [k.TODAY]: 'Dzisiaj',
  [k.INVALID_DATE]: 'Data jest nieprawidłowa',
  [k.DATE_MUST_BE_IN_FUTURE]: 'Data musi być w przyszłości',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Adres lub nazwa lokalizacji',
  [k.ENTER_SONG_WISH]: 'Wpisz tutaj swoją ulubioną piosenkę...',
  [k.NAME_OF_GUEST]: 'Imię i nazwisko gościa',
  [k.QUESTION_REMARK_MAX_1000]: 'Pytania/komentarze (maks. 1000 znaków)',
  [k.LEFT]: 'Linki',
  [k.TOP]: 'Góra',
  [k.RIGHT]: 'Prawo',
  [k.BOTTOM]: 'W dół',
  [k.NAME_OF_BRIDE]: 'Imię Panny Młodej',
  [k.NAME_OF_GROOM]: 'Imię pana młodego',
  [k.TITLE]: 'Tytuł',
  [k.DESCRIPTION]: 'Opis wydarzenia',
  [k.NAME_OF_PLACE]: 'Nazwa miejsca',
  [k.STREET]: 'Ulica',
  [k.HOUSENUMBER]: 'Nr.',
  [k.ZIP]: 'KOD POCZTOWY',
  [k.CITY]: 'Miasto',
  [k.COUNTRY]: 'Kraj/państwo',
  [k.HINTS_FOR_GUESTS]: 'Uwagi dla gości',
  [k.TITLE_FOR_THIS_PAGE]: 'Tytuł tej strony',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Podtytuł tej strony',
  [k.LABEL]: 'Oznaczenie',
  [k.AMAZON_URL]: 'Adres URL Amazon (opcjonalnie)',
  [k.COSTS]: 'Koszty',
  [k.NEW_FOOD_OPTION]: 'Nowa opcja menu',
  [k.TIME]: 'Czas',
  [k.SCHEDULE_ITEM]: 'Element programu',
  [k.YOUR_NAME_OR_NICKNAME]: 'Twoje imię i nazwisko lub pseudonim',
  [k.YOUR_EMAIL_ADDRESS]: 'Twój adres e-mail',
  [k.YOUR_WEDDING_CODE]: 'Twój kod ślubny',
  [k.COULD_NOT_SAVE_WEDDING]: 'Przepraszamy, ale nie mogliśmy zapisać ślubu.',
  [k.ALREADY_HAVE_WEDDING]: 'Masz już ślubne strony główne',
  [k.CREATE_NEW]: 'Utwórz nową stronę',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'Nie mogliśmy pobrać Twoich danych.',
  [k.COULD_NOT_SEND_MAIL]: 'Nie mogliśmy wysłać wiadomości e-mail.',
  [k.DELETE_IMAGE]: 'Usuń zdjęcie',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Czy na pewno chcesz usunąć ten obraz?',
  [k.COULD_NOT_LOGIN]: 'Nie mogliśmy się zalogować.',
  [k.WRONG_PASSWORD_TITLE]: 'Błędne hasło',
  [k.WRONG_PASSWORD_TEXT]: 'Wprowadzone hasło jest nieprawidłowe.',
  [k.WRONG_CODE_TITLE]: 'Nieprawidłowy kod ślubu',
  [k.WRONG_CODE_TEXT]: 'Wprowadzony kod ślubu jest nieprawidłowy.',
  [k.LOADING]: 'Ładowanie...',
  [k.GUEST_NAME_MISSING]: 'Wprowadź nazwiska następujących gości:',
  [k.GUEST_FOOD_MISSING]: 'Uzupełnij prośbę o menu dla następujących gości:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Przekroczyłeś maksymalną liczbę prób. Możesz spróbować ponownie później.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Użyłeś już tego linku, aby zresetować hasło.',
  [k.PLEASE_ENTER_NAME]: 'Wprowadź imię',
  [k.COUNTDOWN_VISIBLE]: 'Odliczanie widoczne.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Odliczanie niewidoczne.',
  [k.ASK_FOR_MENU_WISHES]: 'Tak, pytaj o prośby dotyczące menu',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'Nie, nie pytaj o prośby dotyczące menu',
  [k.SPRING]: 'Wiosna',
  [k.SUMMER]: 'Lato',
  [k.AUTUMN]: 'Jesień',
  [k.WINTER]: 'Zima',
  [k.COULD_NOT_SAVE_PASSWORD]: 'Nie mogliśmy zapisać Twojego hasła.',
  [k.YES_WE_WILL_ATTEND]: 'Tak, weźmiemy udział w Twoim ślubie',
  [k.NO_WE_WILL_NOT_ATTEND]: 'Nie, nie weźmiemy udziału w Twoim ślubie',
  [k.CONFIRM_DIALOG_TITLE]: 'Czy jesteś pewien?',
  [k.ALERT_DIALOG_TITLE]: 'Uwaga',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Tort weselny',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Ceremonia ślubna',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Przyjęcie i szampan',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Taniec i zabawa',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Obiad weselny i bufet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Samochód weselny i parada',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Gry i zabawy',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Kod ubioru',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel i zakwaterowanie',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Sesja zdjęciowa',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Prezenty i upominki',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'Ogólne i miłość',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Tytuł strony',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Tytuł strony',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Witaj na tej stronie',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Pozycja programu',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Pozycja programu',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'Od 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Zdjęcie grupowe',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Info Box',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: 'Ciasto',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' Jeśli chcesz upiec ciasto, daj nam znać. Z niecierpliwością czekamy na kolorowy wybór.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Pudełko z dużym zdjęciem',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'Twoje wprowadzenie',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Tutaj możesz przedstawić się swoim gościom. Powiedz im, jak się poznaliście...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Mapa lokalizacji',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Lokalizacja ślubu',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Nowoczesny`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Niech Wasza miłość zabłyśnie w atmosferze oddającej puls czasów.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Klasyczny`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Czyste linie, harmonijne kolory i odrobina opulencji tworzą atmosferę, która oddaje cześć Waszej miłości.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `Styl vintage przywołuje urok minionych epok z nutą nostalgii i elegancji.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Kwiatowy`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `Ten styl ślubny celebruje naturalne piękno kwiatów w całej ich okazałości.`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `Boho`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `Relaksująca i niekonwencjonalna atmosfera, łącząca naturę i kreatywność – idealna dla wolnych duchów.`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `Rozkwit`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `Romantyczny styl, który podkreśla delikatne piękno kwiatów i elementów florystycznych.`,
  [k.NOT_SURE_ABOUT_DESIGN]: `Niepewny? Nie martw się, możesz zmienić styl później bez utraty danych.`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `Własne zdjęcia i logotypy możesz dodać po bezpłatnej rejestracji.`,
  [k.YOUR_NAMES]: `Wasze imiona`,
  [k.YOUR_WEDDING_DATE]: `Data Waszego ślubu`,
  [k.WEDDING_DATE_FIX]: `Data ślubu jest już ustalona`,
  [k.WEDDING_DATE_NOT_FIX]: `Data ślubu nie jest ustalona`,
  [k.YOUR_WEDDING_STYLE]: `Styl Waszej strony internetowej`,
  [k.OWN_LOGO]: `Własne logo`,
  [k.START_PHOTO]: `Zdjęcie na stronie głównej`,
  [k.USE_START_PHOTO]: `Użyj własnego zdjęcia?`,
  [k.WITH_START_PHOTO]: `Z własnym zdjęciem`,
  [k.WITHOUT_START_PHOTO]: `Bez własnego zdjęcia`,
  [k.ALL_STYLES]: `Wszystkie style`,

  [k.COOKIES_01]: 'Ślubne ciasteczka dla Ciebie?',
  [k.COOKIES_02]:
    "Klikając 'Potwierdź wszystko', zaakceptujesz wszystkie pliki cookie. Alternatywnie możesz dokonać niestandardowego wyboru. Możesz odwołać swój wybór w dowolnym momencie za pomocą 'Ustawień plików cookie'.",
  [k.COOKIES_03]: 'Niezbędne pliki cookie',
  [k.COOKIES_04]: 'Te pliki cookie są niezbędne do działania witryny, więc nie możesz ich wyłączyć.',
  [k.COOKIES_05]: 'Dowiedz się więcej',
  [k.COOKIES_06]: 'Marketingowe i personalizacyjne pliki cookie',
  [k.COOKIES_07]:
    'Te pliki cookie umożliwiają otrzymywanie treści witryny dostosowanych do zachowania użytkownika i pozwalają nam dostosować naszą witrynę do zainteresowań naszych klientów w oparciu o statystyki użytkowania.',
  [k.COOKIES_08]: 'Zaakceptuj wybór',
  [k.COOKIES_09]: 'Zaakceptuj wszystko',
  [k.TERMS_AND_CONDITIONS_00]: 'Warunki użytkowania',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt), zwana dalej również 'dostawcą', 'my' lub 'EureHochzeitOnline.com', zapewnia platformę do tworzenia indywidualnych aplikacji ślubnych. Dzięki tym aplikacjom użytkownicy (zwani dalej łącznie 'Użytkownikami') jako gospodarze mogą stworzyć aplikację na swój ślub i zaoferować swoim gościom informacje o ślubie i możliwościach interakcji za pośrednictwem tej aplikacji. Poniższe warunki mają zastosowanie do korzystania z platformy.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Korzystanie z Platformy',
  [k.TERMS_AND_CONDITIONS_03]:
    'Korzystanie z Platformy jest zasadniczo bezpłatne w wersji próbnej. Jednorazowa opłata musi zostać uiszczona, jeśli platforma jest używana do ślubu z określoną liczbą gości lub większą. Zarówno wersja próbna, jak i pełna wersja wymagają uprzedniej rejestracji i utworzenia konta użytkownika. Użytkownik nie może zezwolić osobom trzecim na korzystanie z jego konta. Użytkownik jest zobowiązany do zachowania w tajemnicy swoich danych dostępowych i chronienia ich przed dostępem osób trzecich.',
  [k.TERMS_AND_CONDITIONS_04]:
    'Aplikacja Ślubna będzie dostępna przez sześć miesięcy po ślubie. Po tym czasie dezaktywujemy aplikację ślubną, abyśmy mogli zaoferować żądany adres innym parom. Jeśli chcesz zachować swoją aplikację ślubną online przez dłuższy czas, możemy wypracować specjalne porozumienie.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Po utworzeniu aplikacji ślubnej można jej używać tylko do jednego ślubu. Jeśli na przykład odkryjemy, że użytkownik przekazał lub sprzedał aplikację ślubną innym parom, zastrzegamy sobie prawo do uniemożliwienia takiego wykorzystania i naliczenia opłaty za kolejne śluby.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Ochrona danych',
  [k.TERMS_AND_CONDITIONS_07]: 'Korzystając z oprogramowania, użytkownicy akceptują',
  [k.TERMS_AND_CONDITIONS_08]: 'ochronę danych',
  [k.TERMS_AND_CONDITIONS_09]: 'regulamin',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Funkcje i usługi EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'Indywidualne aplikacje ślubne, które można utworzyć za pośrednictwem EureHochzeitOnline.com, oferują następujące funkcje',
  [k.TERMS_AND_CONDITIONS_12]: 'Personalizacja:',
  [k.TERMS_AND_CONDITIONS_13]:
    'Gospodarz może skonfigurować i zaprojektować niektóre aspekty aplikacji ślubnej, na przykład, aby dopasować projekt graficzny zaproszeń ślubnych, może wybrać tła, obrazy, kolory i czcionki.',
  [k.TERMS_AND_CONDITIONS_14]: 'Galeria zdjęć:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Goście mogą robić i przesyłać zdjęcia przed lub w trakcie wesela. Zdjęcia te mogą być wyświetlane w automatycznie aktualizowanej galerii obrazów na żywo.',
  [k.TERMS_AND_CONDITIONS_16]: 'Prośby o muzykę:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Goście mogą użyć funkcji wyszukiwania, aby poprosić o piosenki na wesele i ocenić prośby. Gospodarz może użyć tej funkcji do wyboru utworów.',
  [k.TERMS_AND_CONDITIONS_18]: 'Informacje o weselu:',
  [k.TERMS_AND_CONDITIONS_19]:
    'Gospodarz może opublikować miejsce z interaktywnymi mapami, harmonogramem i innymi informacjami dla swoich gości w aplikacji ślubnej.',
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com zastrzega sobie prawo do wprowadzania zmian w zakresie i wyglądzie funkcji oprogramowania w dowolnym momencie.',
  [k.TERMS_AND_CONDITIONS_21]:
    'Do korzystania z funkcji wymagany jest nowoczesny smartfon, tablet lub komputer PC z nowoczesną przeglądarką internetową. W szczególności funkcje na żywo, takie jak przesyłanie zdjęć, quizy ślubne lub prośby o muzykę, wymagają stabilnego i szybkiego połączenia internetowego. EureHochzeitOnline.com nie ponosi odpowiedzialności za dokładność, kompletność, niezawodność, aktualność i użyteczność udostępnianych treści.',
  [k.TERMS_AND_CONDITIONS_22]:
    'Podczas prac konserwacyjnych, o których EureHochzeitOnline.com poinformuje hostów, platforma może być tymczasowo niedostępna. Dokładamy wszelkich starań, aby nasz serwis był dostępny i z dumą możemy powiedzieć, że nigdy nie zawiedliśmy naszych użytkowników. Nie możemy jednak przyjąć żadnych dalszych zobowiązań, w szczególności użytkownik nie ma prawa do stałej dostępności usługi.',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Obowiązki użytkownika',
  [k.TERMS_AND_CONDITIONS_24]:
    'Użytkownik może zapisywać i publikować własne treści i pliki na platformie EureHochzeitOnline.com jako gość lub jako gospodarz. Użytkownik jest odpowiedzialny za te treści. Użytkownik zobowiązuje się nie publikować żadnych artykułów, plików, informacji ślubnych ani innych treści, które naruszają dobre obyczaje lub obowiązujące prawo. W szczególności użytkownik zobowiązuje się nie publikować żadnych postów, plików, informacji ślubnych ani innych treści',
  [k.TERMS_AND_CONDITIONS_25]: 'których publikacja stanowi przestępstwo karne lub administracyjne,',
  [k.TERMS_AND_CONDITIONS_26]: 'naruszają prawa autorskie, znaki towarowe lub prawa konkurencji',
  [k.TERMS_AND_CONDITIONS_27]: 'które naruszają ustawę o usługach prawnych,',
  [k.TERMS_AND_CONDITIONS_28]: 'lub zawierają treści obraźliwe, rasistowskie, dyskryminujące lub pornograficzne',
  [k.TERMS_AND_CONDITIONS_29]: 'które zawierają reklamy.',
  [k.TERMS_AND_CONDITIONS_30]:
    'EureHochzeitOnline.com ma prawo do zmiany lub usunięcia wszelkich treści, które naruszają lub są podejrzane o naruszenie tych zobowiązań. EureHochzeitOnline.com ma również prawo do zablokowania dostępu użytkownika i skonfigurowanej aplikacji ślubnej, jeśli użytkownik narusza warunki użytkowania. Użytkownik jest zobowiązany do zrekompensowania dostawcy wszelkich szkód spowodowanych naruszeniem obowiązków. Usługodawca jest uprawniony do żądania od użytkownika odszkodowania za roszczenia dochodzone przez osoby trzecie z powodu naruszenia prawa przez użytkownika. Użytkownik jest zobowiązany do wspierania usługodawcy w obronie przed takimi roszczeniami. Użytkownik jest również zobowiązany do poniesienia kosztów odpowiedniej obrony prawnej dostawcy.',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Własność intelektualna',
  [k.TERMS_AND_CONDITIONS_32]: 'Twoja własność intelektualna',
  [k.TERMS_AND_CONDITIONS_33]:
    'Jesteś właścicielem praw własności intelektualnej do treści, tekstów, obrazów i innych materiałów, które publikujesz. EureHochzeitOnline.com nie rości sobie żadnych praw własności intelektualnej do takich treści. Upoważniasz jednak EureHochzeitOnline.com do dostępu, kopiowania i rozpowszechniania tych treści w celu dostarczenia ich gościom weselnym. Upoważniasz nas również do wykonywania wszelkich innych czynności niezbędnych do świadczenia naszych usług, w tym między innymi do tworzenia kopii zapasowych i zapewniania dostępu do personelu pomocniczego.',
  [k.TERMS_AND_CONDITIONS_34]: 'Nasza własność intelektualna',
  [k.TERMS_AND_CONDITIONS_35]:
    'Dostawca nie udziela żadnej licencji ani innego prawa do korzystania z praw własności intelektualnej strony internetowej EureHochzeitOnline.com lub platformy oprogramowania poza regularnie tworzoną aplikacją ślubną dostarczaną zgodnie z Warunkami użytkowania. Powielanie, dystrybucja, powielanie, przekazywanie lub inne wykorzystywanie praw własności intelektualnej, w tym udostępnianie ich osobom trzecim, nie jest dozwolone bez uprzedniej pisemnej zgody Usługodawcy.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Prawo do odstąpienia od umowy',
  [k.TERMS_AND_CONDITIONS_37]:
    'Masz prawo odstąpić od niniejszej umowy w ciągu czternastu dni bez podania przyczyn. Okres odstąpienia wynosi czternaście dni od zawarcia umowy. Możesz skorzystać z prawa do odstąpienia od umowy, wysyłając wiadomość e-mail na adres',
  [k.TERMS_AND_CONDITIONS_38]:
    'z jasnym oświadczeniem o Twojej decyzji o odstąpieniu od umowy. W wiadomości e-mail możesz również skorzystać z poniższego przykładowego formularza:',
  [k.TERMS_AND_CONDITIONS_39]: 'Przykładowy formularz odstąpienia od umowy',
  [k.TERMS_AND_CONDITIONS_40]: '- Do fidira Software UG (haftungsbeschränkt), e-mail: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    '- Ja/My ( * ) niniejszym odstępuję/odstępujemy ( * ) od zawartej przeze mnie/nas ( * ) umowy dotyczącej aktywacji naszej aplikacji ślubnej ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- Zamówione w dniu ( * ) / Otrzymane w dniu ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Imię i nazwisko konsumenta(-ów)',
  [k.TERMS_AND_CONDITIONS_44]: '- Adres konsumenta(-ów)',
  [k.TERMS_AND_CONDITIONS_45]: '- Podpis konsumenta(-ów) (tylko w przypadku powiadomienia papierowego)',
  [k.TERMS_AND_CONDITIONS_46]: '- Data',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Niepotrzebne skreślić',
  [k.TERMS_AND_CONDITIONS_48]:
    'Aby zachować termin do odstąpienia od umowy, wystarczy wysłać powiadomienie o skorzystaniu z prawa do odstąpienia od umowy przed upływem terminu do odstąpienia od umowy:',
  [k.TERMS_AND_CONDITIONS_49]: 'Konsekwencje odwołania:',
  [k.TERMS_AND_CONDITIONS_50]:
    'Jeśli odstąpisz od umowy, usuniemy Twoją aplikację ślubną, a jeśli otrzymaliśmy już od Ciebie płatności, zwrócimy Ci te płatności przy użyciu tej samej metody płatności. Nie zostaniesz obciążony żadną opłatą za ten zwrot.',
  [k.TERMS_AND_CONDITIONS_51]:
    'W przypadku umowy o dostarczenie treści cyfrowych, które nie znajdują się na fizycznym nośniku danych, takich jak aplikacja ślubna, prawo do odstąpienia od umowy zgodnie z art. 356 (5) BGB wygasa również wtedy, gdy Dostawca rozpoczął wykonywanie umowy po tym, jak Użytkownik 1. wyraźnie wyraził zgodę na rozpoczęcie wykonywania umowy przez Dostawcę przed upływem terminu do odstąpienia od umowy oraz 2. potwierdził, że jest świadomy, że utraci prawo do odstąpienia od umowy, wyrażając zgodę na rozpoczęcie wykonywania umowy.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Gwarancja i wyłączenie odpowiedzialności',
  [k.TERMS_AND_CONDITIONS_53]:
    "Usługodawca świadczy swoje usługi 'w stanie, w jakim się znajdują', 'ze wszystkimi wadami' i 'w miarę dostępności' i nie udziela żadnych wyraźnych ani dorozumianych gwarancji przydatności handlowej, przydatności do określonego celu, staranności wykonania, nienaruszania praw ani żadnych innych gwarancji w zakresie dozwolonym przez prawo. Nie ma również gwarancji, że usługi Dostawcy (lub jakakolwiek ich część, funkcja lub treść) są (lub będą) kompletne, o określonej jakości, niezawodne, bezpieczne, dokładne, kompatybilne z zamierzonymi działaniami, używanymi urządzeniami, systemami operacyjnymi, przeglądarkami lub programami (gości lub gospodarzy), lub że są zgodne z wszelkimi wymogami prawnymi mającymi zastosowanie do Ciebie, lub że usługi są wolne od wirusów, błędów lub innych szkodliwych składników lub ograniczeń programowych. Ponadto EureHochzeitOnline.com nie ponosi żadnej odpowiedzialności za jakiekolwiek firmy, produkty lub usługi (w tym usługi stron trzecich) wymienione lub oferowane na platformie EureHochzeitOnline.com. Nie ponosimy odpowiedzialności za utratę danych i informacji, a dostawca nie gwarantuje odtworzenia takich danych i informacji. Użytkownik tworzy kopie zapasowe swoich danych i informacji na własne ryzyko. EureHochzeitOnline.com nie ponosi również odpowiedzialności za treści publikowane przez użytkowników ani za prawidłową tożsamość użytkowników.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Klauzula salwatoryjna',
  [k.TERMS_AND_CONDITIONS_55]:
    'Jeśli którekolwiek z postanowień niniejszej Umowy zostanie uznane za nieważne lub niewykonalne z jakiegokolwiek powodu lub w jakimkolwiek zakresie, taka nieważność lub niewykonalność nie wpłynie na pozostałe postanowienia Umowy, a zastosowanie takiego postanowienia będzie egzekwowane w najszerszym zakresie dozwolonym przez prawo. Jeśli EureHochzeitOnline.com zrzeknie się prawa do egzekwowania naruszenia jednego lub więcej warunków, nie oznacza to, że EureHochzeitOnline.com zrzeka się również prawa do egzekwowania innych warunków.',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Postanowienia końcowe',
  [k.TERMS_AND_CONDITIONS_57]:
    'O ile przepisy prawa nie stanowią inaczej, umowy podlegają prawu Republiki Federalnej Niemiec. Wyłącznym miejscem jurysdykcji dla wszelkich sporów/roszczeń wynikających z niniejszych Warunków użytkowania lub z nimi związanych jest Monachium. Zastrzegamy sobie prawo do zmiany niniejszych Warunków użytkowania w przyszłości i opublikowania nowej wersji w tym samym miejscu. Po zmianie Warunków użytkowania nowa wersja musi zostać potwierdzona przez użytkownika.',
  [k.TERMS_AND_CONDITIONS_58]: 'Status: 01.04.2018',
  [k.IMPRINT_00]: 'Nadawca/Informacja prawna',
  [k.IMPRINT_01]: 'fidira Software UG (ograniczona odpowiedzialność)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de jest znakiem towarowym fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Mail:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Siedziba spółki: Monachium, Rejestr Handlowy Monachium HRB',
  [k.IMPRINT_09]: 'Upoważnieni przedstawiciele:',
  [k.PRIVACY_00]: 'Polityka prywatności',
  [k.PRIVACY_01]: 'Wprowadzenie i informacje ogólne',
  [k.PRIVACY_02]:
    'Ochrona Twojej prywatności i prywatności Twoich gości weselnych jest dla EureHochzeitOnline.com najwyższym priorytetem.',
  [k.PRIVACY_03]:
    'Zgodnie z wymogami niemieckiej federalnej ustawy o ochronie danych (BDSG) i niemieckiej ustawy o telemediach (TMG) zapewniamy, że Twoje dane są wykorzystywane wyłącznie do tworzenia i korzystania z Twojej osobistej aplikacji ślubnej oraz do optymalizacji komfortu użytkowania aplikacji ślubnej. Używamy technologii TLS/SSL (Transport Layer Security / Secure Sockets Layer) zarówno do tworzenia, jak i korzystania z aplikacji ślubnych, dzięki czemu dane są zawsze szyfrowane podczas transmisji. Wszystkie nasze serwery aplikacji znajdują się w Unii Europejskiej i są zgodne z unijnymi przepisami dotyczącymi prywatności danych. Podczas procesu rejestracji zostaniesz poproszony o wyrażenie zgody na przetwarzanie danych opisane w niniejszej Polityce prywatności poprzez kliknięcie pola wyboru. Wyrażając zgodę, potwierdzasz, że możemy gromadzić, przetwarzać i wykorzystywać Twoje dane osobowe w zakresie opisanym poniżej. Możesz wycofać swoją zgodę w dowolnym momencie. Aby to zrobić, po prostu wyślij wiadomość e-mail do naszego działu obsługi klienta na adres',
  [k.PRIVACY_04]:
    'Podczas rejestracji zostaniesz poproszony o wyrażenie zgody na operacje przetwarzania opisane w polityce prywatności, którą wyrażasz poprzez kliknięcie checkboxa. Wyrażając zgodę, potwierdzasz, że możemy gromadzić, przetwarzać i wykorzystywać Twoje dane osobowe w zakresie opisanym poniżej. Możesz także wycofać swoją zgodę w dowolnym momencie. Aby to zrobić, wystarczy wysłać e-mail do naszego działu obsługi klienta pod adresem',
  [k.PRIVACY_05]:
    '. Na żądanie nasz dział obsługi klienta dostarczy Ci również informacji o wszystkich przechowywanych danych na Twój temat (zgodnie z § 13 (7) niemieckiej ustawy o telemediach (Telemediengesetz)) i usunie te dane na Twoje żądanie.',
  [k.PRIVACY_06]: '§ 1 Podmiot odpowiedzialny i obszar zastosowania',
  [k.PRIVACY_07]:
    "Podmiotem odpowiedzialnym jest fidira Software UG (haftungsbeschränkt), zwana dalej również 'dostawcą', 'my' lub 'EureHochzeitOnline.com'. Szczegóły dotyczące adresu i upoważnionych przedstawicieli można znaleźć w stopce redakcyjnej.",
  [k.PRIVACY_08]:
    'Niniejsza polityka prywatności obejmuje gromadzenie i wykorzystywanie danych osobowych podczas odwiedzania i korzystania z dowolnej platformy dostawcy za pośrednictwem przeglądarek internetowych. Za pośrednictwem tych ofert Dostawca umożliwia stworzenie indywidualnej aplikacji ślubnej, za pomocą której goście mogą informować się o ślubie i wchodzić ze sobą w interakcje podczas ślubu (np. udostępniać zdjęcia lub odpowiadać na pytania). Dostawca będzie również wykorzystywał dane osobowe do celów statystycznych i analizy rynku. W ten sposób tworzymy anonimowe statystyki, np. dotyczące wesel, lokalizacji, wielkości wesel lub korzystania z aplikacji, w celu dalszej optymalizacji naszej oferty.',
  [k.PRIVACY_09]: '§ 2 Linki do innych stron internetowych',
  [k.PRIVACY_10]:
    'Nasza strona internetowa może zawierać linki do innych stron internetowych. Nie mamy kontroli nad treściami, do których zostaniesz przekierowany po kliknięciu tych linków, ani nad przetwarzaniem Twoich danych, które mogą być przesyłane do strony trzeciej (np. Twój adres IP). W związku z tym nie możemy przyjąć żadnej odpowiedzialności za treści stron trzecich i przetwarzanie takich danych przez strony trzecie.',
  [k.PRIVACY_11]: '§ 3 Wykorzystanie danych bez rejestracji',
  [k.PRIVACY_12]:
    'Kiedy odwiedzasz stronę internetową lub aplikację EureHochzeitOnline.com, automatycznie zapisujemy pewne podstawowe informacje o Twojej wizycie: w szczególności adres IP, odwiedzone strony i czas trwania wizyty. Używamy tych informacji do tworzenia anonimowych statystyk dotyczących zachowania użytkowników i często używanych funkcji w celu ciągłego ulepszania naszej oferty. Twój adres IP jest przechowywany w technicznych plikach dziennika tylko przez ograniczony czas w celu ochrony naszych systemów i tworzonych przez nas aplikacji ślubnych przed nadużyciami i oszustwami.',
  [k.PRIVACY_13]: '§ 4 Wykorzystanie danych podczas rejestracji',
  [k.PRIVACY_14]:
    'Aby uzyskać dostęp do aplikacji ślubnej jako gość lub utworzyć aplikację ślubną jako gospodarz, użytkownik musi się zarejestrować. Podczas procesu rejestracji przechowywany będzie adres e-mail użytkownika, nazwa wyświetlana (dowolnie wybrana, może to być również wymyślony pseudonim), kod ślubny (jeśli dotyczy) oraz hasło (dowolnie definiowane). Informacje te służą do ochrony ofert ślubnych naszych gospodarzy przed nieautoryzowanym dostępem. Podczas tworzenia aplikacji ślubnej gospodarze mogą wprowadzić informacje o swoim ślubie (np. lokalizację, czas, harmonogram), które będą widoczne tylko dla gości tego ślubu. Osiąga się to poprzez generowanie przez gospodarza kodów ślubnych, które goście muszą wprowadzić, aby uzyskać dostęp do aplikacji ślubnej.',
  [k.PRIVACY_15]:
    'Używamy również Twojego adresu e-mail do celów komunikacyjnych: na przykład powiadomienia e-mail mogą być wysyłane do gospodarza w związku z nowymi rejestracjami, innymi wiadomościami ślubnymi, a wiadomości e-mail dotyczące usług EureHochzeitOnline.com mogą być wysyłane na adres e-mail (np. ankiety satysfakcji). Wyświetlamy również adresy e-mail gości weselnych gospodarzowi, aby mogli je wykorzystać do dalszej organizacji wesela.',
  [k.PRIVACY_16]: 'Twój adres e-mail nigdy nie będzie udostępniany osobom trzecim ani innym firmom.',
  [k.PRIVACY_17]: '§ 5 Pliki cookie',
  [k.PRIVACY_18]:
    "Aby zapewnić Ci jak najlepsze wrażenia z użytkowania, używamy plików 'cookie' i tak zwanego 'lokalnego przechowywania' Twojej przeglądarki. Pozwala nam to na przechowywanie niewielkich ilości danych o Twojej Aplikacji Ślubnej w Twojej przeglądarce, aby na przykład Twoje zmiany nie zostały utracone po zamknięciu przeglądarki, przełączeniu na tryb pełnoekranowy Konfiguratora Aplikacji lub abyś nie musiał się logować za każdym razem. Jeśli nie chcesz używać plików cookie lub chcesz usunąć istniejące pliki cookie, możesz je wyłączyć lub usunąć za pomocą przeglądarki. Zapoznaj się z instrukcjami przeglądarki, jak to zrobić, lub skontaktuj się z nami, aby uzyskać pomoc.",
  [k.PRIVACY_19]:
    "Używamy również plików cookie dla usług stron trzecich wymienionych poniżej w '§ 7 Aplikacje i witryny internetowe', aby zapewnić Ci lepsze wrażenia użytkownika.",
  [k.PRIVACY_20]: '§ 6 Aplikacje i witryny internetowe',
  [k.PRIVACY_21]: 'Mapy Google',
  [k.PRIVACY_22]:
    'Używamy Google Maps od Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA do wyświetlania interaktywnych map. Twój adres IP, Twoja lokalizacja i informacje o korzystaniu z naszych usług będą przesyłane i przechowywane przez Google na serwerach w Stanach Zjednoczonych. Warunki korzystania z Map Google można znaleźć na stronie ',
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "Używamy również HotJar, narzędzia do analizy stron internetowych firmy Hotjar Ltd., do zbierania i przechowywania danych w celach marketingowych i optymalizacyjnych. Dane mogą być wykorzystywane do tworzenia pseudonimowych profili użytkowników. Losowo wybrane indywidualne wizyty (bez dodatkowych danych osobowych, tak zwane 'atrybuty użytkownika') są rejestrowane. Tworzy to dziennik ruchów myszy i kliknięć z zamiarem losowego odtwarzania poszczególnych wizyt na stronie internetowej i wyprowadzania potencjalnych ulepszeń dla strony internetowej. Zebrane dane nie będą wykorzystywane do osobistej identyfikacji osoby odwiedzającej tę witrynę ani nie będą łączone z danymi osobowymi osoby noszącej pseudonim bez odrębnej zgody tej osoby. Jeśli nie chcesz być śledzony, możesz globalnie wyłączyć śledzenie na wszystkich stronach internetowych korzystających z HotJar dla przeglądarki, z której aktualnie korzystasz, klikając poniższy link",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'Używamy Mailchimp od The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) do wysyłania naszego newslettera. Pozwala nam to na bezpośredni kontakt z subskrybentami. Ponadto analizujemy Twoje zachowania związane z użytkowaniem, aby zoptymalizować naszą ofertę.',
  [k.PRIVACY_29]: 'W tym celu udostępniamy Mailchimp następujące dane osobowe',
  [k.PRIVACY_30]: 'Adres e-mail [Imię] [Data ślubu] [Informacje o ślubie] [Dane logowania].',
  [k.PRIVACY_31]:
    'Nasze wiadomości e-mail będą zawierać link umożliwiający aktualizację danych osobowych. Mailchimp jest odbiorcą Twoich danych osobowych i działa jako podmiot przetwarzający w naszym imieniu w zakresie dystrybucji naszego newslettera. Przetwarzanie danych opisanych w tej sekcji nie jest wymagane przez prawo lub umowę. Bez Twojej zgody i podania danych osobowych nie możemy wysyłać Ci newslettera. Ponadto Mailchimp gromadzi następujące dane osobowe za pomocą plików cookie i innych metod śledzenia Informacje o Twoim urządzeniu (adres IP, informacje o urządzeniu, system operacyjny, identyfikator przeglądarki, informacje o aplikacji używanej do czytania wiadomości e-mail oraz inne informacje o sprzęcie i połączeniu internetowym. Ponadto gromadzone są dane dotyczące użytkowania, takie jak data i godzina otwarcia wiadomości e-mail/kampanii oraz aktywność przeglądarki (np. które wiadomości e-mail/strony internetowe zostały otwarte). Mailchimp potrzebuje tych informacji, aby zapewnić bezpieczeństwo i niezawodność systemów, egzekwować Warunki świadczenia usług i zapobiegać nadużyciom. Odpowiada to uzasadnionemu interesowi Mailchimp (zgodnie z art. 6 ust. 1 lit. f RODO) i służy wykonaniu umowy (zgodnie z art. 6 ust. 1 lit. b RODO). Mailchimp analizuje również dane dotyczące wydajności, takie jak statystyki dostarczania wiadomości e-mail i inne dane dotyczące komunikacji. Informacje te są wykorzystywane do tworzenia statystyk użytkowania i wydajności Usług. Mailchimp zbiera również informacje o Tobie z innych źródeł. Od czasu do czasu i na nieokreśloną skalę dane osobowe są gromadzone za pośrednictwem mediów społecznościowych i innych zewnętrznych dostawców danych. Nie mamy kontroli nad tymi procesami. ',
  [k.PRIVACY_32]: 'Aby uzyskać więcej informacji na temat swoich wyborów i opcji usunięcia z Mailchimp, odwiedź stronę',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'Podstawą prawną tego przetwarzania jest Twoja zgoda zgodnie z art. 6 ust. 1 lit. a RODO. Możesz wycofać swoją zgodę na przetwarzanie swoich danych osobowych w dowolnym momencie. Link do tego znajdziesz we wszystkich wiadomościach e-mail. Możesz również odwołać swoją zgodę, korzystając z dostępnych opcji kontaktu. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, które już miało miejsce.',
  [k.PRIVACY_35]:
    'Twoje dane będą przetwarzane tak długo, jak długo wyrażasz na to zgodę. W przeciwnym razie zostaną one usunięte po zakończeniu umowy między nami a Mailchimp, chyba że wymogi prawne wymagają dalszego przechowywania.',
  [k.PRIVACY_36]:
    'Mailchimp wdrożył środki zgodności dla międzynarodowych transferów danych. Środki te mają zastosowanie do wszystkich operacji globalnych, w których Mailchimp przetwarza dane osobowe osób fizycznych w UE. Zasady te opierają się na standardowych klauzulach umownych UE (SCC). Więcej informacji można znaleźć na stronie',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    'Korzystamy również z widżetu Spotify Music Player Widget, odtwarzacza muzycznego firmy Spotify AB, aby umożliwić Ci słuchanie playlisty ślubnej. Dostawcą usługi Spotify Music Player Widget jest Spotify AB, Regeringsgatan 19, 111 53 Sztokholm, Szwecja. Więcej informacji na temat przetwarzania danych przez Spotify AB można znaleźć pod adresem',
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Piksel Facebooka',
  [k.PRIVACY_42]:
    "Aby ulepszyć stronę EureHochzeitOnline.com i udostępnić ją większej grupie użytkowników, używamy piksela Facebooka firmy Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, USA ('Facebook'). W tym celu Facebook umieszcza plik cookie w Twojej przeglądarce. Informacje generowane przez ten plik cookie na temat korzystania przez Ciebie z tej strony internetowej będą przesyłane i przechowywane przez Facebook na serwerach w Stanach Zjednoczonych. Facebook może wykorzystać ten transfer danych do ustalenia, czy dana strona została wywołana z Twojej przeglądarki i czy nastąpiło to poprzez kliknięcie reklamy. Nie wykorzystujemy jednak tych danych do zbierania informacji z naszej witryny. Facebook wykorzystuje te informacje, aby pomóc nam w tworzeniu dodatkowych reklam i promowaniu EureHochzeitOnline.com. Oceniamy dane uzyskane z piksela Facebooka w narzędziu analitycznym Facebooka 'Facebook Analytics' w celu dalszego ulepszania EureHochzeitOnline.com. Facebook może przekazywać te informacje stronom trzecim, jeśli jest to wymagane przez prawo lub jeśli takie strony trzecie przetwarzają informacje w imieniu Facebooka. Korzystając z EureHochzeitOnline.com, wyrażasz zgodę na przetwarzanie danych o Tobie przez Facebook w opisany sposób i w celu ulepszenia EureHochzeitOnline.com. Nie udostępniamy Facebookowi żadnych Twoich danych osobowych, kontaktowych ani ślubnych. Możesz dowiedzieć się więcej o polityce bezpieczeństwa i prywatności Facebook Pixel na stronie",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'Możesz również zrezygnować z gromadzenia informacji przez Facebook Pixel, klikając tutaj:',
  [k.PRIVACY_45]: 'Dezaktywuj Piksel',
  [k.PRIVACY_46]: '§ 7 Prawo do informacji i inne prawa zgodnie z BDSG',
  [k.PRIVACY_47]:
    'Z przyjemnością udzielimy Ci informacji o Twoich danych osobowych przechowywanych przez nas w dowolnym momencie. Na Twoje żądanie poprawimy, zablokujemy lub usuniemy Twoje dane. Możesz skontaktować się z nami w dowolnym momencie w sprawie tych żądań lub oświadczeń o odwołaniu pod adresem',
  [k.PRIVACY_48]: 'a my wprowadzimy zmiany tak szybko, jak to możliwe. ',
  [k.PRIVACY_49]: '§ 8 Zmiany w oświadczeniu o ochronie danych',
  [k.PRIVACY_50]:
    'Internet i możliwości techniczne stale się rozwijają. Jest rzeczą oczywistą, że wykorzystamy te nowe możliwości, aby wesela naszych użytkowników były jeszcze bardziej niezapomniane. Może to skłonić nas do aktualizacji i zmiany naszej Polityki prywatności. Będziemy publikować te aktualizacje tutaj.',
  [k.PRIVACY_51]: 'Ostatnia aktualizacja: 28.11.2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Jeśli potrzebujesz więcej języków, napisz do nas e-mail.',
  [k.DECIDE_LANGUAGES]:
    'Zdecyduj, jakie języki ma obsługiwać Twoja witryna ślubna. Możesz wprowadzić swoje teksty dla wszystkich wybranych języków.',
  [k.EDIT_LANGUAGES]: `Edytuj języki`,
  [k.ACTIVE_VOUCHER]: `Aktywny kupon:`,
  [k.ENTER_VOUCHER_CODE]: `Wprowadź kod kuponu`,
  [k.VOUCHER_CODE]: `Kod rabatowy`,
  [k.INVALID_CODE]: `Niestety nie jest to ważny kod!`,
  [k.WANT_CUSTOM_LOGO]: `Czy chcielibyście użyć własnego logo ślubnego?`,
  [k.INDIVIDUAL_LOGO]: `Indywidualne logo`,
  [k.STANDARD_LOGO]: `Standardowe logo`,
  [k.PRIVATE_PURCHASE]: `Zakup jest prywatny`,
  [k.COMMERCIAL_PURCHASE]: `Zakup jest komercyjny`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
