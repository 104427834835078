const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Hi,',
  [k.ENTER_NEW_PASSWORD]: 'Please enter your new password here. You will be automatically logged in and redirected.',
  [k.MINIMUM_6_CHARACTERS]: 'Please use a minimum of 6 characters.',
  [k.SAVE]: 'Save ',
  [k.UNABLE_TO_RESET_PASSWORD]: 'We were unable to reset your password.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Password changed successfully',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'We have changed your password! You can now login with your new password. We are forwarding you now.',
  [k.FUNCTIONS]: 'Features',
  [k.DESIGNS]: 'Themes',
  [k.PRICES]: 'Pricing',
  [k.FAQ]: 'FAQ ',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Create now for free',
  [k.PERFECT_PAGE_FOR_YOU]: 'Your website is tailored to you!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: 'Achieve the perfect design in just a few clicks with our design configurator.',
  [k.DESIGN]: '-Design ',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'How would you describe the wedding of your dreams?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Click on one of the designs to start the design configurator',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Create now for free',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Frequently Asked Questions',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Do you have any questions or feedback?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'We look forward to hearing from you!',
  [k.CONTENT_AND_DESIGN]: 'CONTENT & DESIGN',
  [k.PHOTO_GALLERY]: 'Photo Galleries',
  [k.RSVP_CARD]: 'RSVP/Reply Card',
  [k.PLAYLIST]: 'Playlist',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'MULTILINGUALISM & OWN DOMAIN',
  [k.DURATION_PASSWORD_PROTECTION]: 'RUNTIME & PASSWORD PROTECTION',
  [k.COST_SUPPORT]: 'COSTS & SUPPORT',
  [k.WHAT_CONTENT_TYPICALLY]: 'What content is typical for a wedding site?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Can I create the wedding webpage before all the details (such as our wedding date) are finalized?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Can I customize the design of my wedding website?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    'Is it possible to make changes to the home page without them being immediately visible to everyone?',
  [k.PHOTO_UPLOAD_LIMIT]: 'Is there a limit to the number of photos we can upload to the gallery?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'Will the photos be saved in their original size?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Can guests download the photos uploaded to the site?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]: 'Can we use the photo gallery for a live slide show during our party?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'How does the RSVP feature work?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Can guests add their own songs to the playlist?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: 'Can our DJ access the playlist?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'Is it possible to have our homepage in different languages?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Can we get our own domain for our wedding website?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'How long will our wedding website be available?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Can we password protect our site?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'Is EureHochzeitOnline.com free to use?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    'How can I get assistance in creating my Wedding Webpage on EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    "The content of your wedding webpage is as individual as your wedding itself. In addition to basic information such as date and location, you can add dress codes, directions, special requests, officiant contact information, or a planned timeline. For guests who are traveling from afar and need accommodations, you can list hotel suggestions. It's also a great idea to share your love story with your guests or write personal words about your partner. There are no limits to your creativity.",
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Yes, this is easy to do and even offers significant advantages. You can add information and provide updates at any time, even after your wedding invitations have been sent.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Yes, EureHochzeitOnline.com offers a variety of design options for your wedding website. You can customize colors, fonts, and additional elements. Please contact us if you have any questions or comments.',
  [k.INDEED_CHANGES_VISIBLE]:
    'In fact, all changes to your website will be immediately visible to all visitors in real time. We do not currently offer a feature to schedule or hide changes in advance.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'In the free version there is a limited number of photos you can upload to your gallery. That is because we have costs associated with storage and traffic. However, if you purchase the Premium or Domain packages, you will be able to upload an unlimited number of photos to your gallery. Check out our blog for tips on how to get the perfect wedding photos.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    "When you upload your photos, we create different sizes for each image. Depending on the visitor's screen size, the optimal size is automatically selected to ensure fast loading times with the best possible image quality. However, we also save the original files of the photos so that you can receive them as well.",
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Yes, guests can download uploaded photos.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Absolutely! In fact, this is one of the most popular features of EureHochzeitOnline. All you need is a stable Internet connection and, of course, a screen or projector.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'The RSVP feature makes it easy to share your wedding website login information with your guests. When they visit your wedding site, they will be asked to leave their feedback (e.g. how many people they will be attending with, whether they are children or adults, and any special food requests, allergies, or other requirements). In the guest area, you can track the number of acceptances and cancellations at any time. See our blog for step-by-step instructions.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Absolutely! Your guests can suggest their favorite songs and add them to the playlist. So not only can you celebrate the diversity of your guests, but you can also enjoy the music that is important to you and your loved ones.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Of course. Just invite your DJ to your website and all your wishes will be at his disposal - even live during your party, if you want.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Yes, you can have your website in multiple languages. This feature is available in the Premium package and you can translate all the information into different languages. Please contact us so that we can set up the configuration for your wedding.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Yes, you can easily book your own domain (e.g. www.juliaundromeo.de) for your wedding website with our Premium Package. Please contact us so that we can book the domain for you and set up the redirection.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'Your wedding website will be available for two years after it is created. After that, it will be deleted to make room for new wedding websites. We will remind you in advance. Renewals are available upon request.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'All wedding webpages are protected with a password, which will be shown to you after creation.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Yes, you can create and design a wedding website on EureHochzeitOnline.com for free. However, some advanced features are not free, especially those that require additional server resources. There are no hidden fees.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'If you need help creating your wedding website, feel free to send us a message at beeni@eurehochzeitonline.com. We will get back to you as soon as possible to assist you. ',
  [k.KEY_FEATURES_OVERVIEW]: 'Top features at a glance!',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    "Here's your wedding website with everything you need to create the wedding of your dreams.",
  [k.NEW_NOW]: 'New:',
  [k.MULTILINGUAL_WEDDINGS]: 'Multilingual Weddings!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Now available: Multilingual websites!',
  [k.SCHEDULE]: 'Wedding Timeline',
  [k.LOCATIONS]: 'Wedding Locations',
  [k.RSVP]: 'Reply Card',
  [k.GUESTMANAGEMENT]: 'Guest Management',
  [k.HOTEL_RECOMMENDATION]: 'Hotel Recommendations',
  [k.PRESENT_TABLE]: 'Gift Table',
  [k.OWN_DOMAIN]: 'Custom Domain',
  [k.DIVERSE_LAYOUTS]: 'Countless layouts and designs to choose from and customize.',
  [k.YOUR_WEDDING_DAY]: 'Your wedding day neatly organized for your guests.',
  [k.CLEAR_DIRECTIONS]: 'Clear directions to all wedding locations.',
  [k.NO_GUESSWORK]: 'No more tedious inquiries about who is coming to the wedding.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Collect addresses, allergies, etc. with one click!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Hotel selection for your guests to make their travel easier!',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'Your wedding registry made easy.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: "Your wedding photos and your guests' photos in one place.",
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Your own Spotify playlist - created by you and your guests.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Wedding website with your own domain and password protection.',
  [k.YOUR_WEDDING_WEBSITE]: 'YOUR WEDDING WEBSITE',
  [k.UNIQUE_LIKE_YOU]: '- AS UNIQUE AS YOU!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Easy to set up and customize!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]: 'All the details of your event at a glance - whether on your phone or PC',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Guest confirmations and requests at your fingertips',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]: 'Your party from all angles - live picture gallery for you and your guests',
  [k.MULTILINGUAL_SUPPORT]: '... of course also for multilingual weddings',
  [k.FREE_CREATE]: 'Create now for free',
  [k.DEMO_WEBSITE]: 'Demo Website',
  [k.OUR_PRICING]: 'Our prices',
  [k.UNLOCK_ALL_FEATURES]: 'Unlock all the features you want for a one-time fee',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Do you have any questions or requests?',
  [k.WE_ARE_HERE_TO_HELP]: 'We look forward to hearing from you!',
  [k.USER_TESTIMONIALS]: 'Voices of our users',
  [k.THOUSANDS_OF_PEOPLE]: 'Thousands of people have already celebrated their wedding with EureHochzeitOnline!',
  [k.OUR_GIFT_TIP]: 'Our gift idea:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Treat the bride and groom to a personalized wedding website!',
  [k.BRIDE]: 'Bride',
  [k.F_WITNESS]: 'Maid of Honor',
  [k.GROOM]: 'Groom',
  [k.TESTIMONIAL_1]:
    'Creating our website was a breeze. We were really surprised at how easy it was to add even complicated information. We were really able to create OUR website. Our guests were impressed!',
  [k.TESTIMONIAL_2]:
    'It was fantastic that all of our guests could use the site in their own language. They loved the playlist feature that allowed them to suggest their own favorite songs. In this way, the diversity of the guests was also celebrated through the party music.',
  [k.TESTIMONIAL_3]:
    'Our website was an invaluable help throughout the planning of our wedding. We were able to update important information at any time and even showcase photos in a beautiful photo gallery. Our guests loved the live slideshow and we loved it even more.',
  [k.TESTIMONIAL_4]:
    'Over a year later, we still enjoy listening to the Spotify playlist from our wedding. Our wedding website helped us have a great wedding with wonderful memories.',
  [k.THANK_YOU]: 'Thank You!',
  [k.ACTIVATED_MAIL]: 'You have activated your email address!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Click here to go to the wedding website of',
  [k.AND]: 'and',
  [k.BE_REDIRECTED]: 'to be redirected.',
  [k.SET_A_PASSWORD]: 'Please set a password so that you can always log in and edit your wedding!',
  [k.SET_A_PASSWORD_SHORT]: 'Please choose a password',
  [k.ERROR_OCCURED]:
    'An error has occurred. Please try again. If the error occurs repeatedly, you can send us an email to:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'You already have a password',
  [k.PASSWORD_ALREADY_EXISTING]: 'We have noticed that you have already created a password. You can',
  [k.GO_TO_WEDDING]: 'go to your wedding homepage here and register',
  [k.I_AGREE]: 'I accept the',
  [k.REGISTER_NOW]: 'Register now! ',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'Your free wedding website!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'You have already created the following wedding homepages. You would probably prefer to edit the existing wedding homepages rather than create a new one. Just click below.',
  [k.WEDDING]: 'Wedding Websites',
  [k.ENTER_PASSWORD]: 'Please enter your password',
  [k.USER_ALREADY_EXISTING]: 'We already have a registered user with this email address. Please enter your password.',
  [k.BACK]: 'Back',
  [k.LOGIN]: 'Sign In',
  [k.FORGOT_PASSWORD]: 'Forgot your password? Please click here.',
  [k.MAIL_SENT]: 'We have sent you an email. Please click the link in the email to reset your password.',
  [k.OK]: 'OK',
  [k.PERFECT]: 'Perfect,',
  [k.NAME_OF]: 'What is the name',
  [k.SPOUSE_M]: 'Your chosen one',
  [k.SPOUSE_F]: 'Your chosen one',
  [k.NEXT]: 'Next ',
  [k.WHATS_YOUR_NAME]: "Awesome! What's your name?",
  [k.PREPARING_1]: 'We are preparing your wedding app...',
  [k.PREPARING_2]: 'We save your chosen design...',
  [k.PREPARING_3]: 'A few final tweaks...',
  [k.FINISHED_ENTER_MAIL]:
    'Done! Please enter your email address so we can save your wedding app and send you your login details.',
  [k.ACCEPT_TERMS]: 'Please accept the Terms of Service.',
  [k.ENTER_VALID_MAIL]: 'Please enter a valid email address.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'Are you the bride or groom?',
  [k.QUESTION_WEDDING_DATE]: 'Do you know your wedding date?',
  [k.YES_WE_KNOW]: 'Yes, we do!',
  [k.NO_WE_DONT_KNOW]: 'No, not yet!',
  [k.MAKE_SELECTION]: 'Please make a selection.',
  [k.QUESTION_WEDDING_LOCATION]: 'Do you have a location?',
  [k.YES_WE_HAVE]: 'Yes, we do!',
  [k.ENTER_YOUR_LOCATION]: 'Please enter your wedding location.',
  [k.LOCATION_CLASSIC]: 'Classic',
  [k.LOCATION_COUNTRY]: 'Country',
  [k.LOCATION_OUTDOOR]: 'Outdoor',
  [k.LOCATION_PALACE_HOTEL]: 'Castle',
  [k.QUESTION_WEDDING_STYLE]: 'What style do you prefer?',
  [k.HOTEL]: 'Hotels',
  [k.INFOS]: 'Info',
  [k.LOCATION]: 'Locations',
  [k.PHOTOS]: 'Photos',
  [k.NO_MUSIC_SAVED]: 'No songs saved yet...',
  [k.SEARCH_FOR_SONGS]: 'Search for your favorite song above!',
  [k.LISTEN_TO_FULL_LIST]: 'Listen to the full wedding playlist',
  [k.OPEN_IN_SPOTIFY]: 'Open in Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Listen to individual songs in the playlist',
  [k.NO_RESULT_FOUND]: 'No results found',
  [k.CANCEL]: 'Cancel',
  [k.ADD_SONG]: 'Add a song',
  [k.NO_PHOTOS_UPLOADED_YET]: 'No photos uploaded yet...',
  [k.ADD_SOME_PHOTOS]: 'Click above and take photos!',
  [k.DROP_PHOTOS_HERE_OR]: 'Save photos here or',
  [k.SHOOT_PHOTO]: 'Take a photo',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'View all gifts and entries',
  [k.LOADING_BACKINGS]: 'Entries have been loaded...',
  [k.NO_PRESENTS_YET]: 'You have not yet added any gifts to your gift registry.',
  [k.PRESENT]: 'Add a gift',
  [k.E_MAIL_ADDRESS]: 'Your e-mail address',
  [k.DATE]: 'Gift date',
  [k.NO_BACKINGS_YET]: 'No entries yet',
  [k.CLOSE]: 'close',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Only visible to the bride and groom)',
  [k.SHOW_ALL_ENTRIES]: 'Show all entries',
  [k.EUR]: 'AMOUNT EUR',
  [k.ADD_BACKING]: 'Join',
  [k.BUY]: 'Buy now',
  [k.YOUR_BACKING_SAVED]: 'You are logged in!',
  [k.A_GUEST_ALREADY_BACKED]: 'A guest has already registered.',
  [k.DELETE_MY_BACKING]: 'Delete my entry.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Overview of all registrations',
  [k.LOADING_RSVPS]: 'Registrations are loading...',
  [k.NO_RSVPS_YET]: 'No reply cards received yet.',
  [k.RESPONSE]: 'Reply',
  [k.GUESTS_AND_MENU_WISH]: 'Guests & Menu Request',
  [k.QUESTIONS_AND_REMARKS]: 'Question/Comment',
  [k.WILL_ATTEND]: 'Commitment',
  [k.WILL_NOT_ATTEND]: 'Cancellation',
  [k.SHOW_ALL_RSVPS]: 'View all requests',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'You have already sent the reply to',
  [k.CANNOT_CHANGE_ANY_MOR]: 'has already been sent. The details cannot be changed.',
  [k.MENU_WISH]: 'Request menu',
  [k.RSVP_LOADING]: 'Response card is loading...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Please make a list below of everyone we can expect',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Please make a list below of everyone we can expect and also tell us your menu preferences',
  [k.GUEST]: 'Guests',
  [k.ADD_GUEST]: 'Add another guest',
  [k.SUBMIT_RSVP]: 'Send reply card',
  [k.WEBSITE]: 'Website',
  [k.OUR_PACKAGES]: 'Our packages',
  [k.PACKAGE_BASIC]: 'Basic',
  [k.PACKAGE_COMPLETE]: 'Complete',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Best Seller',
  [k.SELECTED]: 'Selected',
  [k.SELECT]: 'Select',
  [k.CAN_UPGRADE_LATER]: "Don't worry, you can always upgrade later by simply paying the difference.",
  [k.LOOK_IN_FAQ]: 'Still got questions? Check out our FAQ!',
  [k.PACKAGE_FEATURES_DESIGN]: 'Designs, Colors, Fonts',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Your subdomain with password',
  [k.PACKAGE_FEATURES_MOBILE]: 'Desktop & mobile optimized',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Daily schedule with timetable',
  [k.PACKAGE_FEATURES_LOCATION]: 'Location planner and information',
  [k.PACKAGE_FEATURES_INFOS]: 'Info pages (FAQ, Gifts, etc.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Guest registration & management',
  [k.PACKAGE_FEATURES_MUSIC]: 'Music requests by guests',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Guest photo uploads',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Customer service via email',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Multilingual website',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Your own domain (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Custom design from a professional',
  [k.MAX_FIVE]: 'max. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Check payment status',
  [k.PAYMENT_SUCCESSFULL]:
    'Thank you! The payment was successful, you can now use your wedding app in the following package: ',
  [k.PAYMENT_FAILED]: 'Unfortunately, the payment failed. You can try again or send us an email at ',
  [k.ACTIVATE_PACKAGE_NOW]: 'Unlock now: ',
  [k.PAY]: 'Pay',
  [k.EDITING_MODE]: 'Edit Mode',
  [k.ACTIVE]: 'is active',
  [k.NOT_ACTIVE]: 'is not active',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'An error has occurred. Technical feedback: ',
  [k.TRY_AGAIN_PLEASE]: 'Please try again. If the error occurs repeatedly, you can email us at ',
  [k.PACKAGE_UPGRADE]: 'Upgrade Package',
  [k.PACKAGE_ALREADY_BOUGHT]: 'You have already purchased a package for your wedding.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Of course, you can upgrade your website to a higher package by simply paying the difference. Please send us a short email and we will take care of everything.',
  [k.REGISTRATION_NOT_COMPLETE]: 'Registration is not yet complete. Please click on the link we sent you.',
  [k.YOU_HAVE_AN_EMAIL]: 'You have received an email from us.',
  [k.YOU_MUST_REGISTER_FIRST]: 'You need to complete your registration in order to edit your wedding website.',
  [k.CREATOR_MUST_REGISTER]:
    "It's all free and very quick: just click on the link in the email we sent you. Can't find the email? No problem, just click here: ",
  [k.HERE]: 'Here',
  [k.E_MAIL_RESENT]: 'Email sent again.',
  [k.CONFIRMATION_RESENT_TO]: 'We have resent the confirmation link to you: ',
  [k.CAN_TAKE_SOME_MINUTES]:
    'It may take a few minutes for the email to reach you. If you continue to experience problems, please email us and we will help you: ',
  [k.RESENDING_MAIL]: 'Email will be sent again.',
  [k.DEMO_WEDDING_HINT]: 'Demo site - create your own site for free and try it out!',
  [k.CREATE_OWN_WEBSITE]: 'Create your own website!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Some features are disabled on the demo site.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'You can register, create your own wedding website and try out all the features for free!',
  [k.NO_PACKAGE_BOUGHT_HINT]: 'Free basic version - upgrade now for unlimited photos, guests and more features.',
  [k.LETS_UPGRADE]: "Let's upgrade.",
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'You have reached the maximum number of photos for the free basic package.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'You can upgrade to one of our premium plans to upload an unlimited number of photos.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]: 'You can upgrade to one of our premium plans to add an unlimited number of songs.',
  [k.CONFIRM_DELETE_TITLE]: 'Confirm Delete',
  [k.CONFIRM_DELETE_TEXT]: 'Are you sure you want to delete this item?',
  [k.DELETE]: 'Delete Item',
  [k.ADD_ELEMENT]: 'Add a new item',
  [k.WHICH_ELEMENT_TO_ADD]: 'What type of item do you want to add?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Select an icon for the location',
  [k.IMAGE_POSITION]: 'Image Position',
  [k.SELECT_IMAGE]: 'Select image',
  [k.UPLOAD]: 'Upload Image',
  [k.SELECTED_IMAGE]: 'Selected image',
  [k.NO_IMAGE]: 'No image',
  [k.OTHER_IMAGE]: 'Other image ',
  [k.EDIT_WEDDING_BASE_DATA]: 'Edit wedding details',
  [k.ENTER_NAME_OF_BRIDE]: 'Please enter the name of the bride.',
  [k.ENTER_NAME_OF_GROOM]: "Please enter the groom's name.",
  [k.SELECT_DATE]: 'Please select a date.',
  [k.HINT_CHANGE_URL]:
    'Note: If you would like to change the address where your wedding webpage will be available, please send us an email: ',
  [k.EDIT_COUNTDOWN]: 'Edit Wedding Countdown',
  [k.EDIT_INFO_BOX]: 'Edit info box',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Edit box with large photo',
  [k.EDIT_LOCATION]: 'Edit Location',
  [k.EDIT_PLACE]: 'Edit Location',
  [k.ADD_LOCATION]: 'Add Location',
  [k.ADD_PLACE]: 'Add Location',
  [k.SELECT_SEARCH_RESULT]: 'Please select a search result.',
  [k.EDIT_PAGE_TITLE]: 'Edit page title',
  [k.EDIT_PRESENTS]: 'Edit gift registry',
  [k.PRESENT_INTRO_TEXT]: 'Create your own personalized gift registry to inspire your guests.',
  [k.ADDITIONAL_ENTRY]: 'More entries',
  [k.EDIT_RSVP]: 'Edit RSVP',
  [k.MENU_WISHES_ACTIVATED]: 'Do you want your guests to indicate their menu preferences when they RSVP?',
  [k.EDIT_SCHEDULE_ITEM]: 'Edit menu item ',
  [k.NAVIGATION]: 'Menu',
  [k.COUPLE]: 'Bride and Groom',
  [k.MUSIC]: 'Music',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'You are registered as the groom.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'You are registered as the bride.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Your wedding website address:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Wedding code for your guests: ',
  [k.CURRENT_PACKAGE]: 'Your current package:',
  [k.UPGRADE]: 'Upgrade',
  [k.LOGOUT]: 'Log Out',
  [k.FOR_GUESTS]: 'For guests',
  [k.INVISIBLE]: 'invisible',
  [k.VISIBLE]: 'Visible',
  [k.WEDDING_NOT_FOUND]: 'Wedding page not found.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Sorry, we could not find a wedding webpage at the address you entered. Could it be that you mistyped? Please try again.',
  [k.IF_ERROR_REPEATS]: 'If the error occurs repeatedly, you can email us at',
  [k.ERROR_GENERAL]: 'An error has occurred',
  [k.SORRY_WRITE_MAIL]:
    'We are sorry. Please try again. If the error occurs repeatedly, you can simply send us an e-mail and we will help you further.',
  [k.MOVE_UP]: 'Move up',
  [k.MOVE_DOWN]: 'Scroll down',
  [k.OH_SORRY]: "Oh dear... I've made a mistake...",
  [k.ERROR_REFRESH_PAGE]:
    'We are very sorry. Please feel free to reload the page and try again. If the error occurs repeatedly, you can email us at',
  [k.IF_YOU_WANT_TO_HELP]:
    'If you would like to help us find and fix the error extra quickly, you can copy the following text into your email:',
  [k.ENTER_DISPLAY_NAME]:
    'Great! Please enter your name - nickname works too. It will appear under photos you upload, for example.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Please enter a name.',
  [k.WE_SENT_EMAIL]: 'We have sent you an email. Please click on the link in the email to reset your password.',
  [k.WELCOME_BACK]: 'Welcome back to the site,',
  [k.ALMOST_THERE]: 'Almost there,',
  [k.PLEASE_SET_PASSWORD]: 'Please choose a password now.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Choose a password',
  [k.WELCOME_TO_WEDDING_OF]: 'Welcome to the wedding site of',
  [k.PLEASE_ENTER_MAIL]: '! Please enter your email address.',
  [k.WELCOME_TO_EHO]: 'Welcome to the wedding platform of EureHochzeitOnline.com! Please enter your email address.',
  [k.TO_WEDDING]: 'To the wedding',
  [k.ENTER_WEDDING_CODE]: '! Please enter the wedding code.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Please enter the wedding code.',
  [k.RESTART]: 'Please restart',
  [k.UPLOAD_PREPARING]: 'Upload in preparation...',
  [k.UPLOAD_STARTED]: 'Upload in progress...',
  [k.UPLOAD_FINISHING]: 'Upload completed...',
  [k.UPLOAD_CANCELED]: 'Upload was canceled...',
  [k.INVALID_FILE_TYPE]: 'File type is not valid...',
  [k.MAX_FILE_NUMBER]: 'Maximum number of files has been exceeded...',
  [k.FILE_TOO_LARGE]: 'File is too large...',
  [k.DAYS_TO_GO]: 'Days to go!',
  [k.DAY_TO_GO]: 'One more day!',
  [k.TODAY]: 'Today',
  [k.INVALID_DATE]: 'Date is not valid',
  [k.DATE_MUST_BE_IN_FUTURE]: 'Date must be in the future',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Location address or name',
  [k.ENTER_SONG_WISH]: 'Enter your favorite song here... ',
  [k.NAME_OF_GUEST]: 'Name of the guest',
  [k.QUESTION_REMARK_MAX_1000]: 'Questions/Comments (max. 1000 characters)',
  [k.LEFT]: 'Links',
  [k.TOP]: 'Top',
  [k.RIGHT]: 'Right ',
  [k.BOTTOM]: 'Down',
  [k.NAME_OF_BRIDE]: "Bride's name",
  [k.NAME_OF_GROOM]: "Groom's Name",
  [k.TITLE]: 'Title',
  [k.DESCRIPTION]: 'Description of event',
  [k.NAME_OF_PLACE]: 'Place name',
  [k.STREET]: 'Street',
  [k.HOUSENUMBER]: 'No.',
  [k.ZIP]: 'ZIP CODE',
  [k.CITY]: 'City/Town',
  [k.COUNTRY]: 'Country/State',
  [k.HINTS_FOR_GUESTS]: 'Notes for guests',
  [k.TITLE_FOR_THIS_PAGE]: 'Title for this page',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Subtitle for this page',
  [k.LABEL]: 'Designation',
  [k.AMAZON_URL]: 'Amazon URL (optional)',
  [k.COSTS]: 'Costs',
  [k.NEW_FOOD_OPTION]: 'New menu option',
  [k.TIME]: 'Time',
  [k.SCHEDULE_ITEM]: 'Program item',
  [k.YOUR_NAME_OR_NICKNAME]: 'Your name or nickname',
  [k.YOUR_EMAIL_ADDRESS]: 'Your email address',
  [k.YOUR_WEDDING_CODE]: 'Your wedding code',
  [k.COULD_NOT_SAVE_WEDDING]: 'We are sorry, but we were not able to save the wedding.',
  [k.ALREADY_HAVE_WEDDING]: 'You already have wedding homepages',
  [k.CREATE_NEW]: 'Create a new one',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'We could not retrieve your information.',
  [k.COULD_NOT_SEND_MAIL]: 'We were unable to send the email.',
  [k.DELETE_IMAGE]: 'Delete image',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Are you sure you want to delete this image?',
  [k.COULD_NOT_LOGIN]: 'We were unable to log in.',
  [k.WRONG_PASSWORD_TITLE]: 'Wrong password',
  [k.WRONG_PASSWORD_TEXT]: 'The password you entered is incorrect.',
  [k.WRONG_CODE_TITLE]: 'Incorrect wedding code',
  [k.WRONG_CODE_TEXT]: 'The wedding code you entered is incorrect.',
  [k.LOADING]: 'Loading...',
  [k.GUEST_NAME_MISSING]: 'Please enter the names of the following guests: ',
  [k.GUEST_FOOD_MISSING]: 'Please complete the menu request for the following guests: ',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'You have exceeded the maximum number of attempts. You can try again later.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'You have already used this link to reset your password.',
  [k.PLEASE_ENTER_NAME]: 'Please enter a name',
  [k.COUNTDOWN_VISIBLE]: 'Countdown visible.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Countdown not visible.',
  [k.ASK_FOR_MENU_WISHES]: 'Yes, ask for menu requests',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'No, do not ask for menu requests',
  [k.SPRING]: 'Spring ',
  [k.SUMMER]: 'Summer',
  [k.AUTUMN]: 'Fall',
  [k.WINTER]: 'Winter',
  [k.COULD_NOT_SAVE_PASSWORD]: 'We were unable to save your password.',
  [k.YES_WE_WILL_ATTEND]: 'Yes, we will attend your wedding',
  [k.NO_WE_WILL_NOT_ATTEND]: 'No, we will not attend your wedding',
  [k.CONFIRM_DIALOG_TITLE]: 'Are you sure?',
  [k.ALERT_DIALOG_TITLE]: 'Note',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Wedding Cake',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Wedding Ceremony',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Reception and Champagne',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Dance and party',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Wedding Dinner and Buffet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Wedding Car and Parade',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Games and entertainment',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Dress Code',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel and accommodation',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Photo shoot',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Gifts and presents',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'General and love',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Page Title',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Page Title',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Welcome to this page',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Program Item',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Program item',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'From 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Group photo',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Info Box',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Cake',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' If you would like to bake a cake, please let us know. We look forward to putting together a colorful selection.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Box with large photo',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'Your introduction',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Here you can introduce yourself to your guests. Tell them how you met...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Map for locations',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Wedding Location',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Modern`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Let your love shine in an ambiance that captures the pulse of the times.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Classic`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Clean lines, harmonious colors, and a touch of opulence create an atmosphere that honors your love.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `The vintage style brings back the charm of past eras with a touch of nostalgia and elegance.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Flower`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `This wedding style celebrates the natural beauty of flowers in all their splendor.`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `Boho`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `A relaxed and unconventional atmosphere that combines nature and creativity – perfect for free spirits.`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `Blossom`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `A romantic style that highlights the delicate beauty of blossoms and floral elements.`,
  [k.NOT_SURE_ABOUT_DESIGN]: `Not sure? Don’t worry, you can change the style later without losing any data.`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `You can upload your own photos and logos after free registration.`,
  [k.YOUR_NAMES]: `Your Names`,
  [k.YOUR_WEDDING_DATE]: `Your Wedding Date`,
  [k.WEDDING_DATE_FIX]: `Wedding date is already set`,
  [k.WEDDING_DATE_NOT_FIX]: `Wedding date is not set`,
  [k.YOUR_WEDDING_STYLE]: `Your Website Style`,
  [k.OWN_LOGO]: `Own Logo`,
  [k.START_PHOTO]: `Start Page Photo`,
  [k.USE_START_PHOTO]: `Use your own photo?`,
  [k.WITH_START_PHOTO]: `With own photo`,
  [k.WITHOUT_START_PHOTO]: `Without own photo`,
  [k.ALL_STYLES]: `All Styles`,

  [k.COOKIES_01]: 'Some wedding cookies for you?',
  [k.COOKIES_02]:
    "Clicking 'Confirm All' will accept all cookies. Alternatively, you can make a custom selection. You can revoke your selection at any time through the 'Cookie Settings'.",
  [k.COOKIES_03]: 'Necessary cookies',
  [k.COOKIES_04]: 'These cookies are necessary for the functionality of the site, so you cannot disable them.',
  [k.COOKIES_05]: 'Learn more ',
  [k.COOKIES_06]: 'Marketing and personalization cookies',
  [k.COOKIES_07]:
    'These cookies allow you to receive website content tailored to your user behavior and allow us to tailor our website to the interests of our customers based on usage statistics.',
  [k.COOKIES_08]: 'Accept selection',
  [k.COOKIES_09]: 'Accept All',
  [k.TERMS_AND_CONDITIONS_00]: 'Terms of Use',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt), hereinafter also referred to as 'provider', 'we' or 'EureHochzeitOnline.com', provides a platform for the creation of individual wedding applications. With these applications, users (hereinafter collectively referred to as 'Users') as hosts can create an application for their wedding and offer their guests information about the wedding and interaction opportunities through this application. The following terms and conditions apply to the use of the platform.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Use of the Platform',
  [k.TERMS_AND_CONDITIONS_03]:
    'The use of the Platform is generally free of charge in a trial version. A one-time fee needs to be paid if the platform is used for a wedding with a defined number of guests or more. Both the trial version and the full version require prior registration and the creation of a user account. The User may not allow third parties to use his/her account. The user is obliged to keep his access data secret and to protect it from access by third parties.',
  [k.TERMS_AND_CONDITIONS_04]:
    'The Wedding App will be available for six months after the wedding. After that, we will deactivate the Wedding App so that we can offer the desired address to other couples. If you need to keep your wedding application online for a longer period, we can work out a special arrangement.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Once a wedding app is created, it can only be used for one wedding. If, for example, we discover that a user has given or sold a wedding application to other couples, we reserve the right to prevent such use and to charge the fee for further weddings.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Data Protection',
  [k.TERMS_AND_CONDITIONS_07]: 'By using the software, users accept the ',
  [k.TERMS_AND_CONDITIONS_08]: 'data protection ',
  [k.TERMS_AND_CONDITIONS_09]: 'regulations',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Functions and services of EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'The individual wedding applications that can be created via EureHochzeitOnline.com offer the following features',
  [k.TERMS_AND_CONDITIONS_12]: 'Customization:',
  [k.TERMS_AND_CONDITIONS_13]:
    'The host may configure and design certain aspects of the wedding app, for example, to match the graphic design of the wedding invitations, they can choose backgrounds, images, colors and fonts.',
  [k.TERMS_AND_CONDITIONS_14]: 'Photo gallery:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Guests can take and upload photos before or during the wedding. These photos can be displayed in an automatically updated live image gallery.',
  [k.TERMS_AND_CONDITIONS_16]: 'Music requests:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Guests can use a search function to request songs for the wedding and rate the requests. The host can use this feature to select songs.',
  [k.TERMS_AND_CONDITIONS_18]: 'Wedding Information:',
  [k.TERMS_AND_CONDITIONS_19]:
    'The host can publish the venue with interactive maps, the schedule and other information for their guests in their wedding app.',
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com reserves the right to make changes to the scope and design of the features in the software at any time.',
  [k.TERMS_AND_CONDITIONS_21]:
    'A modern smartphone, tablet or PC with a modern internet browser is required to use the features. In particular, live functions such as picture uploads, wedding quizzes or music requests require a stable and fast internet connection. EureHochzeitOnline.com assumes no liability for the accuracy, completeness, reliability, timeliness, and usability of the content provided.',
  [k.TERMS_AND_CONDITIONS_22]:
    'During maintenance work, which EureHochzeitOnline.com will announce to the hosts, the platform may be temporarily unavailable. We make every effort to keep our service available and are proud to say that we have never disappointed our users. However, we cannot assume any further obligations, in particular the user has no right to constant availability of the service.',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Obligations of the user',
  [k.TERMS_AND_CONDITIONS_24]:
    'The user can save and publish his own content and files on the EureHochzeitOnline.com platform as a guest or as a host. The user is responsible for this content. The user undertakes not to publish any articles, files, wedding information or other content that violates common decency or applicable law. In particular, the user agrees not to publish any posts, files, wedding information or other content ',
  [k.TERMS_AND_CONDITIONS_25]: 'whose publication constitutes a criminal or administrative offense, ',
  [k.TERMS_AND_CONDITIONS_26]: 'violate copyright, trademark or competition laws ',
  [k.TERMS_AND_CONDITIONS_27]: 'that violate the Legal Services Act, ',
  [k.TERMS_AND_CONDITIONS_28]: 'or contains offensive, racist, discriminatory or pornographic content',
  [k.TERMS_AND_CONDITIONS_29]: 'that contain advertising.',
  [k.TERMS_AND_CONDITIONS_30]:
    "EureHochzeitOnline.com has the right to change or delete any content that violates or is suspected of violating these obligations. EureHochzeitOnline.com is also entitled to block the user's access and the wedding application set up if the user violates the terms of use. The user shall compensate the provider for any damage caused by the breach of duty. The provider is entitled to claim indemnification from the user for claims asserted by third parties due to the violation of a right by the User. The user is obliged to support the provider in the defense of such claims. The user is also obliged to bear the costs of an appropriate legal defense of the provider.",
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Intellectual Property',
  [k.TERMS_AND_CONDITIONS_32]: 'Your Intellectual Property',
  [k.TERMS_AND_CONDITIONS_33]:
    'You own the intellectual property rights to the content, text, images and other materials you post. EureHochzeitOnline.com does not claim any intellectual property rights in such content. However, you authorize EureHochzeitOnline.com to access, copy and distribute this content for the purpose of providing it to wedding guests. You also authorize us to perform any other acts necessary to provide our services, including but not limited to making backup copies and providing access to support personnel.',
  [k.TERMS_AND_CONDITIONS_34]: 'Our Intellectual Property',
  [k.TERMS_AND_CONDITIONS_35]:
    'The Provider does not grant any license or other right to use the intellectual property rights of the EureHochzeitOnline.com website or software platform outside of a regularly created wedding application provided in accordance with the Terms of Use. Duplication, distribution, reproduction, transmission, or other use of the intellectual property rights, including making them available to third parties, is not permitted without the prior written consent of the Provider.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Right of withdrawal',
  [k.TERMS_AND_CONDITIONS_37]:
    'You have the right to withdraw from this contract within fourteen days without giving reasons. The withdrawal period is fourteen days from the conclusion of the contract. You can exercise your right of withdrawal by sending an e-mail to',
  [k.TERMS_AND_CONDITIONS_38]:
    'with a clear statement of your decision to withdraw from the contract. You can also use the following sample form in your e-mail:',
  [k.TERMS_AND_CONDITIONS_39]: 'Sample withdrawal form',
  [k.TERMS_AND_CONDITIONS_40]: '- To fidira Software UG (haftungsbeschränkt), e-mail: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    '- I/we ( * ) hereby revoke the contract concluded by me/us ( * ) for the activation of our wedding app ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- Ordered on ( * ) / Received on ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Name of consumer(s)',
  [k.TERMS_AND_CONDITIONS_44]: '- Address of consumer(s)',
  [k.TERMS_AND_CONDITIONS_45]: '- Signature of consumer(s) (only for paper notification)',
  [k.TERMS_AND_CONDITIONS_46]: '- Date ',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Delete as appropriateIn order ',
  [k.TERMS_AND_CONDITIONS_48]:
    'To comply with the withdrawal period, it is sufficient to send the notice of exercise of the right of withdrawal before the end of the withdrawal period.Consequences of revocation:',
  [k.TERMS_AND_CONDITIONS_49]: 'Consequences of revocation:',
  [k.TERMS_AND_CONDITIONS_50]:
    'If you cancel your contract, we will delete your wedding app and, if we have already received payments from you, we will refund those payments to you using the same payment method. There will be no charge to you for this refund.',
  [k.TERMS_AND_CONDITIONS_51]:
    'In the case of a contract for the delivery of digital content that is not on a physical data carrier, such as a wedding app, the right of revocation pursuant to Section 356 (5) BGB shall also expire if the Supplier has commenced performance of the contract after the User has 1. expressly consented to the Supplier commencing performance of the contract prior to the expiration of the revocation period and 2. confirmed that he/she is aware that he/she will lose his/her right of revocation by agreeing to the commencement of performance of the contract.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Warranty and Disclaimer',
  [k.TERMS_AND_CONDITIONS_53]:
    "The Provider provides its services 'as is', 'with all faults' and 'as available' and makes no express or implied warranties of merchantability, fitness for a particular purpose, workmanlike performance, non-infringement or any other warranties to the extent permitted by law. There is also no warranty that the Provider's services (or any part, feature or content thereof) are (or will be) complete, of a particular quality, reliable, secure, accurate, compatible with your intended activities, devices used, operating systems, browsers or programs (of guests or hosts), or that they comply with any legal requirements applicable to you, or that the services are free of viruses, bugs or other harmful components or program limitations. Furthermore, EureHochzeitOnline.com assumes no liability for any company, product or service (including third party services) mentioned or offered on the EureHochzeitOnline.com platform. No liability is assumed for the loss of data and information, and the provider does not guarantee the reconstruction of such data and information. The User shall back up his/her data and information at his/her own risk. EureHochzeitOnline.com also assumes no liability for the content posted by users or the correct identity of users.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Severability Clause',
  [k.TERMS_AND_CONDITIONS_55]:
    'If any provision of this Agreement is held invalid or unenforceable for any reason or to any extent, such invalidity or unenforceability shall not affect the remaining provisions of the Agreement, and the application of such provision shall be enforced to the fullest extent permitted by law. If EureHochzeitOnline.com waives its right to enforce a breach of one or more of the terms and conditions, this does not mean that EureHochzeitOnline.com also waives its right to enforce the other terms and conditions.',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Final provisions',
  [k.TERMS_AND_CONDITIONS_57]:
    'Unless otherwise required by law, the agreements shall be governed by the laws of the Federal Republic of Germany. The exclusive place of jurisdiction for all disputes/claims arising out of or in connection with these Terms of Use shall be Munich. We reserve the right to change these Terms of Use in the future and to post the new version at the same location. After a change of the Terms of Use, the new version must be confirmed by the user.',
  [k.TERMS_AND_CONDITIONS_58]: 'Status: 01.04.2018',
  [k.IMPRINT_00]: 'Imprint/Legal notice',
  [k.IMPRINT_01]: 'fidira Software UG (limited liability)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de is a trademark of fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Mail:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Registered office of the company: Munich, Commercial Register Munich HRB',
  [k.IMPRINT_09]: 'Authorized representatives:',
  [k.PRIVACY_00]: 'Privacy Policy',
  [k.PRIVACY_01]: 'Introduction and General Information',
  [k.PRIVACY_02]:
    'Protecting your privacy and the privacy of your wedding guests is a top priority for EureHochzeitOnline.com. ',
  [k.PRIVACY_03]:
    'In accordance with the requirements of the German Federal Data Protection Act (BDSG) and the German Telemedia Act (TMG), we ensure at all times that your data is only used for the creation and use of your personal wedding app and to optimize the user experience of your wedding app. We use TLS/SSL (Transport Layer Security / Secure Sockets Layer) technology for both the creation and use of wedding apps, so that data is always encrypted during transmission. All of our application servers are located in the European Union and comply with EU data privacy regulations. We will not process your information beyond the purposes described in this Privacy Policy.During the registration process, you will be asked for your consent to the processing described in this Privacy Policy by clicking on the checkbox. By giving your consent, you confirm that we may collect, process and use your personal data to the extent described below. You may withdraw your consent at any time. To do so, simply send an e-mail to our customer service at',
  [k.PRIVACY_04]:
    'During registration, you will be asked for your consent to the processing operations described in the privacy policy, which you give by clicking on the checkbox. By giving your consent, you confirm that we may collect, process and use your personal data to the extent described below. You can also withdraw your consent at any time. To do so, simply send an e-mail to our customer service at',
  [k.PRIVACY_05]:
    '. Upon request, our customer service will also provide you with information about all data stored about you (pursuant to § 13 (7) of the German Telemedia Act (Telemediengesetz)) and will delete this data upon your request.',
  [k.PRIVACY_06]: '§ 1 Responsible body and area of application',
  [k.PRIVACY_07]:
    "The responsible entity is fidira Software UG (haftungsbeschränkt), hereinafter also referred to as 'provider', 'we' or 'EureHochzeitOnline.com'. Details about the address and authorized representatives can be found in the imprint.",
  [k.PRIVACY_08]:
    "This privacy policy covers the collection and use of personal data when visiting and using any of the provider's platforms via internet browsers. Through these offerings, the Provider makes it possible to create an individual wedding app with which the guests can inform themselves about the wedding and interact with each other during the wedding (e.g., share photos or answer questions). The Supplier will also use the personal data for statistical and market analysis purposes. In doing so, we compile anonymous statistics, e.g., on weddings, locations, wedding sizes or app usage, in order to further optimize our offering.",
  [k.PRIVACY_09]: '§ 2 Links to other websites',
  [k.PRIVACY_10]:
    'Our website may contain links to other websites. We have no control over the content to which you are directed after clicking on these links, nor over the processing of your data that may be transmitted to the third party (e.g. your IP address). Therefore, we cannot accept any responsibility for the content of third parties and the processing of such data by third parties.',
  [k.PRIVACY_11]: '§ 3 Use of data without registration',
  [k.PRIVACY_12]:
    'When you visit the website or app of EureHochzeitOnline.com, we automatically save some basic information about your visit: in particular, the IP address, the pages visited and the duration of the visit. We use this information to compile anonymous statistics about user behavior and frequently used features in order to continually improve our offerings. Your IP address is only stored in technical log files for a limited period of time in order to protect our systems and the wedding apps we create from abuse and fraud.',
  [k.PRIVACY_13]: '§ 4 Use of data upon registration',
  [k.PRIVACY_14]:
    "In order to gain access to a wedding app as a guest or to create a wedding app as a host, the user must register. During the registration process, the user's email address, a display name (freely selectable, can also be an invented pseudonym), a wedding code (if applicable), and a password (freely definable) will be stored. This information is used to protect the wedding listings of our hosts from unauthorized access. When creating a wedding app, hosts can enter information about their wedding (e.g. location, time, schedule) that will only be visible to guests of that wedding. This is accomplished by the host generating wedding codes that guests must enter to access the wedding application.",
  [k.PRIVACY_15]:
    'We also use your email address for communication purposes: for example, email notifications may be sent to the host regarding new registrations, other wedding news, and EureHochzeitOnline.com service emails may be sent to the email address (e.g., satisfaction surveys). We also display the email addresses of wedding guests to the host so that they can use them for further wedding organization.',
  [k.PRIVACY_16]: 'Your email address will never be shared with third parties or other companies.',
  [k.PRIVACY_17]: '§ 5 Cookies',
  [k.PRIVACY_18]:
    "In order to provide you with the best user experience, we use 'cookies' and the so-called 'local storage' of your browser. This allows us to store small amounts of data about your Wedding App in your browser so that, for example, your changes are not lost when you close your browser, switch to full screen mode of the App Configurator, or so that you do not have to log in each time. If you do not want to use cookies or want to delete existing cookies, you can disable or remove them through your browser. Please refer to your browser's instructions on how to do this, or contact us for assistance.",
  [k.PRIVACY_19]:
    "We also use cookies for the third party services listed below in '§ 7 Applications and Websites' to provide you with a better user experience.",
  [k.PRIVACY_20]: '§ 6 Applications and Websites',
  [k.PRIVACY_21]: 'Google Maps',
  [k.PRIVACY_22]:
    'We use Google Maps from Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA to display interactive maps. Your IP address, your location and information about your use of our services will be transmitted to and stored by Google on servers in the United States. The terms of use for Google Maps can be found at ',
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "We also use HotJar, a web analytics tool from Hotjar Ltd., to collect and store data for marketing and optimization purposes.The data can be used to create pseudonymous user profiles.Randomly selected individual visits (without additional personal information, so-called 'user attributes') are recorded.This creates a log of mouse movements and clicks with the intention of randomly playing back individual website visits and deriving potential improvements for the website. The data collected will not be used to personally identify the visitor to this website, nor will it be merged with personal data about the bearer of the pseudonym, without the separate consent of the person concerned.If you do not want to be tracked, you can globally disable tracking on all websites that use HotJar for the browser you are currently using by clicking on the following link",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'We use Mailchimp from The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) to send out our newsletter. This allows us to contact subscribers directly. In addition, we analyze your usage behavior in order to optimize our offerings.',
  [k.PRIVACY_29]: 'For this purpose, we share the following personal information with Mailchimp',
  [k.PRIVACY_30]: 'Email Address [First Name] [Wedding Date] [Wedding Information] [Login Details].',
  [k.PRIVACY_31]:
    "Our email mailings will include a link for you to update your personal information. Mailchimp is the recipient of your personal data and acts as a processor on our behalf as far as the distribution of our newsletter is concerned. The processing of the data described in this section is not required by law or contract. Without your consent and the submission of your personal data, we cannot send you a newsletter. In addition, Mailchimp collects the following personal information using cookies and other tracking methods Information about your device (IP address, device information, operating system, browser ID, information about the application you use to read your email, and other information about your hardware and Internet connection. In addition, usage data such as the date and time you opened the email/campaign and browser activity (e.g., which emails/websites were opened) is collected. Mailchimp needs this information to ensure the security and reliability of the systems, to enforce the Terms of Service, and to prevent misuse. This corresponds to Mailchimp's legitimate interest (pursuant to Art. 6 para. 1 lit. f GDPR) and serves the performance of the contract (pursuant to Art. 6 para. 1 lit. b GDPR). Mailchimp also analyzes performance data, such as email delivery statistics and other communication data. This information is used to compile usage and performance statistics for the Services. Mailchimp also collects information about you from other sources. From time to time and on an indeterminate scale, personal information is collected through social media and other third-party data providers. We have no control over these processes. ",
  [k.PRIVACY_32]: 'For more information about your choices and removal options with Mailchimp, please visit',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'The legal basis for this processing is your consent pursuant to Art.6 para.1 lit. a GDPR.You may withdraw your consent to the processing of your personal data at any time.You will find a link to do so in all mailings.You can also revoke your consent using the contact options provided. Withdrawal does not affect the lawfulness of any processing that has already taken place.',
  [k.PRIVACY_35]:
    'Your data will be processed for as long as you have given your consent. Otherwise, it will be deleted after the contract between us and Mailchimp has ended, unless legal requirements make further storage necessary.',
  [k.PRIVACY_36]:
    'Mailchimp has implemented compliance measures for international data transfers. These measures apply to all global operations where Mailchimp processes personal data of individuals in the EU. These policies are based on the EU Standard Contractual Clauses (SCCs). More information can be found at',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    "We also use the Spotify Music Player Widget, a music player from Spotify AB, to allow you to listen to the wedding playlist. The service provider of the Spotify Music Player Widget is Spotify AB, Regeringsgatan 19, 111 53 Stockholm, Sweden. More information about Spotify AB's data processing can be found at ",
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Facebook Pixel',
  [k.PRIVACY_42]:
    "In order to improve EureHochzeitOnline.com and make it known to a larger group of users, we use the Facebook Pixel of Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, USA ('Facebook'). To do this, Facebook places a cookie in your browser. The information generated by this cookie about your use of this website will be transmitted to and stored by Facebook on servers in the United States. Facebook can use this data transfer to determine that a particular page was accessed from your browser and whether this was done by clicking on an advertisement. However, we do not use this data to collect information from our site. Facebook uses this information to help us create additional advertisements and to promote EureHochzeitOnline.com. We evaluate the data obtained from the Facebook pixel in the Facebook analysis tool 'Facebook Analytics' in order to further improve EureHochzeitOnline.com. Facebook may transfer this information to third parties where required to do so by law, or where such third parties process the information on Facebook's behalf. By using EureHochzeitOnline.com, you consent to the processing of data about you by Facebook in the manner described and for the purpose of improving EureHochzeitOnline.com. We do not share any of your personal, contact or wedding information with Facebook. You can learn more about the security and privacy policies of Facebook Pixel at",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'You can also opt out of the collection of information by Facebook Pixel by clicking here:',
  [k.PRIVACY_45]: 'Deactivate Pixel',
  [k.PRIVACY_46]: '§ 7 Right to information and other rights according to the BDSG',
  [k.PRIVACY_47]:
    'We will be happy to provide you with information about your personal data stored by us at any time. Upon your request, we will also correct, block or delete your data. You can contact us at any time with these requests or with declarations of revocation at',
  [k.PRIVACY_48]: 'and we will make the changes as soon as possible. ',
  [k.PRIVACY_49]: '§ 8 Changes to the data protection declaration',
  [k.PRIVACY_50]:
    "The Internet and technical possibilities are constantly developing. It goes without saying that we will use these new possibilities to make our users' weddings even more unforgettable.This may lead us to update and amend our Privacy Policy.We will post those updates here.",
  [k.PRIVACY_51]: 'Last updated: 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'If you need more languages, please write us an e-mail.',
  [k.DECIDE_LANGUAGES]:
    'Decide which languages ​​your wedding website should support. You can enter your texts for all selected languages.',
  [k.EDIT_LANGUAGES]: `Edit Languages`,
  [k.ACTIVE_VOUCHER]: `Active voucher:`,
  [k.ENTER_VOUCHER_CODE]: `Enter coupon code`,
  [k.VOUCHER_CODE]: `Discount code`,
  [k.INVALID_CODE]: `Unfortunately no valid code!`,
  [k.WANT_CUSTOM_LOGO]: `Do you want to use your own wedding logo?`,
  [k.INDIVIDUAL_LOGO]: `Custom logo`,
  [k.STANDARD_LOGO]: `Standard logo`,
  [k.PRIVATE_PURCHASE]: `The purchase is private`,
  [k.COMMERCIAL_PURCHASE]: `The purchase is commercial`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
