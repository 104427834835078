const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Hi,',
  [k.ENTER_NEW_PASSWORD]:
    'Por favor, introduz aqui a tua nova palavra-passe. Serás automaticamente conectado e redireccionado.',
  [k.MINIMUM_6_CHARACTERS]: 'Por favor, utiliza um mínimo de 6 caracteres.',
  [k.SAVE]: 'Guarda',
  [k.UNABLE_TO_RESET_PASSWORD]: 'Não foi possível redefinir a tua palavra-passe.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Palavra-passe alterada com sucesso',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Alterámos a tua palavra-passe! Podes agora iniciar sessão com a tua nova palavra-passe. Estamos a reencaminhar-te agora.',
  [k.FUNCTIONS]: 'Características',
  [k.DESIGNS]: 'Temas',
  [k.PRICES]: 'Preços',
  [k.FAQ]: 'FAQ',
  [k.BLOG]: 'Blogue',
  [k.CREATE_NOW_FREE]: 'Cria agora gratuitamente',
  [k.PERFECT_PAGE_FOR_YOU]: 'O teu site é feito à tua medida!',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]:
    'Obtém o design perfeito em apenas alguns cliques com o nosso configurador de design.',
  [k.DESIGN]: '-Design ',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: 'Como descreverias o casamento dos teus sonhos?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Clica numa das imagens para iniciar o configurador de design',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Cria agora gratuitamente',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Perguntas frequentes',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: 'Tens alguma pergunta ou comentário?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Estamos ansiosos por te ouvir!',
  [k.CONTENT_AND_DESIGN]: 'CONTEÚDO E DESIGN',
  [k.PHOTO_GALLERY]: 'Galerias de fotografias',
  [k.RSVP_CARD]: 'RSVP/Cartão de resposta',
  [k.PLAYLIST]: 'Lista de reprodução',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'MULTILINGUISMO & DOMÍNIO PRÓPRIO',
  [k.DURATION_PASSWORD_PROTECTION]: 'TEMPO DE EXECUÇÃO E PROTECÇÃO POR PALAVRA-PASSE',
  [k.COST_SUPPORT]: 'CUSTOS E APOIO',
  [k.WHAT_CONTENT_TYPICALLY]: 'Que conteúdos são típicos de um site de casamento?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    'Posso criar a página web do casamento antes de todos os pormenores (como a data do casamento) estarem finalizados?',
  [k.CAN_CUSTOMIZE_DESIGN]: 'Posso personalizar o design do meu site de casamento?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    'É possível fazer alterações na página inicial sem que elas sejam imediatamente visíveis para todos?',
  [k.PHOTO_UPLOAD_LIMIT]: 'Existe um limite para o número de fotografias que podemos carregar na galeria?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: 'As fotografias serão guardadas no seu tamanho original?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: 'Os convidados podem descarregar as fotografias carregadas no sítio?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]:
    'Podemos utilizar a galeria de fotografias para uma apresentação de diapositivos em direto durante a nossa festa?',
  [k.HOW_DOES_R_S_V_P_WORK]: 'Como funciona a função RSVP?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: 'Os convidados podem adicionar as suas próprias músicas à lista de reprodução?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: 'O nosso DJ pode aceder à lista de reprodução?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: 'É possível ter a nossa página inicial em diferentes idiomas?',
  [k.OWN_DOMAIN_POSSIBILITY]: 'Podemos obter o nosso próprio domínio para o nosso site de casamento?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: 'Durante quanto tempo estará disponível o nosso site de casamento?',
  [k.PROTECT_SITE_WITH_PASSWORD]: 'Podemos proteger o nosso site com uma password?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'A utilização da EureHochzeitOnline.com é gratuita?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    'Como posso obter ajuda para criar a minha página de casamento na EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    'O conteúdo da tua página de casamento é tão individual como o teu próprio casamento. Para além das informações básicas, como a data e o local, podes acrescentar códigos de vestuário, direcções, pedidos especiais, informações de contacto do oficiante ou um cronograma planeado. Para os convidados que viajam de longe e precisam de alojamento, podes indicar sugestões de hotéis. Também é uma boa ideia partilhar a tua história de amor com os teus convidados ou escrever palavras pessoais sobre o teu parceiro. Não há limites para a tua criatividade.',
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Sim, isto é fácil de fazer e até oferece vantagens significativas. Podes acrescentar informações e fazer actualizações a qualquer momento, mesmo depois de os convites de casamento terem sido enviados.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Sim, a EureHochzeitOnline.com oferece uma variedade de opções de design para o teu site de casamento. Podes personalizar as cores, os tipos de letra e outros elementos. Por favor, contacta-nos se tiveres alguma dúvida ou comentário.',
  [k.INDEED_CHANGES_VISIBLE]:
    'De facto, todas as alterações ao teu site serão imediatamente visíveis para todos os visitantes em tempo real. Atualmente, não oferecemos uma funcionalidade para programar ou ocultar alterações com antecedência.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'Na versão gratuita, há um número limitado de fotos que podes carregar na tua galeria. Isto deve-se ao facto de termos custos associados ao armazenamento e ao tráfego. No entanto, se adquirires os pacotes Premium ou Domínio, poderás carregar um número ilimitado de fotografias para a tua galeria. Consulta o nosso blogue para obteres dicas sobre como obter as fotografias de casamento perfeitas.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Quando carregas as tuas fotografias, criamos tamanhos diferentes para cada imagem. Dependendo do tamanho do ecrã do visitante, o tamanho ideal é automaticamente selecionado para garantir tempos de carregamento rápidos com a melhor qualidade de imagem possível. No entanto, também guardamos os ficheiros originais das fotografias para que também os possas receber.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Sim, os visitantes podem descarregar as fotografias carregadas.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Podes crer! De facto, esta é uma das características mais populares da EureHochzeitOnline. Tudo o que precisas é de uma ligação estável à Internet e, claro, de um ecrã ou projetor.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'A função RSVP facilita a partilha das informações de login do teu site de casamento com os teus convidados. Quando visitarem o teu site de casamento, ser-lhes-á pedido que deixem os seus comentários (por exemplo, com quantas pessoas vão estar presentes, se são crianças ou adultos, e quaisquer pedidos especiais de comida, alergias ou outros requisitos). Na área de convidados, podes acompanhar o número de aceitações e cancelamentos a qualquer momento. Consulta o nosso blogue para obteres instruções passo a passo.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    'Podes crer! Os teus convidados podem sugerir as suas canções favoritas e adicioná-las à lista de reprodução. Assim, não só podes celebrar a diversidade dos teus convidados, como também podes apreciar a música que é importante para ti e para os teus entes queridos.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Claro. Basta convidares o teu DJ para o teu site e todos os teus desejos estarão à sua disposição - mesmo ao vivo durante a tua festa, se quiseres.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Sim, podes ter o teu site em várias línguas. Esta funcionalidade está disponível no pacote Premium e podes traduzir toda a informação para diferentes línguas. Por favor, contacta-nos para que possamos definir a configuração para o teu casamento.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Sim, podes facilmente reservar o teu próprio domínio (por exemplo, www.juliaundromeo.de) para o site do teu casamento com o nosso pacote Premium. Por favor, contacta-nos para que possamos reservar o domínio para ti e configurar o redireccionamento.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'O teu site de casamento estará disponível durante dois anos após a sua criação. Depois disso, será apagado para dar lugar a novos sites de casamento. Lembrar-te-emos com antecedência. As renovações estão disponíveis mediante pedido.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Todas as páginas web de casamento estão protegidas com uma palavra-passe, que te será mostrada após a criação.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Sim, podes criar e desenhar um site de casamento na EureHochzeitOnline.com gratuitamente. No entanto, alguns recursos avançados não são gratuitos, especialmente aqueles que exigem recursos adicionais do servidor. Não há taxas ocultas.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'Se precisares de ajuda para criar o teu site de casamento, não hesites em enviar-nos uma mensagem para beeni@eurehochzeitonline.com. Entraremos em contacto contigo o mais rapidamente possível para te ajudar.',
  [k.KEY_FEATURES_OVERVIEW]: 'Dá uma vista de olhos nas principais características!',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'Aqui está o teu site de casamento com tudo o que precisas para criar o casamento dos teus sonhos.',
  [k.NEW_NOW]: 'Novo:',
  [k.MULTILINGUAL_WEDDINGS]: 'Casamentos multilingues!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Agora disponível: Websites multilíngues!',
  [k.SCHEDULE]: 'Linha do tempo do casamento',
  [k.LOCATIONS]: 'Localização do casamento',
  [k.RSVP]: 'Cartão de resposta',
  [k.GUESTMANAGEMENT]: 'Gestão de convidados',
  [k.HOTEL_RECOMMENDATION]: 'Recomendações de hotéis',
  [k.PRESENT_TABLE]: 'Mesa de Presentes',
  [k.OWN_DOMAIN]: 'Domínio personalizado',
  [k.DIVERSE_LAYOUTS]: 'Inúmeros layouts e designs para escolheres e personalizares.',
  [k.YOUR_WEDDING_DAY]: 'O dia do teu casamento bem organizado para os teus convidados.',
  [k.CLEAR_DIRECTIONS]: 'Direcções claras para todos os locais do casamento.',
  [k.NO_GUESSWORK]: 'Acabaram-se as perguntas aborrecidas sobre quem vem ao casamento.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Recolhe endereços, alergias, etc. com um clique!',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Seleção de hotéis para os teus convidados para facilitar a sua viagem!',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'A tua lista de casamento facilitada.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'As tuas fotografias de casamento e as dos teus convidados num só lugar.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]:
    'A tua própria lista de reprodução Spotify - criada por ti e pelos teus convidados.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Site de casamento com o teu próprio domínio e proteção por palavra-passe.',
  [k.YOUR_WEDDING_WEBSITE]: 'O TEU SITE DE CASAMENTO',
  [k.UNIQUE_LIKE_YOU]: '- TÃO ÚNICO COMO TU!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Fácil de configurar e personalizar!',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]:
    'Todos os detalhes do teu evento num piscar de olhos - seja no teu telemóvel ou PC',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Confirmações e pedidos de convidados na ponta dos teus dedos',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]:
    'A tua festa de todos os ângulos - galeria de imagens ao vivo para ti e para os teus convidados',
  [k.MULTILINGUAL_SUPPORT]: '... claro que também para casamentos multilingues',
  [k.FREE_CREATE]: 'Cria agora gratuitamente',
  [k.DEMO_WEBSITE]: 'Demonstração do site',
  [k.OUR_PRICING]: 'Os nossos preços',
  [k.UNLOCK_ALL_FEATURES]: 'Desbloqueia todas as funcionalidades que desejas por uma taxa única',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: 'Tens alguma pergunta ou pedido?',
  [k.WE_ARE_HERE_TO_HELP]: 'Estamos ansiosos por te ouvir!',
  [k.USER_TESTIMONIALS]: 'Vozes dos nossos utilizadores',
  [k.THOUSANDS_OF_PEOPLE]: 'Milhares de pessoas já celebraram o seu casamento com a EureHochzeitOnline!',
  [k.OUR_GIFT_TIP]: 'A nossa ideia de presente:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Oferece aos noivos um site de casamento personalizado!',
  [k.BRIDE]: 'Noiva',
  [k.F_WITNESS]: 'Dama de Honra',
  [k.GROOM]: 'Noivo',
  [k.TESTIMONIAL_1]:
    'Criar o nosso site foi muito fácil. Ficámos realmente surpreendidos com a facilidade com que foi possível adicionar informações, mesmo as mais complicadas. Conseguimos realmente criar o NOSSO site. Os nossos convidados ficaram impressionados!',
  [k.TESTIMONIAL_2]:
    'Foi fantástico o facto de todos os nossos convidados poderem utilizar o site na sua própria língua. Adoraram a função de lista de reprodução que lhes permitia sugerir as suas próprias canções favoritas. Desta forma, a diversidade dos convidados também foi celebrada através da música da festa.',
  [k.TESTIMONIAL_3]:
    'O nosso sítio Web foi uma ajuda inestimável durante o planeamento do nosso casamento. Pudemos atualizar informações importantes em qualquer altura e até mostrar fotografias numa bela galeria de fotografias. Os nossos convidados adoraram o slideshow ao vivo e nós adorámos ainda mais.',
  [k.TESTIMONIAL_4]:
    'Mais de um ano depois, ainda gostamos de ouvir a lista de reprodução do nosso casamento no Spotify. O nosso site de casamento ajudou-nos a ter um casamento fantástico com memórias maravilhosas.',
  [k.THANK_YOU]: 'Muito obrigado!',
  [k.ACTIVATED_MAIL]: 'Ativaste o teu endereço de e-mail!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Clica aqui para ires para o site de casamento de',
  [k.AND]: 'e',
  [k.BE_REDIRECTED]: 'para seres redireccionado.',
  [k.SET_A_PASSWORD]: 'Por favor, define uma palavra-passe para que possas sempre entrar e editar o teu casamento!',
  [k.SET_A_PASSWORD_SHORT]: 'Escolhe uma palavra-passe',
  [k.ERROR_OCCURED]:
    'Ocorreu um erro. Por favor, tenta novamente. Se o erro ocorrer repetidamente, podes enviar-nos um e-mail para:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Já tens uma palavra-passe',
  [k.PASSWORD_ALREADY_EXISTING]: 'Verificámos que já criaste uma palavra-passe. Podes',
  [k.GO_TO_WEDDING]: 'ir à página inicial do teu casamento aqui e registar-te',
  [k.I_AGREE]: 'Aceito o teu registo',
  [k.REGISTER_NOW]: 'Regista-te agora!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: 'O teu site de casamento gratuito!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Já criaste as seguintes páginas de casamento. Provavelmente preferes editar as páginas de casamento existentes em vez de criar uma nova. Clica em baixo.',
  [k.WEDDING]: 'Sites de casamento',
  [k.ENTER_PASSWORD]: 'Por favor, introduz a tua palavra-passe',
  [k.USER_ALREADY_EXISTING]:
    'Já temos um utilizador registado com este endereço de e-mail. Por favor, introduz a tua palavra-passe.',
  [k.BACK]: 'Volta atrás',
  [k.LOGIN]: 'Regista-te',
  [k.FORGOT_PASSWORD]: 'Esqueceste-te da tua palavra-passe? Por favor, clica aqui.',
  [k.MAIL_SENT]: 'Enviámos-te um e-mail. Clica na ligação do e-mail para redefinires a tua palavra-passe.',
  [k.OK]: 'OK',
  [k.PERFECT]: 'Perfeito,',
  [k.NAME_OF]: 'Qual é o teu nome?',
  [k.SPOUSE_M]: 'O teu escolhido',
  [k.SPOUSE_F]: 'O teu escolhido',
  [k.NEXT]: 'Seguinte',
  [k.WHATS_YOUR_NAME]: 'És fantástico! Qual é o teu nome?',
  [k.PREPARING_1]: 'Estamos a preparar a tua aplicação de casamento...',
  [k.PREPARING_2]: 'Guardamos o design que escolheste...',
  [k.PREPARING_3]: 'Faz alguns ajustes finais...',
  [k.FINISHED_ENTER_MAIL]:
    'Já está! Introduz o teu endereço de e-mail para que possamos guardar a tua aplicação de casamento e enviar-te os teus dados de acesso.',
  [k.ACCEPT_TERMS]: 'Por favor, aceita os Termos de Serviço.',
  [k.ENTER_VALID_MAIL]: 'Introduz um endereço de e-mail válido.',
  [k.QUESTION_BRIDE_OR_GROOM]: 'És a noiva ou o noivo?',
  [k.QUESTION_WEDDING_DATE]: 'Sabes a data do teu casamento?',
  [k.YES_WE_KNOW]: 'Sim, já sabes!',
  [k.NO_WE_DONT_KNOW]: 'Não, ainda não sabes!',
  [k.MAKE_SELECTION]: 'Por favor, faz uma seleção.',
  [k.QUESTION_WEDDING_LOCATION]: 'Já tens um local?',
  [k.YES_WE_HAVE]: 'Sim, já tens!',
  [k.ENTER_YOUR_LOCATION]: 'Por favor, introduz a localização do teu casamento.',
  [k.LOCATION_CLASSIC]: 'Clássico',
  [k.LOCATION_COUNTRY]: 'Campestre',
  [k.LOCATION_OUTDOOR]: 'Ao ar livre',
  [k.LOCATION_PALACE_HOTEL]: 'Castelo',
  [k.QUESTION_WEDDING_STYLE]: 'Que estilo preferes?',
  [k.HOTEL]: 'Hotéis',
  [k.INFOS]: 'Informação',
  [k.LOCATION]: 'Localização',
  [k.PHOTOS]: 'Fotos',
  [k.NO_MUSIC_SAVED]: 'Ainda não tens músicas guardadas...',
  [k.SEARCH_FOR_SONGS]: 'Procura a tua música favorita acima!',
  [k.LISTEN_TO_FULL_LIST]: 'Ouve a lista de reprodução completa do casamento',
  [k.OPEN_IN_SPOTIFY]: 'Abre no Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Ouve as músicas individuais da lista de reprodução',
  [k.NO_RESULT_FOUND]: 'Não encontraste resultados',
  [k.CANCEL]: 'Cancela',
  [k.ADD_SONG]: 'Adiciona uma música',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Ainda não carregaste nenhuma foto...',
  [k.ADD_SOME_PHOTOS]: 'Clica em cima e tira fotos!',
  [k.DROP_PHOTOS_HERE_OR]: 'Guarda as fotos aqui ou',
  [k.SHOOT_PHOTO]: 'Tira uma foto',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Vê todas as prendas e entradas',
  [k.LOADING_BACKINGS]: 'As entradas foram carregadas...',
  [k.NO_PRESENTS_YET]: 'Ainda não adicionaste nenhum presente à tua lista de presentes.',
  [k.PRESENT]: 'Adiciona um presente',
  [k.E_MAIL_ADDRESS]: 'O teu endereço de e-mail',
  [k.DATE]: 'Data do presente',
  [k.NO_BACKINGS_YET]: 'Ainda não tens entradas',
  [k.CLOSE]: 'fecha',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Apenas visível para os noivos)',
  [k.SHOW_ALL_ENTRIES]: 'Mostra todas as entradas',
  [k.EUR]: 'MONTANTE EUR',
  [k.ADD_BACKING]: 'Junta-te a nós',
  [k.BUY]: 'Compra agora',
  [k.YOUR_BACKING_SAVED]: 'Estás registado!',
  [k.A_GUEST_ALREADY_BACKED]: 'Um convidado já se registou.',
  [k.DELETE_MY_BACKING]: 'Apaga o meu registo.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Visão geral de todos os registos',
  [k.LOADING_RSVPS]: 'Os registos estão a carregar...',
  [k.NO_RSVPS_YET]: 'Ainda não recebeste nenhum cartão de resposta.',
  [k.RESPONSE]: 'Responde',
  [k.GUESTS_AND_MENU_WISH]: 'Convidados e pedido de menu',
  [k.QUESTIONS_AND_REMARKS]: 'Pergunta/Comentário',
  [k.WILL_ATTEND]: 'Compromisso',
  [k.WILL_NOT_ATTEND]: 'Cancelamento',
  [k.SHOW_ALL_RSVPS]: 'Vê todos os pedidos',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Já enviaste a resposta a',
  [k.CANNOT_CHANGE_ANY_MOR]: 'já foi enviada. Os detalhes não podem ser alterados.',
  [k.MENU_WISH]: 'Menu de pedidos',
  [k.RSVP_LOADING]: 'O cartão de resposta está a carregar...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Por favor, faz uma lista abaixo de todos os que podemos esperar',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Por favor, faz uma lista abaixo de todas as pessoas que podemos esperar e diz-nos também as tuas preferências de menu',
  [k.GUEST]: 'Convidados',
  [k.ADD_GUEST]: 'Adiciona outro convidado',
  [k.SUBMIT_RSVP]: 'Envia o cartão de resposta',
  [k.WEBSITE]: 'Sítio Web',
  [k.OUR_PACKAGES]: 'Os nossos pacotes',
  [k.PACKAGE_BASIC]: 'Básico',
  [k.PACKAGE_COMPLETE]: 'Completo',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Melhor vendedor',
  [k.SELECTED]: 'Selecionado',
  [k.SELECT]: 'Selecionar',
  [k.CAN_UPGRADE_LATER]: 'Não te preocupes, podes sempre atualizar mais tarde, pagando simplesmente a diferença.',
  [k.LOOK_IN_FAQ]: 'Ainda tens dúvidas? Consulta as nossas FAQ!',
  [k.PACKAGE_FEATURES_DESIGN]: 'Desenhos, cores, tipos de letra',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'O teu subdomínio com palavra-passe',
  [k.PACKAGE_FEATURES_MOBILE]: 'Optimizado para computador e telemóvel',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Agenda diária com horário',
  [k.PACKAGE_FEATURES_LOCATION]: 'Planeador de localização e informações',
  [k.PACKAGE_FEATURES_INFOS]: 'Páginas de informação (FAQ, Ofertas, etc.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Registo e gestão de convidados',
  [k.PACKAGE_FEATURES_MUSIC]: 'Pedidos de música pelos convidados',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Envio de fotografias dos convidados',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Serviço de apoio ao cliente por correio eletrónico',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Sítio Web multilingue',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'O teu próprio domínio (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Design personalizado por um profissional',
  [k.MAX_FIVE]: 'max. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Verifica o estado do pagamento',
  [k.PAYMENT_SUCCESSFULL]:
    'Agradece! O pagamento foi bem sucedido, agora podes usar a tua aplicação de casamento no seguinte pacote:',
  [k.PAYMENT_FAILED]: 'Infelizmente, o pagamento falhou. Podes tentar novamente ou enviar-nos um e-mail para',
  [k.ACTIVATE_PACKAGE_NOW]: 'Desbloqueia agora:',
  [k.PAY]: 'Paga',
  [k.EDITING_MODE]: 'Modo de edição',
  [k.ACTIVE]: 'está ativo',
  [k.NOT_ACTIVE]: 'não está ativo',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Ocorreu um erro. Feedback técnico:',
  [k.TRY_AGAIN_PLEASE]: 'Tenta novamente. Se o erro ocorrer repetidamente, podes enviar-nos um e-mail para',
  [k.PACKAGE_UPGRADE]: 'Atualizar pacote',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Já compraste um pacote para o teu casamento.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Claro que podes atualizar o teu site para um pacote superior, pagando simplesmente a diferença. Por favor, envia-nos um breve e-mail e nós trataremos de tudo.',
  [k.REGISTRATION_NOT_COMPLETE]: 'O registo ainda não está completo. Por favor, clica no link que te enviamos.',
  [k.YOU_HAVE_AN_EMAIL]: 'Recebeste um e-mail da nossa parte.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Tens de completar o teu registo para poderes editar o teu site de casamento.',
  [k.CREATOR_MUST_REGISTER]:
    'É tudo gratuito e muito rápido: basta clicar no link do e-mail que te enviamos. Não encontras o e-mail? Não há problema, clica aqui:',
  [k.HERE]: 'Clica aqui',
  [k.E_MAIL_RESENT]: 'Enviámos-te um novo e-mail.',
  [k.CONFIRMATION_RESENT_TO]: 'Voltámos a enviar-te a ligação de confirmação:',
  [k.CAN_TAKE_SOME_MINUTES]:
    'Pode demorar alguns minutos até que o e-mail chegue até ti. Se continuares a ter problemas, envia-nos um e-mail e nós ajudar-te-emos:',
  [k.RESENDING_MAIL]: 'O e-mail será enviado novamente.',
  [k.DEMO_WEDDING_HINT]: 'Site de demonstração - cria o teu próprio site gratuitamente e experimenta-o!',
  [k.CREATE_OWN_WEBSITE]: 'Cria o teu próprio site!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Algumas funcionalidades estão desactivadas no site de demonstração.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Podes registar-te, criar o teu próprio site de casamento e experimentar todas as funcionalidades gratuitamente!',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    'Versão básica gratuita - actualiza agora para teres fotos ilimitadas, convidados e mais funcionalidades.',
  [k.LETS_UPGRADE]: 'Faz a atualização.',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Atingiste o número máximo de fotografias para o pacote básico gratuito.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Podes atualizar para um dos nossos planos premium para carregar um número ilimitado de fotografias.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Podes atualizar para um dos nossos planos premium para adicionar um número ilimitado de músicas.',
  [k.CONFIRM_DELETE_TITLE]: 'Confirma a eliminação',
  [k.CONFIRM_DELETE_TEXT]: 'Tens a certeza de que pretendes apagar este item?',
  [k.DELETE]: 'Eliminar item',
  [k.ADD_ELEMENT]: 'Adiciona um novo item',
  [k.WHICH_ELEMENT_TO_ADD]: 'Que tipo de item pretendes adicionar?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Selecciona um ícone para a localização',
  [k.IMAGE_POSITION]: 'Posição da imagem',
  [k.SELECT_IMAGE]: 'Selecciona a imagem',
  [k.UPLOAD]: 'Carrega a imagem',
  [k.SELECTED_IMAGE]: 'Imagem selecionada',
  [k.NO_IMAGE]: 'Sem imagem',
  [k.OTHER_IMAGE]: 'Outra imagem',
  [k.EDIT_WEDDING_BASE_DATA]: 'Edita os detalhes do casamento',
  [k.ENTER_NAME_OF_BRIDE]: 'Por favor, introduz o nome da noiva.',
  [k.ENTER_NAME_OF_GROOM]: 'Por favor, introduz o nome do noivo.',
  [k.SELECT_DATE]: 'Por favor, selecciona uma data.',
  [k.HINT_CHANGE_URL]:
    'Nota: Se quiseres alterar o endereço onde a página web do teu casamento estará disponível, envia-nos um e-mail:',
  [k.EDIT_COUNTDOWN]: 'Edita a contagem decrescente do casamento',
  [k.EDIT_INFO_BOX]: 'Edita a caixa de informação',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Editar caixa com foto grande',
  [k.EDIT_LOCATION]: 'Edita a localização',
  [k.EDIT_PLACE]: 'Edita a localização',
  [k.ADD_LOCATION]: 'Adicionar local',
  [k.ADD_PLACE]: 'Adicionar localização',
  [k.SELECT_SEARCH_RESULT]: 'Por favor, selecciona um resultado de pesquisa.',
  [k.EDIT_PAGE_TITLE]: 'Edita o título da página',
  [k.EDIT_PRESENTS]: 'Editar registo de presentes',
  [k.PRESENT_INTRO_TEXT]: 'Cria a tua própria lista de presentes personalizada para inspirar os teus convidados.',
  [k.ADDITIONAL_ENTRY]: 'Mais entradas',
  [k.EDIT_RSVP]: 'Editar RSVP',
  [k.MENU_WISHES_ACTIVATED]:
    'Queres que os teus convidados indiquem as suas preferências de menu quando confirmarem a sua presença?',
  [k.EDIT_SCHEDULE_ITEM]: 'Edita o item do menu',
  [k.NAVIGATION]: 'Define o menu',
  [k.COUPLE]: 'Noiva e noivo',
  [k.MUSIC]: 'Menu de música',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Estás registado como noivo.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Estás registado como noiva.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'O endereço do teu site de casamento:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Código de casamento para os teus convidados:',
  [k.CURRENT_PACKAGE]: 'O teu pacote atual:',
  [k.UPGRADE]: 'Actualiza-te',
  [k.LOGOUT]: 'Termina a sessão',
  [k.FOR_GUESTS]: 'Para os teus convidados',
  [k.INVISIBLE]: 'invisível',
  [k.VISIBLE]: 'Visível',
  [k.WEDDING_NOT_FOUND]: 'Não encontraste a página do casamento.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Lamentamos, mas não foi possível encontrar uma página de casamento no endereço que introduziste. Será que escreveste mal? Tenta novamente.',
  [k.IF_ERROR_REPEATS]: 'Se o erro ocorrer repetidamente, podes enviar-nos um e-mail para',
  [k.ERROR_GENERAL]: 'Ocorreu um erro',
  [k.SORRY_WRITE_MAIL]:
    'Desculpa-nos. Tenta novamente. Se o erro ocorrer repetidamente, podes simplesmente enviar-nos uma mensagem de correio eletrónico e nós ajudar-te-emos.',
  [k.MOVE_UP]: 'Move para cima',
  [k.MOVE_DOWN]: 'Desloca-te para baixo',
  [k.OH_SORRY]: 'Oh, meu Deus... Enganei-me...',
  [k.ERROR_REFRESH_PAGE]:
    'Lamentamos imenso. Não hesites em recarregar a página e tentar novamente. Se o erro ocorrer repetidamente, podes enviar-nos um e-mail para',
  [k.IF_YOU_WANT_TO_HELP]:
    'Se quiseres ajudar-nos a encontrar e corrigir o erro mais rapidamente, podes copiar o seguinte texto para o teu e-mail:',
  [k.ENTER_DISPLAY_NAME]:
    'Ótimo! Introduz o teu nome - a alcunha também serve. Aparecerá por baixo das fotografias que carregas, por exemplo.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Por favor, introduz um nome.',
  [k.WE_SENT_EMAIL]: 'Enviámos-te um e-mail. Clica na ligação contida no e-mail para redefinires a tua palavra-passe.',
  [k.WELCOME_BACK]: 'Bem-vindo de volta ao sítio,',
  [k.ALMOST_THERE]: 'Estás quase lá,',
  [k.PLEASE_SET_PASSWORD]: 'Escolhe agora uma palavra-passe.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Escolhe uma palavra-passe',
  [k.WELCOME_TO_WEDDING_OF]: 'Bem-vindo ao site de casamento de',
  [k.PLEASE_ENTER_MAIL]: '! Por favor, insere o teu endereço de e-mail.',
  [k.WELCOME_TO_EHO]:
    'Bem-vindo à plataforma de casamentos da EureHochzeitOnline.com! Por favor, insere o teu endereço de e-mail.',
  [k.TO_WEDDING]: 'Para o casamento',
  [k.ENTER_WEDDING_CODE]: '! Por favor, introduz o código de casamento.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Por favor, introduz o código de casamento.',
  [k.RESTART]: 'Por favor, reinicia',
  [k.UPLOAD_PREPARING]: 'Carrega em preparação...',
  [k.UPLOAD_STARTED]: 'Carrega em curso...',
  [k.UPLOAD_FINISHING]: 'Carrega o ficheiro completo...',
  [k.UPLOAD_CANCELED]: 'A transferência foi cancelada...',
  [k.INVALID_FILE_TYPE]: 'O tipo de ficheiro não é válido...',
  [k.MAX_FILE_NUMBER]: 'O número máximo de ficheiros foi ultrapassado...',
  [k.FILE_TOO_LARGE]: 'O ficheiro é demasiado grande...',
  [k.DAYS_TO_GO]: 'Faltam dias!',
  [k.DAY_TO_GO]: 'Falta um dia!',
  [k.TODAY]: 'Hoje',
  [k.INVALID_DATE]: 'A data não é válida',
  [k.DATE_MUST_BE_IN_FUTURE]: 'A data deve estar no futuro',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Endereço ou nome do local',
  [k.ENTER_SONG_WISH]: 'Introduz aqui a tua música preferida...',
  [k.NAME_OF_GUEST]: 'Nome do convidado',
  [k.QUESTION_REMARK_MAX_1000]: 'Perguntas/Comentários (máx. 1000 caracteres)',
  [k.LEFT]: 'Ligações',
  [k.TOP]: 'Acima',
  [k.RIGHT]: 'Para a direita',
  [k.BOTTOM]: 'Em baixo',
  [k.NAME_OF_BRIDE]: 'Nome da noiva',
  [k.NAME_OF_GROOM]: 'Nome do noivo',
  [k.TITLE]: 'Título do evento',
  [k.DESCRIPTION]: 'Descrição do evento',
  [k.NAME_OF_PLACE]: 'Nome do local',
  [k.STREET]: 'Rua',
  [k.HOUSENUMBER]: 'Não.',
  [k.ZIP]: 'CÓDIGO POSTAL',
  [k.CITY]: 'Cidade',
  [k.COUNTRY]: 'País/Estado',
  [k.HINTS_FOR_GUESTS]: 'Notas para os hóspedes',
  [k.TITLE_FOR_THIS_PAGE]: 'Título para esta página',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Subtítulo para esta página',
  [k.LABEL]: 'Designação',
  [k.AMAZON_URL]: 'URL da Amazon (opcional)',
  [k.COSTS]: 'Custos',
  [k.NEW_FOOD_OPTION]: 'Nova opção de menu',
  [k.TIME]: 'Tempo',
  [k.SCHEDULE_ITEM]: 'Item do programa',
  [k.YOUR_NAME_OR_NICKNAME]: 'O teu nome ou alcunha',
  [k.YOUR_EMAIL_ADDRESS]: 'O teu endereço de e-mail',
  [k.YOUR_WEDDING_CODE]: 'O código do teu casamento',
  [k.COULD_NOT_SAVE_WEDDING]: 'Lamentamos, mas não foi possível guardar o teu casamento.',
  [k.ALREADY_HAVE_WEDDING]: 'Já tens uma página de casamento',
  [k.CREATE_NEW]: 'Cria uma nova',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'Não foi possível recuperar as tuas informações.',
  [k.COULD_NOT_SEND_MAIL]: 'Não foi possível enviar-te o e-mail.',
  [k.DELETE_IMAGE]: 'Eliminar imagem',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: 'Tens a certeza de que queres apagar esta imagem?',
  [k.COULD_NOT_LOGIN]: 'Não foi possível iniciar sessão.',
  [k.WRONG_PASSWORD_TITLE]: 'Palavra-passe errada',
  [k.WRONG_PASSWORD_TEXT]: 'A palavra-passe que introduziste está incorrecta.',
  [k.WRONG_CODE_TITLE]: 'Código de casamento incorreto',
  [k.WRONG_CODE_TEXT]: 'O código de casamento que introduziste está incorreto.',
  [k.LOADING]: 'Carregamento...',
  [k.GUEST_NAME_MISSING]: 'Por favor, introduz os nomes dos seguintes convidados:',
  [k.GUEST_FOOD_MISSING]: 'Por favor, completa o pedido de menu para os seguintes convidados:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Excedeste o número máximo de tentativas. Podes tentar novamente mais tarde.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Já utilizaste esta ligação para redefinir a tua palavra-passe.',
  [k.PLEASE_ENTER_NAME]: 'Por favor, introduz um nome',
  [k.COUNTDOWN_VISIBLE]: 'Contagem decrescente visível.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Contagem decrescente não visível.',
  [k.ASK_FOR_MENU_WISHES]: 'Sim, pede pedidos de menu',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'Não, não solicites pedidos de ementas',
  [k.SPRING]: 'primavera',
  [k.SUMMER]: 'verão',
  [k.AUTUMN]: 'outono',
  [k.WINTER]: 'inverno',
  [k.COULD_NOT_SAVE_PASSWORD]: 'Não foi possível guardar a tua palavra-passe.',
  [k.YES_WE_WILL_ATTEND]: 'Sim, vamos ao teu casamento',
  [k.NO_WE_WILL_NOT_ATTEND]: 'Não, não iremos ao teu casamento',
  [k.CONFIRM_DIALOG_TITLE]: 'Tens a certeza?',
  [k.ALERT_DIALOG_TITLE]: 'Nota',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Bolo de casamento',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Cerimónia de casamento',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Receção e champanhe',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Dança e festa',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Jantar de Casamento e Buffet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Carro do casamento e desfile',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Jogos e animação',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Código de vestuário',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel e alojamento',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Sessão fotográfica',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Presentes e prendas',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'Geral e amor',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Título da página',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Título da página',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Bem-vindo a esta página',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Item do programa',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Programa',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'A partir das 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Fotografia de grupo',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Caixa de informação',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Bolo',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' Se quiseres fazer um bolo, avisa-nos. Estamos ansiosos por reunir uma seleção colorida.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Caixa com foto grande',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'A tua apresentação',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Aqui podes apresentar-te aos teus convidados. Conta-lhes como te conheceste...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Mapa dos locais',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Local do casamento',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Moderno`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Deixe seu amor brilhar em um ambiente que capta o pulso dos tempos.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Clássico`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Linhas limpas, cores harmoniosas e um toque de opulência criam um ambiente que honra o seu amor.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `O estilo vintage traz de volta o charme das épocas passadas, com um toque de nostalgia e elegância.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Flor`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `Este estilo de casamento celebra a beleza natural das flores em todo o seu esplendor.`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `Boho`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `Uma atmosfera descontraída e não convencional que une natureza e criatividade – perfeita para espíritos livres.`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `Florescer`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `Um estilo romântico que destaca a beleza delicada das flores e dos elementos florais.`,
  [k.NOT_SURE_ABOUT_DESIGN]: `Não tem certeza? Não se preocupe, você pode mudar o estilo mais tarde sem perder os dados.`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `Você pode fazer upload das suas próprias fotos e logotipos após o registro gratuito.`,
  [k.YOUR_NAMES]: `Seus nomes`,
  [k.YOUR_WEDDING_DATE]: `A data do seu casamento`,
  [k.WEDDING_DATE_FIX]: `Data do casamento já definida`,
  [k.WEDDING_DATE_NOT_FIX]: `Data do casamento não definida`,
  [k.YOUR_WEDDING_STYLE]: `Estilo do seu site`,
  [k.OWN_LOGO]: `Logotipo próprio`,
  [k.START_PHOTO]: `Foto da página inicial`,
  [k.USE_START_PHOTO]: `Usar foto própria?`,
  [k.WITH_START_PHOTO]: `Com foto própria`,
  [k.WITHOUT_START_PHOTO]: `Sem foto própria`,
  [k.ALL_STYLES]: `Todos os estilos`,

  [k.COOKIES_01]: 'Queres umas bolachas de casamento?',
  [k.COOKIES_02]:
    "Se clicares em 'Confirmar tudo', aceitarás todos os cookies. Em alternativa, podes fazer uma seleção personalizada. Podes revogar a tua seleção a qualquer momento através das 'Definições de cookies'.",
  [k.COOKIES_03]: 'Cookies necessários',
  [k.COOKIES_04]: 'Estes cookies são necessários para a funcionalidade do sítio, pelo que não os podes desativar.',
  [k.COOKIES_05]: 'Aprende mais',
  [k.COOKIES_06]: 'Cookies de marketing e personalização',
  [k.COOKIES_07]:
    'Estes cookies permitem-te receber conteúdos do site adaptados ao teu comportamento de utilizador e permitem-nos adaptar o nosso site aos interesses dos nossos clientes com base em estatísticas de utilização.',
  [k.COOKIES_08]: 'Aceitar seleção',
  [k.COOKIES_09]: 'Aceita tudo',
  [k.TERMS_AND_CONDITIONS_00]: 'Condições de utilização',
  [k.TERMS_AND_CONDITIONS_01]:
    "A fidira Software UG (haftungsbeschränkt), doravante também designada por 'fornecedor', 'nós' ou 'EureHochzeitOnline.com', disponibiliza uma plataforma para a criação de aplicações individuais para casamentos. Com estas aplicações, os utilizadores (doravante designados coletivamente por 'Utilizadores') como anfitriões podem criar uma aplicação para o seu casamento e oferecer aos seus convidados informações sobre o casamento e oportunidades de interação através desta aplicação. Os seguintes termos e condições aplicam-se à utilização da plataforma.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Utilização da Plataforma',
  [k.TERMS_AND_CONDITIONS_03]:
    'A utilização da plataforma é geralmente gratuita numa versão experimental. Se a plataforma for utilizada para um casamento com um número definido de convidados ou superior, é necessário pagar uma taxa única. Tanto a versão de teste como a versão completa requerem um registo prévio e a criação de uma conta de utilizador. O utilizador não pode permitir que terceiros utilizem a sua conta. O utilizador é obrigado a manter os seus dados de acesso secretos e a protegê-los do acesso de terceiros.',
  [k.TERMS_AND_CONDITIONS_04]:
    'A App Casamento estará disponível durante seis meses após o casamento. Depois disso, desactivaremos a aplicação de casamento para podermos oferecer o endereço pretendido a outros casais. Se precisares de manter a tua aplicação de casamento online por um período mais longo, podemos fazer um acordo especial.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Uma vez criada a aplicação de casamento, esta só pode ser utilizada para um casamento. Se, por exemplo, descobrirmos que um utilizador cedeu ou vendeu uma aplicação de casamento a outros casais, reservamo-nos o direito de impedir essa utilização e de cobrar a taxa para outros casamentos.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Proteção de dados',
  [k.TERMS_AND_CONDITIONS_07]: 'Ao utilizar o software, o utilizador aceita a',
  [k.TERMS_AND_CONDITIONS_08]: 'proteção de dados',
  [k.TERMS_AND_CONDITIONS_09]: 'regulamentos',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Funções e serviços da EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'As aplicações individuais de casamento que podem ser criadas através da EureHochzeitOnline.com oferecem as seguintes características',
  [k.TERMS_AND_CONDITIONS_12]: 'Personalização:',
  [k.TERMS_AND_CONDITIONS_13]:
    'O anfitrião pode configurar e desenhar certos aspectos da aplicação de casamento, por exemplo, para combinar com o design gráfico dos convites de casamento, pode escolher fundos, imagens, cores e tipos de letra.',
  [k.TERMS_AND_CONDITIONS_14]: 'Galeria de fotografias:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Os convidados podem tirar e carregar fotografias antes ou durante o casamento. Estas fotografias podem ser apresentadas numa galeria de imagens em direto actualizada automaticamente.',
  [k.TERMS_AND_CONDITIONS_16]: 'Pedidos de música:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Os convidados podem utilizar uma função de pesquisa para pedir músicas para o casamento e classificar os pedidos. O anfitrião pode utilizar esta função para selecionar as músicas.',
  [k.TERMS_AND_CONDITIONS_18]: 'Informações sobre o casamento:',
  [k.TERMS_AND_CONDITIONS_19]:
    'O anfitrião pode publicar o local com mapas interactivos, o horário e outras informações para os seus convidados na sua aplicação de casamento.',
  [k.TERMS_AND_CONDITIONS_20]:
    'A EureHochzeitOnline.com reserva-se o direito de alterar o âmbito e o design das funcionalidades do software em qualquer altura.',
  [k.TERMS_AND_CONDITIONS_21]:
    'Para utilizar as funcionalidades, é necessário um smartphone, tablet ou PC moderno com um navegador de Internet moderno. Em particular, as funções em direto, como o carregamento de fotografias, os questionários de casamento ou os pedidos de música, requerem uma ligação estável e rápida à Internet. A EureHochzeitOnline.com não assume qualquer responsabilidade pela exatidão, integridade, fiabilidade, atualidade e usabilidade do conteúdo fornecido.',
  [k.TERMS_AND_CONDITIONS_22]:
    'Durante os trabalhos de manutenção, que a EureHochzeitOnline.com anunciará aos hospedeiros, a plataforma pode estar temporariamente indisponível. Fazemos todos os esforços para manter o nosso serviço disponível e temos orgulho em dizer que nunca desapontámos os nossos utilizadores. No entanto, não podemos assumir quaisquer outras obrigações, em particular o utilizador não tem direito a uma disponibilidade constante do serviço.',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Obrigações do utilizador',
  [k.TERMS_AND_CONDITIONS_24]:
    'O utilizador pode guardar e publicar os seus próprios conteúdos e ficheiros na plataforma EureHochzeitOnline.com como convidado ou como anfitrião. O utilizador é responsável por estes conteúdos. O utilizador compromete-se a não publicar artigos, ficheiros, informações de casamento ou outros conteúdos que violem a decência comum ou a lei aplicável. Em particular, o utilizador compromete-se a não publicar artigos, ficheiros, informações sobre casamentos ou outros conteúdos',
  [k.TERMS_AND_CONDITIONS_25]: 'cuja publicação constitua uma infração penal ou administrativa,',
  [k.TERMS_AND_CONDITIONS_26]: 'viole a legislação sobre direitos de autor, marcas registadas ou concorrência',
  [k.TERMS_AND_CONDITIONS_27]: 'que viole a Lei dos Serviços Jurídicos,',
  [k.TERMS_AND_CONDITIONS_28]: 'ou contenha conteúdo ofensivo, racista, discriminatório ou pornográfico',
  [k.TERMS_AND_CONDITIONS_29]: 'que contenham publicidade.',
  [k.TERMS_AND_CONDITIONS_30]:
    'A EureHochzeitOnline.com tem o direito de alterar ou apagar qualquer conteúdo que viole ou seja suspeito de violar essas obrigações. A EureHochzeitOnline.com também tem o direito de bloquear o acesso do utilizador e a aplicação de casamento criada, se o utilizador violar as condições de utilização. O utilizador deve indemnizar o fornecedor por qualquer dano causado pela violação do dever. O fornecedor tem o direito de exigir uma indemnização ao utilizador por reclamações de terceiros devido à violação de um direito por parte do utilizador. O utilizador é obrigado a apoiar o prestador na defesa de tais reclamações. O utilizador também é obrigado a suportar os custos de uma defesa legal adequada do fornecedor.',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Propriedade intelectual',
  [k.TERMS_AND_CONDITIONS_32]: 'A tua propriedade intelectual',
  [k.TERMS_AND_CONDITIONS_33]:
    'Os direitos de propriedade intelectual sobre os conteúdos, textos, imagens e outros materiais que colocares no site são teus. A EureHochzeitOnline.com não reivindica nenhum direito de propriedade intelectual sobre esse conteúdo. No entanto, autorizas a EureHochzeitOnline.com a aceder, copiar e distribuir este conteúdo com o objetivo de o fornecer aos convidados do casamento. Também nos autoriza a realizar quaisquer outros actos necessários para fornecer os nossos serviços, incluindo, mas não limitado a fazer cópias de segurança e fornecer acesso ao pessoal de apoio.',
  [k.TERMS_AND_CONDITIONS_34]: 'A nossa propriedade intelectual',
  [k.TERMS_AND_CONDITIONS_35]:
    'O provedor não concede nenhuma licença ou outro direito de uso dos direitos de propriedade intelectual do site EureHochzeitOnline.com ou da plataforma de software fora de um aplicativo de casamento criado regularmente e fornecido de acordo com os Termos de Uso. A duplicação, distribuição, reprodução, transmissão ou qualquer outro uso dos direitos de propriedade intelectual, incluindo a sua disponibilização a terceiros, não é permitida sem o consentimento prévio por escrito do Provedor.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Direito de retratação',
  [k.TERMS_AND_CONDITIONS_37]:
    'Tens o direito de rescindir o presente contrato no prazo de catorze dias sem justificação. O prazo de retratação é de catorze dias a contar da celebração do contrato. Podes exercer o teu direito de rescisão enviando um e-mail para',
  [k.TERMS_AND_CONDITIONS_38]:
    'com uma declaração clara da tua decisão de rescindir o contrato. Também podes utilizar o seguinte modelo de formulário na tua mensagem eletrónica:',
  [k.TERMS_AND_CONDITIONS_39]: 'Modelo de formulário de rescisão',
  [k.TERMS_AND_CONDITIONS_40]:
    '- Para fidira Software UG (haftungsbeschränkt), envia um e-mail para: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    '- Eu/nós ( * ) revogo(amos) o contrato celebrado por mim/nós ( * ) para a ativação da nossa aplicação de casamento ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- Encomendado em ( * ) / Recebido em ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Nome do(s) consumidor(es)',
  [k.TERMS_AND_CONDITIONS_44]: '- Endereço do(s) consumidor(es)',
  [k.TERMS_AND_CONDITIONS_45]: '- Assinatura do(s) consumidor(es) (apenas para notificação em papel)',
  [k.TERMS_AND_CONDITIONS_46]: '- Data',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Riscar o que não interessaPor ordem',
  [k.TERMS_AND_CONDITIONS_48]:
    'Para respeitar o prazo de retratação, basta enviar a notificação do exercício do direito de retratação antes do fim do prazo de retratação.Consequências da revogação:',
  [k.TERMS_AND_CONDITIONS_49]: 'Consequências da revogação:',
  [k.TERMS_AND_CONDITIONS_50]:
    'Se cancelares o teu contrato, eliminaremos a tua aplicação de casamento e, se já tivermos recebido pagamentos da tua parte, reembolsar-te-emos esses pagamentos utilizando o mesmo método de pagamento. Este reembolso não te será cobrado.',
  [k.TERMS_AND_CONDITIONS_51]:
    'No caso de um contrato para a entrega de conteúdo digital que não esteja num suporte de dados físico, como uma aplicação de casamento, o direito de revogação nos termos do § 356 (5) do BGB também expira se o Fornecedor tiver iniciado a execução do contrato depois de o Utilizador ter 1. consentido expressamente que o Fornecedor inicie a execução do contrato antes do termo do período de revogação e 2. confirmado que tem conhecimento de que perderá o seu direito de revogação ao concordar com o início da execução do contrato.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Garantia e exclusão de responsabilidade',
  [k.TERMS_AND_CONDITIONS_53]:
    "O prestador fornece os seus serviços 'tal como estão', 'com todas as falhas' e 'conforme disponíveis' e não oferece garantias expressas ou implícitas de comercialização, adequação a um determinado fim, desempenho profissional, não infração ou quaisquer outras garantias na medida do permitido por lei. Também não há garantia de que os serviços do Provedor (ou qualquer parte, caraterística ou conteúdo do mesmo) são (ou serão) completos, de uma qualidade particular, confiáveis, seguros, precisos, compatíveis com suas atividades pretendidas, dispositivos utilizados, sistemas operacionais, navegadores ou programas (de convidados ou anfitriões), ou que eles cumprem com quaisquer requisitos legais aplicáveis a ti, ou que os serviços estão livres de vírus, bugs ou outros componentes prejudiciais ou limitações de programas. Além disso, a EureHochzeitOnline.com não assume nenhuma responsabilidade por qualquer empresa, produto ou serviço (incluindo serviços de terceiros) mencionados ou oferecidos na plataforma EureHochzeitOnline.com. Não assumimos nenhuma responsabilidade pela perda de dados e informações, e o provedor não garante a reconstrução de tais dados e informações. O utilizador faz cópias de segurança dos seus dados e informações por sua conta e risco. A EureHochzeitOnline.com também não assume qualquer responsabilidade pelo conteúdo publicado pelos utilizadores ou pela identidade correcta dos utilizadores.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Cláusula de separação',
  [k.TERMS_AND_CONDITIONS_55]:
    'Se qualquer disposição do presente Acordo for considerada inválida ou inexequível por qualquer motivo ou em qualquer medida, tal invalidade ou inexequibilidade não afectará as restantes disposições do Acordo, e a aplicação de tal disposição será aplicada em toda a extensão permitida por lei. Se a EureHochzeitOnline.com renunciar ao seu direito de fazer cumprir uma violação de um ou mais dos termos e condições, isso não significa que a EureHochzeitOnline.com também renuncia ao seu direito de fazer cumprir os outros termos e condições.',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Disposições finais',
  [k.TERMS_AND_CONDITIONS_57]:
    'Salvo disposição legal em contrário, os acordos são regidos pelas leis da República Federal da Alemanha. O foro exclusivo para todos os litígios/reclamações resultantes ou relacionados com as presentes Condições de utilização é Munique. Reservamo-nos o direito de alterar estes Termos de Utilização no futuro e de publicar a nova versão no mesmo local. Após uma alteração das condições de utilização, a nova versão tem de ser confirmada pelo utilizador.',
  [k.TERMS_AND_CONDITIONS_58]: 'Estado: 01.04.2018',
  [k.IMPRINT_00]: 'Impressão/Nota legal',
  [k.IMPRINT_01]: 'fidira Software UG (responsabilidade limitada)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de é uma marca registada da fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Mail:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Sede social da empresa: Munique, Registo Comercial de Munique HRB',
  [k.IMPRINT_09]: 'Representantes autorizados:',
  [k.PRIVACY_00]: 'Política de privacidade',
  [k.PRIVACY_01]: 'Introdução e informações gerais',
  [k.PRIVACY_02]:
    'Proteger a tua privacidade e a privacidade dos convidados do teu casamento é uma prioridade máxima para a EureHochzeitOnline.com.',
  [k.PRIVACY_03]:
    'De acordo com os requisitos da Lei Federal Alemã de Proteção de Dados (BDSG) e da Lei Alemã de Telemedia (TMG), garantimos sempre que os teus dados são utilizados apenas para a criação e utilização da tua aplicação pessoal de casamento e para otimizar a experiência do utilizador da tua aplicação de casamento. Utilizamos a tecnologia TLS/SSL (Transport Layer Security / Secure Sockets Layer) tanto para a criação como para a utilização de aplicações de casamento, para que os dados sejam sempre encriptados durante a transmissão. Todos os nossos servidores de aplicações estão localizados na União Europeia e cumprem os regulamentos de privacidade de dados da UE. Durante o processo de registo, ser-te-á pedido o teu consentimento para o processamento descrito na presente Política de Privacidade, clicando na caixa de verificação. Ao dar o teu consentimento, confirmas que podemos recolher, processar e utilizar os teus dados pessoais na medida descrita abaixo. Podes retirar o teu consentimento em qualquer altura. Para o fazeres, basta enviares uma mensagem de correio eletrónico para o nosso serviço de apoio ao cliente em',
  [k.PRIVACY_04]:
    'Durante o registo, ser-lhe-á pedido o seu consentimento para as operações de tratamento descritas na política de privacidade, que dá clicando na caixa de verificação. Ao dar o seu consentimento, você confirma que podemos coletar, processar e usar seus dados pessoais na medida descrita abaixo. Você também pode retirar seu consentimento a qualquer momento. Para tal, basta enviar um e-mail para o nosso serviço de apoio ao cliente em',
  [k.PRIVACY_05]:
    '. A pedido, o nosso serviço de apoio ao cliente também te informará sobre todos os dados armazenados sobre ti (de acordo com o § 13 (7) da Lei Alemã de Telemédia (Telemediengesetz)) e apagará estes dados a teu pedido.',
  [k.PRIVACY_06]: '§ 1 Entidade responsável e área de aplicação',
  [k.PRIVACY_07]:
    "A entidade responsável é a fidira Software UG (haftungsbeschränkt), doravante também designada por 'fornecedor', 'nós' ou 'EureHochzeitOnline.com'. Encontra os detalhes sobre o endereço e os representantes autorizados na impressão.",
  [k.PRIVACY_08]:
    'Esta política de privacidade abrange a recolha e utilização de dados pessoais quando visitas e utilizas qualquer uma das plataformas do fornecedor através de navegadores de internet. Através destas ofertas, o Fornecedor possibilita a criação de uma aplicação de casamento individual com a qual os convidados podem informar-se sobre o casamento e interagir uns com os outros durante o casamento (por exemplo, partilhar fotografias ou responder a perguntas). O Fornecedor também utilizará os dados pessoais para fins estatísticos e de análise de mercado. Ao fazê-lo, compilamos estatísticas anónimas, por exemplo, sobre casamentos, locais, tamanhos de casamento ou utilização da aplicação, a fim de otimizar ainda mais a nossa oferta.',
  [k.PRIVACY_09]: '§ 2 Ligações a outros sítios Web',
  [k.PRIVACY_10]:
    'O nosso sítio Web pode conter ligações a outros sítios Web. Não temos qualquer controlo sobre o conteúdo para o qual és direcionado depois de clicares nesses links, nem sobre o processamento dos teus dados que podem ser transmitidos a terceiros (por exemplo, o teu endereço IP). Por conseguinte, não podemos aceitar qualquer responsabilidade pelo conteúdo de terceiros e pelo processamento de tais dados por terceiros.',
  [k.PRIVACY_11]: '§ 3 Utilização de dados sem registo',
  [k.PRIVACY_12]:
    'Quando visitas o site ou a aplicação da EureHochzeitOnline.com, guardamos automaticamente algumas informações básicas sobre a tua visita: em particular, o endereço IP, as páginas visitadas e a duração da visita. Utilizamos estas informações para compilar estatísticas anónimas sobre o comportamento do utilizador e as funcionalidades mais utilizadas, a fim de melhorar continuamente as nossas ofertas. O teu endereço IP só é armazenado em ficheiros de registo técnico durante um período de tempo limitado, a fim de proteger os nossos sistemas e as aplicações de casamento que criamos contra abusos e fraudes.',
  [k.PRIVACY_13]: '§ 4 Utilização dos dados aquando do registo',
  [k.PRIVACY_14]:
    'Para ter acesso a uma aplicação de casamento como convidado ou para criar uma aplicação de casamento como anfitrião, o utilizador tem de se registar. Durante o processo de registo, são armazenados o endereço de e-mail do utilizador, um nome de apresentação (livremente selecionável, também pode ser um pseudónimo inventado), um código de casamento (se aplicável) e uma palavra-passe (livremente definível). Estas informações são utilizadas para proteger as listas de casamento dos nossos anfitriões contra o acesso não autorizado. Ao criar uma aplicação de casamento, os anfitriões podem introduzir informações sobre o seu casamento (por exemplo, local, hora, horário) que só serão visíveis para os convidados desse casamento. Para tal, o anfitrião gera códigos de casamento que os convidados têm de introduzir para aceder à aplicação de casamento.',
  [k.PRIVACY_15]:
    'Também usamos o teu endereço de e-mail para fins de comunicação: por exemplo, notificações por e-mail podem ser enviadas ao anfitrião sobre novas inscrições, outras notícias sobre o casamento, e e-mails de serviços da EureHochzeitOnline.com podem ser enviados para o endereço de e-mail (por exemplo, pesquisas de satisfação). Além disso, mostramos os endereços de e-mail dos convidados do casamento ao anfitrião, para que ele possa utilizá-los na organização do casamento.',
  [k.PRIVACY_16]: 'O teu endereço de e-mail nunca será partilhado com terceiros ou outras empresas.',
  [k.PRIVACY_17]: '§ 5 Cookies',
  [k.PRIVACY_18]:
    "Para te proporcionar a melhor experiência de utilização, utilizamos 'cookies' e o chamado 'armazenamento local' do teu browser. Isto permite-nos armazenar pequenas quantidades de dados sobre a sua App de Casamento no seu browser, para que, por exemplo, as suas alterações não se percam quando fecha o browser, quando muda para o modo de ecrã inteiro do Configurador de App, ou para que não tenha de iniciar sessão todas as vezes. Se não quiseres utilizar cookies ou quiseres apagar os cookies existentes, podes desactivá-los ou removê-los através do teu browser. Consulta as instruções do teu browser para saberes como o fazer ou contacta-nos para obteres assistência.",
  [k.PRIVACY_19]:
    "Também utilizamos cookies para os serviços de terceiros listados abaixo em '§ 7 Aplicações e sítios Web' para te proporcionar uma melhor experiência de utilização.",
  [k.PRIVACY_20]: '§ 6 Aplicações e sítios Web',
  [k.PRIVACY_21]: 'Google Maps',
  [k.PRIVACY_22]:
    'Utilizamos o Google Maps da Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, EUA para apresentar mapas interactivos. O teu endereço IP, a tua localização e as informações sobre a tua utilização dos nossos serviços serão transmitidos e armazenados pela Google em servidores nos Estados Unidos. Os termos de utilização do Google Maps podem ser consultados em ',
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "Também utilizamos o HotJar, uma ferramenta de análise da Web da Hotjar Ltd, para recolher e armazenar dados para fins de marketing e otimização. Os dados podem ser utilizados para criar perfis de utilizador pseudónimos. São registadas visitas individuais seleccionadas aleatoriamente (sem informações pessoais adicionais, os chamados 'atributos do utilizador'), o que cria um registo dos movimentos e cliques do rato com a intenção de reproduzir aleatoriamente visitas individuais ao sítio Web e obter potenciais melhorias para o sítio Web. Os dados recolhidos não serão utilizados para identificar pessoalmente o visitante deste sítio Web, nem serão fundidos com dados pessoais sobre o portador do pseudónimo, sem o consentimento separado da pessoa em causa. Se não quiseres ser rastreado, podes desativar globalmente o rastreio em todos os sítios Web que utilizam o HotJar para o browser que estás a utilizar atualmente, clicando na seguinte ligação",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'Utilizamos o Mailchimp do The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) para enviar a nossa newsletter. Isto permite-nos contactar diretamente os subscritores. Além disso, analisamos o teu comportamento de utilização para otimizar as nossas ofertas.',
  [k.PRIVACY_29]: 'Para este efeito, partilhamos as seguintes informações pessoais com a Mailchimp',
  [k.PRIVACY_30]:
    'Endereço de e-mail [Nome próprio] [Data de casamento] [Informações sobre o casamento] [Detalhes de início de sessão].',
  [k.PRIVACY_31]:
    'As nossas mensagens de correio eletrónico incluirão uma ligação para actualizares as tuas informações pessoais. O Mailchimp é o destinatário dos teus dados pessoais e actua como um processador em nosso nome no que diz respeito à distribuição da nossa newsletter. O tratamento dos dados descritos nesta secção não é exigido por lei ou por contrato. Sem o teu consentimento e o envio dos teus dados pessoais, não podemos enviar-te uma newsletter. Além disso, a Mailchimp recolhe as seguintes informações pessoais utilizando cookies e outros métodos de rastreio Informações sobre o teu dispositivo (endereço IP, informações do dispositivo, sistema operativo, ID do browser, informações sobre a aplicação que utilizas para ler o teu e-mail e outras informações sobre o teu hardware e ligação à Internet. Além disso, são recolhidos dados de utilização, como a data e a hora em que abriste o e-mail/campanha e a atividade do browser (por exemplo, que e-mails/sites foram abertos). A Mailchimp necessita destas informações para garantir a segurança e fiabilidade dos sistemas, para fazer cumprir os Termos de Serviço e para evitar utilizações indevidas. Isso corresponde ao interesse legítimo da Mailchimp (de acordo com o Art. 6, parágrafo 1 lit. f GDPR) e serve para a execução do contrato (de acordo com o Art. 6, parágrafo 1 lit. b GDPR). A Mailchimp também analisa dados de desempenho, tais como estatísticas de entrega de e-mails e outros dados de comunicação. Estas informações são utilizadas para compilar estatísticas de utilização e desempenho dos Serviços. A Mailchimp também recolhe informações sobre ti a partir de outras fontes. Ocasionalmente e numa escala indeterminada, são recolhidas informações pessoais através das redes sociais e de outros fornecedores de dados terceiros. Não temos qualquer controlo sobre estes processos. ',
  [k.PRIVACY_32]: 'Para mais informações sobre as tuas escolhas e opções de remoção com o Mailchimp, visita',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'base legal para este processamento é o teu consentimento, de acordo com o Art.6 para.1 lit. a GDPR. Podes retirar o teu consentimento para o processamento dos teus dados pessoais a qualquer momento. A retirada do consentimento não afecta a legalidade de qualquer tratamento que já tenha sido efectuado.',
  [k.PRIVACY_35]:
    'Os teus dados serão tratados enquanto tiveres dado o teu consentimento. Caso contrário, serão eliminados após a cessação do contrato entre nós e a Mailchimp, a menos que os requisitos legais tornem necessário um armazenamento posterior.',
  [k.PRIVACY_36]:
    'A Mailchimp implementou medidas de conformidade para transferências internacionais de dados. Estas medidas aplicam-se a todas as operações globais em que a Mailchimp processa dados pessoais de indivíduos na UE. Estas políticas baseiam-se nas Cláusulas Contratuais-tipo (CCP) da UE. Podes encontrar mais informações em',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    'Também utilizamos o Spotify Music Player Widget, um leitor de música do Spotify AB, para te permitir ouvir a lista de reprodução do casamento. O fornecedor de serviços do Spotify Music Player Widget é a Spotify AB, Regeringsgatan 19, 111 53 Estocolmo, Suécia. Podes encontrar mais informações sobre o processamento de dados da Spotify AB em',
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Pixel do Facebook',
  [k.PRIVACY_42]:
    "Para melhorar a EureHochzeitOnline.com e torná-la conhecida por um grupo maior de utilizadores, usamos o Facebook Pixel do Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, EUA ('Facebook'). Para tal, o Facebook coloca um cookie no teu browser. A informação gerada por este cookie sobre a tua utilização deste website será transmitida e armazenada pelo Facebook em servidores nos Estados Unidos. O Facebook pode utilizar esta transferência de dados para determinar que uma determinada página foi acedida a partir do teu browser e se o fizeste clicando num anúncio. No entanto, não utilizamos estes dados para recolher informações do nosso site. O Facebook utiliza estas informações para nos ajudar a criar anúncios adicionais e para promover a EureHochzeitOnline.com. Avaliamos os dados obtidos através do pixel do Facebook na ferramenta de análise do Facebook 'Facebook Analytics', a fim de melhorar ainda mais a EureHochzeitOnline.com. O Facebook pode transmitir estas informações a terceiros, se tal for exigido por lei, ou se estes terceiros processarem as informações em nome do Facebook. Ao utilizar a EureHochzeitOnline.com, consente o processamento de dados sobre ti pelo Facebook da forma descrita e com o objetivo de melhorar a EureHochzeitOnline.com. Não partilhamos nenhuma das tuas informações pessoais, de contacto ou de casamento com o Facebook. Podes saber mais sobre as políticas de segurança e privacidade do Facebook Pixel em",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: 'Também podes optar por não recolher informações pelo Facebook Pixel clicando aqui:',
  [k.PRIVACY_45]: 'Desativar o Pixel',
  [k.PRIVACY_46]: '§ 7 Direito à informação e outros direitos de acordo com a BDSG',
  [k.PRIVACY_47]:
    'Teremos todo o gosto em fornecer-te, a qualquer momento, informações sobre os teus dados pessoais armazenados por nós. A teu pedido, também corrigiremos, bloquearemos ou apagaremos os teus dados. Podes contactar-nos a qualquer momento com estes pedidos ou com declarações de revogação em',
  [k.PRIVACY_48]: 'e nós faremos as alterações o mais rapidamente possível. ',
  [k.PRIVACY_49]: '§ 8 Alterações à declaração de proteção de dados',
  [k.PRIVACY_50]:
    'A Internet e as possibilidades técnicas estão em constante desenvolvimento. É evidente que utilizaremos estas novas possibilidades para tornar os casamentos dos nossos utilizadores ainda mais inesquecíveis, o que nos poderá levar a atualizar e a alterar a nossa política de privacidade, que publicaremos aqui.',
  [k.PRIVACY_51]: 'Última atualização: 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Se precisar de mais idiomas, escreva-nos um e-mail.',
  [k.DECIDE_LANGUAGES]:
    'Decida quais idiomas seu site de casamento deve suportar. Você pode inserir seus textos para todos os idiomas selecionados.',
  [k.EDIT_LANGUAGES]: `Editar idiomas`,
  [k.ACTIVE_VOUCHER]: `Voucher ativo:`,
  [k.ENTER_VOUCHER_CODE]: `Digite o código do cupom`,
  [k.VOUCHER_CODE]: `Código de desconto`,
  [k.INVALID_CODE]: `Infelizmente não é um código válido!`,
  [k.WANT_CUSTOM_LOGO]: `Querem usar um logótipo próprio para o casamento?`,
  [k.INDIVIDUAL_LOGO]: `Logótipo personalizado`,
  [k.STANDARD_LOGO]: `Logótipo padrão`,
  [k.PRIVATE_PURCHASE]: `A compra é privada`,
  [k.COMMERCIAL_PURCHASE]: `A compra é comercial`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
