const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: '您好、',
  [k.ENTER_NEW_PASSWORD]: '请在此输入您的新密码。您将自动登录并重新定向。',
  [k.MINIMUM_6_CHARACTERS]: '请至少输入 6 个字符。',
  [k.SAVE]: '保存',
  [k.UNABLE_TO_RESET_PASSWORD]: '我们无法重置您的密码。',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: '密码更改成功',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]: '我们已更改您的密码！您现在可以使用新密码登录。我们正在转发给您。',
  [k.FUNCTIONS]: '功能介绍',
  [k.DESIGNS]: '主题',
  [k.PRICES]: '定价',
  [k.FAQ]: '常见问题',
  [k.BLOG]: '博客',
  [k.CREATE_NOW_FREE]: '现在免费创建',
  [k.PERFECT_PAGE_FOR_YOU]: '为您量身定制网站！',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]: '使用我们的设计配置器，只需点击几下即可实现完美设计。',
  [k.DESIGN]: '设计',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: '如何描述您梦想中的婚礼？',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: '点击其中一张图片，启动设计配置器',
  [k.CREATE_WEDDING_WEBSITE_NOW]: '现在免费创建',
  [k.FREQUENTLY_ASKED_QUESTIONS]: '常见问题',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: '您有任何问题或反馈吗？',
  [k.WE_LOOK_FORWARD_TO_IT]: '我们期待您的反馈！',
  [k.CONTENT_AND_DESIGN]: '内容与设计',
  [k.PHOTO_GALLERY]: '照片集',
  [k.RSVP_CARD]: '回复卡',
  [k.PLAYLIST]: '播放列表',
  [k.MULTILINGUAL_OWN_DOMAIN]: '多语言和自有域名',
  [k.DURATION_PASSWORD_PROTECTION]: '运行时间和密码保护',
  [k.COST_SUPPORT]: '成本与支持',
  [k.WHAT_CONTENT_TYPICALLY]: '婚礼网站一般包含哪些内容？',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]: '我能否在所有细节（如婚礼日期）确定之前创建婚礼网页？',
  [k.CAN_CUSTOMIZE_DESIGN]: '我可以定制婚礼网站的设计吗？',
  [k.CHANGES_VISIBLE_AFTER_SAVE]: '是否可以对主页进行修改，而不会让所有人立即看到？',
  [k.PHOTO_UPLOAD_LIMIT]: '我们可以上传到相册的照片数量有限制吗？',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: '照片会以原始尺寸保存吗？',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: '客人能否下载上传到网站上的照片？',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]: '我们能否在聚会期间使用照片库进行现场幻灯片放映？',
  [k.HOW_DOES_R_S_V_P_WORK]: 'RSVP 功能如何使用？',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]: '来宾能否在播放列表中添加自己的歌曲？',
  [k.CAN_DJ_ACCESS_PLAYLIST]: '我们的 DJ 能否访问播放列表？',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: '我们的主页可以使用不同语言吗？',
  [k.OWN_DOMAIN_POSSIBILITY]: '我们可以为婚礼网站申请自己的域名吗？',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: '我们的婚礼网站可以使用多长时间？',
  [k.PROTECT_SITE_WITH_PASSWORD]: '我们可以对网站进行密码保护吗？',
  [k.IS_EUREHOCHZEITONLINE_FREE]: 'EureHochzeitOnline.com 是否免费使用？',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]: '如何在EureHochzeitOnline.com上创建婚礼网页？',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    '您的婚礼网页内容与您的婚礼本身一样个性化。除了日期和地点等基本信息外，您还可以添加着装规范、路线指引、特殊要求、司仪联系信息或计划时间表。对于远道而来需要住宿的宾客，您可以列出酒店建议。与宾客分享你们的爱情故事或写下关于伴侣的个人感言也是一个不错的主意。创意无极限。',
  [k.YES_FEASIBLE_ADVANTAGES]:
    '是的，这很容易做到，甚至有很大的优势。您可以随时添加信息和提供更新，甚至在婚礼请柬寄出之后。',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    '是的，EureHochzeitOnline.com 为您的婚礼网站提供多种设计选项。您可以自定义颜色、字体和其他元素。如果您有任何问题或意见，请联系我们。',
  [k.INDEED_CHANGES_VISIBLE]:
    '事实上，您网站上的所有改动都会立即实时显示给所有访问者。我们目前不提供提前安排或隐藏更改的功能。',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    '在免费版本中，您可以上传到图库中的照片数量有限。这是因为我们需要支付存储和流量费用。但是，如果您购买了高级或域套餐，您就可以在相册中上传无限数量的照片。查看我们的博客，了解如何获得完美婚纱照的技巧。',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    '上传照片时，我们会为每张照片创建不同的尺寸。根据访问者的屏幕尺寸，我们会自动选择最佳尺寸，以确保快速加载时间和最佳图像质量。不过，我们也会保存照片的原始文件，以便您也能收到。',
  [k.YES_GUESTS_CAN_DOWNLOAD]: '是的，客人可以下载上传的照片。',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    '当然可以！事实上，这是 EureHochzeitOnline 最受欢迎的功能之一。您只需要一个稳定的互联网连接，当然还需要一个屏幕或投影仪。',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'RSVP 功能可让您轻松地与宾客共享婚礼网站的登录信息。当他们访问您的婚礼网站时，您会要求他们留下反馈意见（例如，他们将与多少人一起参加，是儿童还是成人，以及任何特殊的食物要求、过敏症或其他要求）。在来宾区，您可以随时跟踪接受和取消的人数。有关分步说明，请参阅我们的博客。',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    '当然可以！您的宾客可以推荐自己喜欢的歌曲，并将它们添加到播放列表中。因此，您不仅可以庆祝宾客的多样性，还可以欣赏到对您和您所爱的人来说非常重要的音乐。',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    '当然可以。只需将 DJ 邀请到您的网站，您的所有愿望都将由他来实现--如果您愿意，甚至可以在派对期间现场直播。',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    '是的，您的网站可以使用多种语言。高级套餐中提供此功能，您可以将所有信息翻译成不同语言。请联系我们，以便我们为您的婚礼进行配置。',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    '是的，您可以使用我们的高级套餐为您的婚礼网站轻松预订自己的域名（如 www.juliaundromeo.de）。请联系我们，以便我们为您预订域名并设置重定向。',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    '您的婚礼网站将在创建后两年内可用。两年后，该网站将被删除，以便为新的婚礼网站腾出空间。我们会提前提醒您。可根据要求续订。',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]: '所有婚礼网页都有密码保护，创建后会向您显示密码。',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    '是的，您可以在 EureHochzeitOnline.com 上免费创建和设计婚礼网站。但是，某些高级功能并非免费，尤其是那些需要额外服务器资源的功能。没有任何隐藏费用。',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    '如果您在创建婚礼网站时需要帮助，请随时发送邮件至 beeni@eurehochzeitonline.com。我们将尽快回复您，为您提供帮助。',
  [k.KEY_FEATURES_OVERVIEW]: '主要功能一览！',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]: '这是您的婚礼网站，包含创建梦想婚礼所需的一切。',
  [k.NEW_NOW]: '新功能：',
  [k.MULTILINGUAL_WEDDINGS]: '多语言婚礼！',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: '现在提供：多语言网站！',
  [k.SCHEDULE]: '婚礼时间表',
  [k.LOCATIONS]: '婚礼地点',
  [k.RSVP]: '回复卡',
  [k.GUESTMANAGEMENT]: '宾客管理',
  [k.HOTEL_RECOMMENDATION]: '酒店推荐',
  [k.PRESENT_TABLE]: '礼品台',
  [k.OWN_DOMAIN]: '自定义域名',
  [k.DIVERSE_LAYOUTS]: '无数种布局和设计供您选择和定制。',
  [k.YOUR_WEDDING_DAY]: '为您的宾客整齐地安排婚礼当天的活动。',
  [k.CLEAR_DIRECTIONS]: '所有婚礼地点的清晰指引。',
  [k.NO_GUESSWORK]: '不再繁琐地询问谁会来参加婚礼。',
  [k.GUEST_ADDRESSES_ALLERGIES]: '一键收集地址、过敏症等信息！',
  [k.HOTEL_SELECTION_FOR_GUESTS]: '为您的宾客选择酒店，让他们的旅行更轻松！',
  [k.EASY_ONLINE_GIFT_REGISTRY]: '让您的婚礼登记更加简单。',
  [k.WEDDING_PICTURES_FOR_GUESTS]: '您的婚礼照片和宾客照片一网打尽。',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: '您自己的 Spotify 播放列表--由您和宾客共同创建。',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: '带有您自己的域名和密码保护的婚礼网站。',
  [k.YOUR_WEDDING_WEBSITE]: '您的婚礼网站',
  [k.UNIQUE_LIKE_YOU]: '- 与您一样独一无二！',
  [k.EASY_SETUP_CUSTOMIZED]: '易于设置和定制！',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]: '无论是在手机上还是在电脑上，活动的所有细节一目了然',
  [k.GUEST_FEEDBACK_AND_WISHES]: '宾客确认和要求一目了然',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]: '从各个角度展示您的派对--为您和宾客提供实时图片库',
  [k.MULTILINGUAL_SUPPORT]: '...当然也适用于多语言婚礼',
  [k.FREE_CREATE]: '现在免费创建',
  [k.DEMO_WEBSITE]: '演示网站',
  [k.OUR_PRICING]: '我们的价格',
  [k.UNLOCK_ALL_FEATURES]: '只需一次性付费，即可解锁您想要的所有功能',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: '您有任何问题或要求吗？',
  [k.WE_ARE_HERE_TO_HELP]: '我们期待您的来信！',
  [k.USER_TESTIMONIALS]: '我们用户的声音',
  [k.THOUSANDS_OF_PEOPLE]: '成千上万的人已经通过 EureHochzeitOnline 举办了婚礼！',
  [k.OUR_GIFT_TIP]: '我们的礼物',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: '用个性化婚礼网站款待新郎新娘！',
  [k.BRIDE]: '新娘',
  [k.F_WITNESS]: '伴娘',
  [k.GROOM]: '新郎',
  [k.TESTIMONIAL_1]:
    '创建我们的网站轻而易举。即使是复杂的信息也能轻松添加，这让我们非常惊讶。我们真的能够创建自己的网站。我们的客人对我们印象深刻！',
  [k.TESTIMONIAL_2]:
    '我们所有的客人都能用自己的语言使用网站，这真是太棒了。他们喜欢播放列表功能，可以推荐自己喜欢的歌曲。通过这种方式，派对音乐也体现了来宾的多样性。',
  [k.TESTIMONIAL_3]:
    '我们的网站在整个婚礼策划过程中提供了宝贵的帮助。我们可以随时更新重要信息，甚至可以在漂亮的照片库中展示照片。我们的宾客喜欢现场幻灯片，我们更喜欢它。',
  [k.TESTIMONIAL_4]:
    '一年多过去了，我们仍然喜欢听婚礼上的 Spotify 播放列表。我们的婚礼网站帮助我们举办了一场盛大的婚礼，留下了美好的回忆。',
  [k.THANK_YOU]: '非常感谢!',
  [k.ACTIVATED_MAIL]: '您已激活电子邮件地址！',
  [k.CLICK_HERE_TO_GO_TO_WED]: '单击此处进入婚礼网站',
  [k.AND]: '和',
  [k.BE_REDIRECTED]: '进行重定向。',
  [k.SET_A_PASSWORD]: '请设置密码，以便随时登录并编辑您的婚礼！',
  [k.SET_A_PASSWORD_SHORT]: '请选择密码',
  [k.ERROR_OCCURED]: '出现错误。请重试。如果错误重复发生，您可以向我们发送电子邮件：',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: '您已经有一个密码',
  [k.PASSWORD_ALREADY_EXISTING]: '我们注意到您已经创建了密码。您可以',
  [k.GO_TO_WEDDING]: '到您的婚礼主页注册',
  [k.I_AGREE]: '我接受',
  [k.REGISTER_NOW]: '现在注册！',
  [k.YOUR_FREE_WEDDING_WEBSITE]: '您的免费婚礼网站！',
  [k.ALREADY_CREATED_WEDDINGS]:
    '您已经创建了以下婚礼主页。您可能更愿意编辑现有的婚礼主页，而不是创建一个新的。只需点击下面的链接。',
  [k.WEDDING]: '婚礼网站',
  [k.ENTER_PASSWORD]: '请输入密码',
  [k.USER_ALREADY_EXISTING]: '我们已经有一个使用此电子邮件地址的注册用户。请输入您的密码。',
  [k.BACK]: '返回',
  [k.LOGIN]: '登录',
  [k.FORGOT_PASSWORD]: '忘记密码？请点击此处。',
  [k.MAIL_SENT]: '我们已经向您发送了一封电子邮件。请点击电子邮件中的链接重置密码。',
  [k.OK]: '确定',
  [k.PERFECT]: '太完美了',
  [k.NAME_OF]: '名字是什么？',
  [k.SPOUSE_M]: '您的选择',
  [k.SPOUSE_F]: '您的选择',
  [k.NEXT]: '下一个',
  [k.WHATS_YOUR_NAME]: '真棒您叫什么名字？',
  [k.PREPARING_1]: '我们正在为您准备婚礼应用程序...',
  [k.PREPARING_2]: '保存您选择的设计...',
  [k.PREPARING_3]: '最后调整一下...',
  [k.FINISHED_ENTER_MAIL]: '完成！请输入您的电子邮件地址，以便我们保存您的婚礼应用程序并向您发送登录详情。',
  [k.ACCEPT_TERMS]: '请接受服务条款。',
  [k.ENTER_VALID_MAIL]: '请输入有效的电子邮件地址。',
  [k.QUESTION_BRIDE_OR_GROOM]: '您是新娘还是新郎？',
  [k.QUESTION_WEDDING_DATE]: '您知道您的婚礼日期吗？',
  [k.YES_WE_KNOW]: '是的，我们知道！',
  [k.NO_WE_DONT_KNOW]: '不，还不知道！',
  [k.MAKE_SELECTION]: '请选择。',
  [k.QUESTION_WEDDING_LOCATION]: '您有婚礼地点吗？',
  [k.YES_WE_HAVE]: '有，我们有！',
  [k.ENTER_YOUR_LOCATION]: '请输入您的婚礼地点。',
  [k.LOCATION_CLASSIC]: '经典',
  [k.LOCATION_COUNTRY]: '乡村',
  [k.LOCATION_OUTDOOR]: '户外',
  [k.LOCATION_PALACE_HOTEL]: '城堡式',
  [k.QUESTION_WEDDING_STYLE]: '您喜欢哪种风格？',
  [k.HOTEL]: '酒店',
  [k.INFOS]: '信息',
  [k.LOCATION]: '酒店位置',
  [k.PHOTOS]: '照片',
  [k.NO_MUSIC_SAVED]: '尚未保存歌曲...',
  [k.SEARCH_FOR_SONGS]: '请在上方搜索您喜欢的歌曲！',
  [k.LISTEN_TO_FULL_LIST]: '聆听完整婚礼播放列表',
  [k.OPEN_IN_SPOTIFY]: '在 Spotify 中打开',
  [k.LISTEN_TO_SINGLE_SONGS]: '聆听播放列表中的单首歌曲',
  [k.NO_RESULT_FOUND]: '未找到结果',
  [k.CANCEL]: '取消',
  [k.ADD_SONG]: '添加歌曲',
  [k.NO_PHOTOS_UPLOADED_YET]: '尚未上传照片...',
  [k.ADD_SOME_PHOTOS]: '点击上方并拍照！',
  [k.DROP_PHOTOS_HERE_OR]: '在此保存照片或',
  [k.SHOOT_PHOTO]: '拍摄照片',
  [k.OVERVIEW_OF_ALL_PRESENTS]: '查看所有礼物和作品',
  [k.LOADING_BACKINGS]: '条目已加载...',
  [k.NO_PRESENTS_YET]: '您尚未在礼品登记册中添加任何礼品。',
  [k.PRESENT]: '添加礼物',
  [k.E_MAIL_ADDRESS]: '您的电子邮件地址',
  [k.DATE]: '礼物日期',
  [k.NO_BACKINGS_YET]: '尚无条目',
  [k.CLOSE]: '关闭',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(仅新郎新娘可见）',
  [k.SHOW_ALL_ENTRIES]: '显示所有作品',
  [k.EUR]: '金额 欧元',
  [k.ADD_BACKING]: '加入',
  [k.BUY]: '现在购买',
  [k.YOUR_BACKING_SAVED]: '您已登录！',
  [k.A_GUEST_ALREADY_BACKED]: '已有访客注册。',
  [k.DELETE_MY_BACKING]: '删除我的条目。',
  [k.OVERVIEW_OF_ALL_RSVPS]: '所有注册概览',
  [k.LOADING_RSVPS]: '注册正在加载中...',
  [k.NO_RSVPS_YET]: '尚未收到回复卡。',
  [k.RESPONSE]: '回复',
  [k.GUESTS_AND_MENU_WISH]: '客人和菜单要求',
  [k.QUESTIONS_AND_REMARKS]: '问题/评论',
  [k.WILL_ATTEND]: '承诺',
  [k.WILL_NOT_ATTEND]: '取消预订',
  [k.SHOW_ALL_RSVPS]: '查看所有请求',
  [k.YOU_ALREADY_SUBMITTED_ON]: '您已向',
  [k.CANNOT_CHANGE_ANY_MOR]: '已发送。无法更改详细信息。',
  [k.MENU_WISH]: '请求菜单',
  [k.RSVP_LOADING]: '回复卡正在加载...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: '请在下面列出我们可以期待的所有人的名单',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]: '请在下面列出我们可以邀请的人员名单，并告诉我们您对菜单的偏好',
  [k.GUEST]: '客人',
  [k.ADD_GUEST]: '添加另一位客人',
  [k.SUBMIT_RSVP]: '发送回复卡',
  [k.WEBSITE]: '网站',
  [k.OUR_PACKAGES]: '我们的套餐',
  [k.PACKAGE_BASIC]: '基本套餐',
  [k.PACKAGE_COMPLETE]: '完整套餐',
  [k.PACKAGE_PREMIUM]: '高级',
  [k.PACKAGE_VIP]: '贵宾',
  [k.BEST_SELLER]: '最畅销',
  [k.SELECTED]: '精选',
  [k.SELECT]: '选择',
  [k.CAN_UPGRADE_LATER]: '别担心，您只需支付差额即可升级。',
  [k.LOOK_IN_FAQ]: '还有问题？查看我们的常见问题！',
  [k.PACKAGE_FEATURES_DESIGN]: '设计、颜色、字体',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: '带密码的子域',
  [k.PACKAGE_FEATURES_MOBILE]: '桌面和手机优化',
  [k.PACKAGE_FEATURES_SCHEDULE]: '带时间表的每日日程表',
  [k.PACKAGE_FEATURES_LOCATION]: '地点规划和信息',
  [k.PACKAGE_FEATURES_INFOS]: '信息页面（常见问题、礼品等）',
  [k.PACKAGE_FEATURES_RSVP]: '客人登记和管理',
  [k.PACKAGE_FEATURES_MUSIC]: '宾客音乐要求',
  [k.PACKAGE_FEATURES_PHOTOS]: '宾客照片上传',
  [k.PACKAGE_FEATURES_SUPPORT]: '通过电子邮件提供客户服务',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: '多语言网站',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: '您自己的域名 (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: '专业人士定制设计',
  [k.MAX_FIVE]: '最多5',
  [k.CHECKING_PAYMENT_STATUS]: '查看付款状态',
  [k.PAYMENT_SUCCESSFULL]: '谢谢！付款成功，您现在可以在以下套餐中使用您的婚礼应用程序：',
  [k.PAYMENT_FAILED]: '很遗憾，付款失败。您可以重试或发送电子邮件至',
  [k.ACTIVATE_PACKAGE_NOW]: '现在解锁：',
  [k.PAY]: '付款',
  [k.EDITING_MODE]: '编辑模式',
  [k.ACTIVE]: '已激活',
  [k.NOT_ACTIVE]: '未激活',
  [k.ERROR_OCCURED_WITH_MESSAGE]: '发生错误。技术反馈：',
  [k.TRY_AGAIN_PLEASE]: '请重试。如果错误重复出现，请发送电子邮件至',
  [k.PACKAGE_UPGRADE]: '升级套餐',
  [k.PACKAGE_ALREADY_BOUGHT]: '您已经购买了婚礼套餐。',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    '当然，您只需支付差额即可将您的网站升级到更高的套餐。请给我们发一封简短的电子邮件，我们会处理好一切。',
  [k.REGISTRATION_NOT_COMPLETE]: '注册尚未完成。请点击我们发送给您的链接。',
  [k.YOU_HAVE_AN_EMAIL]: '您已收到我们的电子邮件。',
  [k.YOU_MUST_REGISTER_FIRST]: '您需要完成注册才能编辑您的婚礼网站。',
  [k.CREATOR_MUST_REGISTER]: '您只需点击我们发送给您的电子邮件中的链接即可。找不到电子邮件？没问题，点击这里：',
  [k.HERE]: '这里',
  [k.E_MAIL_RESENT]: '再次发送电子邮件。',
  [k.CONFIRMATION_RESENT_TO]: '我们已重新向您发送了确认链接：',
  [k.CAN_TAKE_SOME_MINUTES]:
    '您可能需要几分钟才能收到电子邮件。如果您仍然遇到问题，请发送电子邮件给我们，我们将为您提供帮助：',
  [k.RESENDING_MAIL]: '我们将再次发送电子邮件。',
  [k.DEMO_WEDDING_HINT]: '演示网站 - 免费创建您自己的网站并试用！',
  [k.CREATE_OWN_WEBSITE]: '创建您自己的网站！',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: '演示网站上的某些功能是禁用的。',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]: '您可以免费注册，创建自己的婚礼网站并试用所有功能！',
  [k.NO_PACKAGE_BOUGHT_HINT]: '免费基本版 - 立即升级，获得无限照片、宾客和更多功能。',
  [k.LETS_UPGRADE]: '升级吧',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: '您的照片数量已达到免费基本套餐的上限。',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]: '您可以升级到我们的高级计划，上传无限量的照片。',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]: '您可以升级到我们的高级计划之一，无限量添加歌曲。',
  [k.CONFIRM_DELETE_TITLE]: '确认删除',
  [k.CONFIRM_DELETE_TEXT]: '您确定要删除此项目吗？',
  [k.DELETE]: '删除项目',
  [k.ADD_ELEMENT]: '添加新项目',
  [k.WHICH_ELEMENT_TO_ADD]: '您想添加什么类型的项目？',
  [k.SELECT_ICON_FOR_LOCATION]: '选择位置图标',
  [k.IMAGE_POSITION]: '图像位置',
  [k.SELECT_IMAGE]: '选择图片',
  [k.UPLOAD]: '上传图片',
  [k.SELECTED_IMAGE]: '已选图像',
  [k.NO_IMAGE]: '无图像',
  [k.OTHER_IMAGE]: '其他图像',
  [k.EDIT_WEDDING_BASE_DATA]: '编辑婚礼细节',
  [k.ENTER_NAME_OF_BRIDE]: '请输入新娘姓名。',
  [k.ENTER_NAME_OF_GROOM]: '请输入新郎的姓名。',
  [k.SELECT_DATE]: '请选择日期。',
  [k.HINT_CHANGE_URL]: '注意：如果您想更改婚礼网页的地址，请给我们发送电子邮件：',
  [k.EDIT_COUNTDOWN]: '编辑婚礼倒计时',
  [k.EDIT_INFO_BOX]: '编辑信息框',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: '编辑大照片框',
  [k.EDIT_LOCATION]: '编辑地点',
  [k.EDIT_PLACE]: '编辑地点',
  [k.ADD_LOCATION]: '添加地点',
  [k.ADD_PLACE]: '添加地点',
  [k.SELECT_SEARCH_RESULT]: '请选择搜索结果。',
  [k.EDIT_PAGE_TITLE]: '编辑页面标题',
  [k.EDIT_PRESENTS]: '编辑礼品登记表',
  [k.PRESENT_INTRO_TEXT]: '创建您自己的个性化礼品登记表，为您的客人带来灵感。',
  [k.ADDITIONAL_ENTRY]: '更多条目',
  [k.EDIT_RSVP]: '编辑 RSVP',
  [k.MENU_WISHES_ACTIVATED]: '您希望宾客在提交 RSVP 时说明他们对菜单的偏好吗？',
  [k.EDIT_SCHEDULE_ITEM]: '编辑菜单项',
  [k.NAVIGATION]: '设置菜单',
  [k.COUPLE]: '新郎新娘',
  [k.MUSIC]: '音乐菜单',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: '您已注册为新郎。',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: '您已注册为新娘。',
  [k.ADDRESS_OF_YOUR_PAGE]: '您的婚礼网站地址：',
  [k.WEDDING_CODE_FOR_GUESTS]: '宾客婚礼代码：',
  [k.CURRENT_PACKAGE]: '您当前的套餐：',
  [k.UPGRADE]: '升级',
  [k.LOGOUT]: '注销',
  [k.FOR_GUESTS]: '给宾客',
  [k.INVISIBLE]: '不可见',
  [k.VISIBLE]: '可见',
  [k.WEDDING_NOT_FOUND]: '未找到婚礼页面。',
  [k.WEDDING_NOT_FOUND_TEXT]: '很抱歉，我们无法在您输入的地址找到婚礼网页。是否是您输入错误？请再试一次。',
  [k.IF_ERROR_REPEATS]: '如果错误重复出现，您可以发送电子邮件至',
  [k.ERROR_GENERAL]: '出现错误',
  [k.SORRY_WRITE_MAIL]: '我们很抱歉。请重试。如果错误重复发生，您可以给我们发送电子邮件，我们将为您提供进一步帮助。',
  [k.MOVE_UP]: '上移',
  [k.MOVE_DOWN]: '向下滚动',
  [k.OH_SORRY]: '哦，亲爱的...我弄错了...',
  [k.ERROR_REFRESH_PAGE]: '非常抱歉。请重新加载页面并再试一次。如果错误重复出现，您可以发送电子邮件至',
  [k.IF_YOU_WANT_TO_HELP]: '如果您想帮助我们更快地找到并修复错误，可以将以下文字复制到电子邮件中：',
  [k.ENTER_DISPLAY_NAME]: '太好了！请输入您的姓名--昵称也可以。例如，它将显示在您上传的照片下。',
  [k.ENTER_DISPLAY_NAME_SHORT]: '请输入姓名。',
  [k.WE_SENT_EMAIL]: '我们已经向您发送了一封电子邮件。请点击邮件中的链接重置密码。',
  [k.WELCOME_BACK]: '欢迎回到网站、',
  [k.ALMOST_THERE]: '快到了、',
  [k.PLEASE_SET_PASSWORD]: '请现在选择密码。',
  [k.PLEASE_SET_PASSWORD_SHORT]: '选择密码',
  [k.WELCOME_TO_WEDDING_OF]: '欢迎来到',
  [k.PLEASE_ENTER_MAIL]: '!请输入您的电子邮件地址。',
  [k.WELCOME_TO_EHO]: '欢迎访问 EureHochzeitOnline.com 的婚礼平台！请输入您的电子邮件地址。',
  [k.TO_WEDDING]: '对于婚礼',
  [k.ENTER_WEDDING_CODE]: '!请输入婚礼代码。',
  [k.ENTER_WEDDING_CODE_SHORT]: '请输入婚礼代码。',
  [k.RESTART]: '请重新启动',
  [k.UPLOAD_PREPARING]: '上传准备中...',
  [k.UPLOAD_STARTED]: '上传中...',
  [k.UPLOAD_FINISHING]: '上传已完成...',
  [k.UPLOAD_CANCELED]: '上传已取消...',
  [k.INVALID_FILE_TYPE]: '文件类型无效...',
  [k.MAX_FILE_NUMBER]: '已超过最大文件数...',
  [k.FILE_TOO_LARGE]: '文件太大...',
  [k.DAYS_TO_GO]: '还有几天',
  [k.DAY_TO_GO]: '还有一天',
  [k.TODAY]: '今天',
  [k.INVALID_DATE]: '日期无效',
  [k.DATE_MUST_BE_IN_FUTURE]: '日期必须在未来',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: '地点地址或名称',
  [k.ENTER_SONG_WISH]: '在此输入您最喜欢的歌曲...',
  [k.NAME_OF_GUEST]: '客人姓名',
  [k.QUESTION_REMARK_MAX_1000]: '问题/评论（最多 1000 个字符）',
  [k.LEFT]: '链接',
  [k.TOP]: '顶部',
  [k.RIGHT]: '右边',
  [k.BOTTOM]: '向下',
  [k.NAME_OF_BRIDE]: '新娘姓名',
  [k.NAME_OF_GROOM]: '新郎姓名',
  [k.TITLE]: '标题',
  [k.DESCRIPTION]: '活动描述',
  [k.NAME_OF_PLACE]: '地点名称',
  [k.STREET]: '街道',
  [k.HOUSENUMBER]: '号码',
  [k.ZIP]: '邮政编码',
  [k.CITY]: '城市/镇',
  [k.COUNTRY]: '国家/州',
  [k.HINTS_FOR_GUESTS]: '客人须知',
  [k.TITLE_FOR_THIS_PAGE]: '本页标题',
  [k.SUBTITLE_FOR_THIS_PAGE]: '本页副标题',
  [k.LABEL]: '名称',
  [k.AMAZON_URL]: '亚马逊网址（可选）',
  [k.COSTS]: '费用',
  [k.NEW_FOOD_OPTION]: '新菜单选项',
  [k.TIME]: '时间',
  [k.SCHEDULE_ITEM]: '计划项目',
  [k.YOUR_NAME_OR_NICKNAME]: '您的姓名或昵称',
  [k.YOUR_EMAIL_ADDRESS]: '您的电子邮件地址',
  [k.YOUR_WEDDING_CODE]: '您的婚礼代码',
  [k.COULD_NOT_SAVE_WEDDING]: '很抱歉，我们无法保存婚礼。',
  [k.ALREADY_HAVE_WEDDING]: '您已拥有婚礼主页',
  [k.CREATE_NEW]: '创建新主页',
  [k.COULD_NOT_LOAD_YOUR_DATA]: '我们无法检索到您的信息。',
  [k.COULD_NOT_SEND_MAIL]: '我们无法发送电子邮件。',
  [k.DELETE_IMAGE]: '删除图片',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: '您确定要删除此图片吗？',
  [k.COULD_NOT_LOGIN]: '我们无法登录。',
  [k.WRONG_PASSWORD_TITLE]: '密码错误',
  [k.WRONG_PASSWORD_TEXT]: '您输入的密码不正确。',
  [k.WRONG_CODE_TITLE]: '婚礼代码不正确',
  [k.WRONG_CODE_TEXT]: '您输入的婚礼代码不正确。',
  [k.LOADING]: '正在加载...',
  [k.GUEST_NAME_MISSING]: '请输入以下宾客的姓名：',
  [k.GUEST_FOOD_MISSING]: '请填写下列宾客的菜单请求：',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: '您已超过尝试的最大次数。您可以稍后再试。',
  [k.EXPIRED_CODE_PASSWORD_RESET]: '您已使用此链接重置密码。',
  [k.PLEASE_ENTER_NAME]: '请输入姓名',
  [k.COUNTDOWN_VISIBLE]: '倒计时可见。',
  [k.COUNTDOWN_NOT_VISIBLE]: '倒计时不可见。',
  [k.ASK_FOR_MENU_WISHES]: '是，请求菜单',
  [k.ASK_NOT_FOR_MENU_WISHES]: '否，不要求提供菜单',
  [k.SPRING]: '春季',
  [k.SUMMER]: '夏季',
  [k.AUTUMN]: '秋季',
  [k.WINTER]: '冬季',
  [k.COULD_NOT_SAVE_PASSWORD]: '我们无法保存您的密码。',
  [k.YES_WE_WILL_ATTEND]: '是，我们将参加您的婚礼',
  [k.NO_WE_WILL_NOT_ATTEND]: '不，我们不会参加您的婚礼',
  [k.CONFIRM_DIALOG_TITLE]: '您确定吗？',
  [k.ALERT_DIALOG_TITLE]: '备注',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: '婚礼蛋糕',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: '婚礼仪式',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: '招待会和香槟',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: '舞会和派对',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: '婚礼晚宴和自助餐',
  [k.DEFAULT_IMAGES_TYPE_CAR]: '婚车和游行',
  [k.DEFAULT_IMAGES_TYPE_GAME]: '游戏和娱乐',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: '着装',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: '酒店和住宿',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: '照片拍摄',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: '礼品和礼物',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: '一般和爱情',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: '页面标题',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: '页面标题',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: '欢迎访问本页',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: '节目项目',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: '节目项目',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: '从 15:00 开始',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: '集体照',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: '信息框',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 蛋糕',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]: ' 如果您想制作蛋糕，请告诉我们。我们将为您准备丰富多彩的蛋糕。',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: '带大幅照片的盒子',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: '您的介绍',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]: '在这里，您可以向宾客介绍自己。告诉他们您是如何认识...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: '婚礼地点地图',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: '婚礼地点',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `现代`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `让你们的爱情在与时俱进的氛围中闪耀。`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `经典`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `简洁的线条、协调的色彩和一丝华丽，营造出一个尊重你们爱情的氛围。`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `复古`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `复古风格带回了过去时代的魅力，带着一点怀旧和优雅。`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `花卉`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `这种婚礼风格庆祝花卉的自然美丽，在它们的全部辉煌中展现出来。`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `波西米亚`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `一种轻松且不拘一格的氛围，将自然与创造力结合起来 – 适合自由的灵魂。`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `绽放`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `一种浪漫的风格，突出了花朵和花卉元素的微妙美感。`,
  [k.NOT_SURE_ABOUT_DESIGN]: `不确定？不用担心，您可以稍后更改样式，而不会丢失任何数据。`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `注册后，您可以上传自己的照片和标志。`,
  [k.YOUR_NAMES]: `你们的名字`,
  [k.YOUR_WEDDING_DATE]: `你们的婚礼日期`,
  [k.WEDDING_DATE_FIX]: `婚礼日期已定`,
  [k.WEDDING_DATE_NOT_FIX]: `婚礼日期未定`,
  [k.YOUR_WEDDING_STYLE]: `你们的网站风格`,
  [k.OWN_LOGO]: `自定义标志`,
  [k.START_PHOTO]: `首页照片`,
  [k.USE_START_PHOTO]: `使用自己的照片？`,
  [k.WITH_START_PHOTO]: `使用自己的照片`,
  [k.WITHOUT_START_PHOTO]: `不使用自己的照片`,
  [k.ALL_STYLES]: `所有风格`,

  [k.COOKIES_01]: '您需要一些婚礼饼干吗？',
  [k.COOKIES_02]:
    "点击 '全部确认 '将接受所有 cookie。或者，您也可以自定义选择。您可以随时通过 'Cookie 设置 '撤销您的选择。",
  [k.COOKIES_03]: '必要 Cookie',
  [k.COOKIES_04]: '这些 cookie 是网站功能所必需的，因此您不能禁用它们。',
  [k.COOKIES_05]: '了解更多',
  [k.COOKIES_06]: '营销和个性化 cookie',
  [k.COOKIES_07]:
    '通过这些 Cookie，您可以接收根据您的用户行为定制的网站内容，我们也可以根据使用统计数据，按照客户的兴趣定制我们的网站。',
  [k.COOKIES_08]: '接受选择',
  [k.COOKIES_09]: '全部接受',
  [k.TERMS_AND_CONDITIONS_00]: '使用条款',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt)，以下也称 '提供商'、'我们 '或 'EureHochzeitOnline.com'，为创建个人婚礼应用程序提供平台。通过这些应用程序，用户（以下统称 '用户'）作为主人可以为自己的婚礼创建一个应用程序，并通过该应用程序向宾客提供婚礼信息和互动机会。以下条款和条件适用于平台的使用。",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 平台的使用',
  [k.TERMS_AND_CONDITIONS_03]:
    '平台的试用版一般免费使用。若婚礼宾客人数达到或超过规定人数，则需一次性支付费用。试用版和正式版都需要事先注册并创建用户账户。用户不得允许第三方使用其账户。用户有义务对其访问数据保密并防止第三方访问。',
  [k.TERMS_AND_CONDITIONS_04]:
    '婚礼应用程序将在婚礼结束后六个月内可用。之后，我们将停用婚礼应用程序，以便将所需地址提供给其他新人。如果您需要延长婚礼应用程序的在线时间，我们可以做出特殊安排。',
  [k.TERMS_AND_CONDITIONS_05]:
    '婚礼应用程序一旦创建，只能用于一场婚礼。例如，如果我们发现用户将婚礼应用程序提供或出售给其他新人，我们有权阻止此类使用，并对其他婚礼收取费用。',
  [k.TERMS_AND_CONDITIONS_06]: '§ 第 2 节 数据保护',
  [k.TERMS_AND_CONDITIONS_07]: '通过使用本软件，用户接受',
  [k.TERMS_AND_CONDITIONS_08]: '数据保护',
  [k.TERMS_AND_CONDITIONS_09]: '条例',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 EureHochzeitOnline.com的功能和服务',
  [k.TERMS_AND_CONDITIONS_11]: '通过 EureHochzeitOnline.com 创建的个人婚礼应用程序具有以下功能',
  [k.TERMS_AND_CONDITIONS_12]: '个性化：',
  [k.TERMS_AND_CONDITIONS_13]:
    '主人可以对婚礼应用程序的某些方面进行配置和设计，例如，为了与婚礼请柬的图形设计相匹配，他们可以选择背景、图像、颜色和字体。',
  [k.TERMS_AND_CONDITIONS_14]: '照片库：',
  [k.TERMS_AND_CONDITIONS_15]: '宾客可以在婚礼前或婚礼期间拍摄并上传照片。这些照片可以显示在自动更新的实时图片库中。',
  [k.TERMS_AND_CONDITIONS_16]: '音乐请求：',
  [k.TERMS_AND_CONDITIONS_17]: '来宾可以使用搜索功能为婚礼点歌，并对点歌进行评分。主持人可使用此功能选择歌曲。',
  [k.TERMS_AND_CONDITIONS_18]: '婚礼信息：',
  [k.TERMS_AND_CONDITIONS_19]: '主人可以在婚礼应用程序中为宾客发布带有互动地图的场地、日程安排和其他信息。',
  [k.TERMS_AND_CONDITIONS_20]: 'EureHochzeitOnline.com 保留随时更改软件功能范围和设计的权利。',
  [k.TERMS_AND_CONDITIONS_21]:
    '使用这些功能需要配备现代互联网浏览器的现代智能手机、平板电脑或个人电脑。特别是图片上传、婚礼问答或音乐请求等实时功能需要稳定快速的网络连接。EureHochzeitOnline.com对所提供内容的准确性、完整性、可靠性、及时性和可用性不承担任何责任。',
  [k.TERMS_AND_CONDITIONS_22]:
    '在维护工作期间，EureHochzeitOnline.com 将向主机宣布，平台可能会暂时无法使用。我们将尽一切努力保证服务的可用性，我们可以自豪地说，我们从未让用户失望过。但是，我们不承担任何进一步的义务，尤其是用户无权要求服务的持续可用性。',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 用户的义务',
  [k.TERMS_AND_CONDITIONS_24]:
    '用户可以作为访客或主机在 EureHochzeitOnline.com 平台上保存和发布自己的内容和文件。用户对这些内容负责。用户承诺不发布任何违反公共道德或适用法律的文章、文件、婚礼信息或其他内容。尤其是，用户同意不发布以下任何文章、文件、婚礼信息或其他内容',
  [k.TERMS_AND_CONDITIONS_25]: '发布构成刑事犯罪或行政违法的内容、',
  [k.TERMS_AND_CONDITIONS_26]: '违反版权法、商标法或竞争法',
  [k.TERMS_AND_CONDITIONS_27]: '违反《法律服务法》、',
  [k.TERMS_AND_CONDITIONS_28]: '或包含攻击性、种族主义、歧视性或色情内容',
  [k.TERMS_AND_CONDITIONS_29]: '包含广告的内容。',
  [k.TERMS_AND_CONDITIONS_30]:
    'EureHochzeitOnline.com有权更改或删除任何违反或涉嫌违反这些义务的内容。如果用户违反了使用条款，EureHochzeitOnline.com 也有权阻止用户访问和婚礼应用程序的设置。用户应赔偿提供方因违反义务而造成的任何损失。提供方有权要求用户赔偿第三方因用户侵权而提出的索赔。用户有义务为提供方辩护。用户还有义务承担为提供方进行适当法律辩护的费用。',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 知识产权',
  [k.TERMS_AND_CONDITIONS_32]: '您的知识产权',
  [k.TERMS_AND_CONDITIONS_33]:
    '您拥有您发布的内容、文本、图像和其他材料的知识产权。EureHochzeitOnline.com对这些内容不主张任何知识产权。但是，您授权EureHochzeitOnline.com访问、复制和分发这些内容，以便将其提供给婚礼客人。您还授权我们执行任何其他必要的行为，以提供我们的服务，包括但不限于制作备份副本和为支持人员提供访问权限。',
  [k.TERMS_AND_CONDITIONS_34]: '我们的知识产权',
  [k.TERMS_AND_CONDITIONS_35]:
    '提供方不授予任何使用EureHochzeitOnline.com网站或软件平台知识产权的许可或其他权利，但根据使用条款提供的定期创建的婚礼应用程序除外。未经提供方事先书面同意，不得复制、分发、翻印、传播或以其他方式使用知识产权，包括将其提供给第三方。',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 退出权',
  [k.TERMS_AND_CONDITIONS_37]:
    '您有权在十四天内撤销本合同，无需说明理由。退约期限为合同签订后 14 天内。您可以通过发送电子邮件至',
  [k.TERMS_AND_CONDITIONS_38]: '明确说明您撤回合同的决定。您也可以在电子邮件中使用以下表格样本：',
  [k.TERMS_AND_CONDITIONS_39]: '撤销合同表格样本',
  [k.TERMS_AND_CONDITIONS_40]: '- 致 fidira Software UG (haftungsbeschränkt)，电子邮件：beeni@eurehochzeitonline.com：',
  [k.TERMS_AND_CONDITIONS_41]: '- 我/我们 ( * ) 特此撤销由我/我们 ( * ) 签订的激活婚礼应用程序的合同 ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- 订购日期 ( * ) / 接收日期 ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- 消费者姓名',
  [k.TERMS_AND_CONDITIONS_44]: '- 消费者地址',
  [k.TERMS_AND_CONDITIONS_45]: '- 消费者签名（仅适用于书面通知',
  [k.TERMS_AND_CONDITIONS_46]: '- 日期',
  [k.TERMS_AND_CONDITIONS_47]: '(*)酌情删除',
  [k.TERMS_AND_CONDITIONS_48]: '为了遵守撤销期限，在撤销期限结束前发送行使撤销权的通知即可：',
  [k.TERMS_AND_CONDITIONS_49]: '撤销的后果：',
  [k.TERMS_AND_CONDITIONS_50]:
    '如果您取消合同，我们将删除您的婚礼应用程序，如果我们已经收到您的付款，我们将使用相同的付款方式向您退款。退款不收取任何费用。',
  [k.TERMS_AND_CONDITIONS_51]:
    '在交付非实体数据载体的数字内容（如婚礼应用程序）的合同中，如果供应商在用户满足以下条件后开始履行合同，则根据《德国民法典》第356 (5)条规定的撤销权也将失效：1.明确同意供应商在撤销期限届满前开始履行合同；2.确认其知悉同意开始履行合同将丧失撤销权。',
  [k.TERMS_AND_CONDITIONS_52]: '§ 第 7 条 保证和免责声明',
  [k.TERMS_AND_CONDITIONS_53]:
    "提供方 '按原样'、'无故障 '和 '可用 '提供服务，在法律允许的范围内，不对适销性、特定用途的适用性、工作性能、非侵权性或任何其他保证做出明示或暗示的保证。也不保证提供方的服务（或其任何部分、功能或内容）是（或将是）完整的、具有特定质量的、可靠的、安全的、准确的、与您的预期活动、使用的设备、操作系统、浏览器或程序（客人或主机）兼容的，或符合适用于您的任何法律要求的，或服务不含病毒、漏洞或其他有害成分或程序限制的。此外，EureHochzeitOnline.com对EureHochzeitOnline.com平台上提及或提供的任何公司、产品或服务（包括第三方服务）不承担任何责任。对数据和信息的丢失不承担任何责任，提供者也不保证重建这些数据和信息。用户应自行承担备份数据和信息的风险。EureHochzeitOnline.com也不对用户发布的内容或用户的正确身份承担任何责任。",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 可分割性条款',
  [k.TERMS_AND_CONDITIONS_55]:
    '如果本协议的任何条款因任何原因或在任何范围内被认定为无效或不可执行，则该无效性或不可执行性不应影响本协议的其余条款，且该条款的适用应在法律允许的最大范围内执行。如果EureHochzeitOnline.com放弃执行违反一项或多项条款和条件的权利，这并不意味着EureHochzeitOnline.com也放弃执行其他条款和条件的权利。',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 最后条款',
  [k.TERMS_AND_CONDITIONS_57]:
    '除非法律另有规定，协议应受德意志联邦共和国法律管辖。因本使用条款引起的或与之相关的所有争议/索赔的专属管辖地均为慕尼黑。我们保留将来更改本使用条款并在相同位置发布新版本的权利。使用条款变更后，新版本必须由用户确认。',
  [k.TERMS_AND_CONDITIONS_58]: '状态：01.04.2018',
  [k.IMPRINT_00]: '版本/法律声明',
  [k.IMPRINT_01]: 'fidira Software UG（有限责任公司）',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de 是 fidira Software UG 的商标。',
  [k.IMPRINT_03]: 'Enzianstr.31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: '电子邮件：',
  [k.IMPRINT_06]: 'Web：',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: '公司注册地慕尼黑，慕尼黑 HRB 商业注册处',
  [k.IMPRINT_09]: '授权代表',
  [k.PRIVACY_00]: '隐私政策',
  [k.PRIVACY_01]: '简介和一般信息',
  [k.PRIVACY_02]: '保护您和您婚礼客人的隐私是 EureHochzeitOnline.com 的首要任务。',
  [k.PRIVACY_03]: '根据《德国联邦数据保护法》（BDSG）和《德国电信媒体法》（TMG）的要求，',
  [k.PRIVACY_04]:
    '我们始终确保您的数据仅用于创建和使用您的个人婚礼应用程序，以及优化您的婚礼应用程序的用户体验。我们使用 TLS/SSL（传输层安全/安全套接字层）技术创建和使用婚礼应用程序，因此数据在传输过程中始终是加密的。我们所有的应用程序服务器都位于欧盟，符合欧盟数据隐私法规。在注册过程中，我们会通过点击复选框的方式征求您对本隐私政策所述处理方式的同意。您一旦同意，即表示您确认我们可以在下述范围内收集、处理和使用您的个人数据。您可以随时撤销同意。为此，只需向我们的客户服务部门发送电子邮件，地址为',
  [k.PRIVACY_05]:
    '.根据您的要求，我们的客户服务部门还将向您提供有关您的所有存储数据的信息（根据《德国电信媒体法》（Telemediengesetz）§ 13 (7)），并将根据您的要求删除这些数据。',
  [k.PRIVACY_06]: '§ 1 负责机构和适用范围',
  [k.PRIVACY_07]:
    "责任主体为 fidira Software UG (haftungsbeschränkt)，以下也称为 '提供商'、'我们 '或 'EureHochzeitOnline.com'。有关地址和授权代表的详细信息，请参阅说明。",
  [k.PRIVACY_08]:
    '本隐私政策涉及通过互联网浏览器访问和使用提供方任何平台时个人数据的收集和使用。通过这些产品，提供方可以创建个人婚礼应用程序，宾客可以通过该应用程序了解婚礼信息，并在婚礼期间进行互动（如分享照片或回答问题）。供应商也会将个人数据用于统计和市场分析目的。在此过程中，我们会对婚礼、地点、婚礼规模或应用程序使用情况等进行匿名统计，以便进一步优化我们的服务。',
  [k.PRIVACY_09]: '§ 2 与其他网站的链接',
  [k.PRIVACY_10]:
    '我们的网站可能包含其他网站的链接。我们无法控制您在点击这些链接后被指向的内容，也无法控制您的数据（例如您的 IP 地址）可能被传输到第三方的处理过程。因此，我们对第三方的内容和第三方对这些数据的处理不承担任何责任。',
  [k.PRIVACY_11]: '§ 3 未经注册的数据使用',
  [k.PRIVACY_12]:
    '当您访问EureHochzeitOnline.com网站或应用程序时，我们会自动保存一些有关您访问的基本信息：特别是IP地址、访问的网页和访问的持续时间。我们利用这些信息对用户行为和常用功能进行匿名统计，以便不断改进我们的服务。您的 IP 地址只会在技术日志文件中存储一段有限的时间，以保护我们的系统和我们创建的婚礼应用程序免遭滥用和欺诈。',
  [k.PRIVACY_13]: '§ 4 注册时数据的使用',
  [k.PRIVACY_14]:
    '为了以客人身份访问婚礼应用程序或以主人身份创建婚礼应用程序，用户必须进行注册。在注册过程中，将存储用户的电子邮件地址、显示名称（可自由选择，也可以是编造的假名）、婚礼代码（如适用）和密码（可自由定义）。这些信息用于保护主人的婚礼列表，防止未经授权的访问。在创建婚礼应用程序时，主人可以输入有关其婚礼的信息（如地点、时间、日程安排），这些信息只有参加该婚礼的客人才能看到。这是通过主人生成婚礼代码来实现的，客人必须输入这些代码才能访问婚礼应用程序。',
  [k.PRIVACY_15]:
    '我们还将您的电子邮件地址用于通信目的：例如，可能会向主人发送有关新注册情况、其他婚礼新闻的电子邮件通知，EureHochzeitOnline.com 服务电子邮件也可能发送到该电子邮件地址（如满意度调查）。我们也会将婚礼来宾的电子邮件地址显示给主人，以便他们进一步组织婚礼。',
  [k.PRIVACY_16]: '您的电子邮件地址绝不会与第三方或其他公司共享。',
  [k.PRIVACY_17]: '§ 5 Cookies',
  [k.PRIVACY_18]:
    "为了给您提供最佳的用户体验，我们在您的浏览器中使用 'cookies '和所谓的 '本地存储'。这使我们能够在您的浏览器中存储有关婚礼应用程序的少量数据，例如，当您关闭浏览器或切换到应用程序配置器的全屏模式时，您所做的更改不会丢失，或者您不必每次都登录。如果您不想使用 cookie 或想删除现有 cookie，可以通过浏览器禁用或删除它们。请参考浏览器的使用说明，或联系我们寻求帮助。",
  [k.PRIVACY_19]: "我们还为'§ 7 应用程序和网站 '中列出的第三方服务使用cookies，以便为你提供更好的用户体验。",
  [k.PRIVACY_20]: '§ 6 应用程序和网站',
  [k.PRIVACY_21]: '谷歌地图',
  [k.PRIVACY_22]:
    '我们使用谷歌公司（Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA）的谷歌地图来显示交互式地图。您的 IP 地址、您所在的位置以及您使用我们服务的相关信息将被传送到谷歌在美国的服务器上并由其存储。有关 Google 地图的使用条款，请访问 https://www.google.com/intl/de_de/help/terms_maps.htmlHotJar。',
  [k.PRIVACY_23]: '我们还使用 ',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "（Hotjar 有限公司提供的网络分析工具）收集和存储数据，用于营销和优化目的。这些数据可用于创建假名用户档案。我们会记录随机选择的个人访问（不包含额外的个人信息，即所谓的 '用户属性'），从而创建鼠标移动和点击日志，目的是随机回放个人网站访问，为网站带来潜在的改进。所收集的数据不会用于识别网站访问者的个人身份，也不会在未经当事人单独同意的情况下与假名携带者的个人数据合并。如果您不希望被跟踪，可以通过点击以下链接，在您当前使用的浏览器上全面禁用所有使用 HotJar 的网站的跟踪功能",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    '我们使用来自 The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) 的 Mailchimp 发送时事通讯。这使我们能够直接联系订阅者。此外，我们还会分析您的使用行为，以便优化我们的产品。',
  [k.PRIVACY_29]: '为此，我们与 Mailchimp 共享以下个人信息',
  [k.PRIVACY_30]: '电子邮件地址 [姓名] [婚礼日期] [婚礼信息] [登录详情]。',
  [k.PRIVACY_31]:
    '我们的电子邮件将包含一个链接，供您更新个人信息。Mailchimp 是您个人数据的接收方，并代表我们处理我们的时事通讯。本节所述的数据处理并非法律或合同要求。未经您的同意并提交您的个人数据，我们无法向您发送时事通讯。此外，Mailchimp 还会使用 Cookie 和其他跟踪方法收集以下个人信息 有关您的设备的信息（IP 地址、设备信息、操作系统、浏览器 ID、您用来阅读电子邮件的应用程序信息，以及有关您的硬件和互联网连接的其他信息。此外，还会收集使用数据，如您打开电子邮件/活动的日期和时间以及浏览器活动（如打开了哪些电子邮件/网站）。Mailchimp 需要这些信息来确保系统的安全性和可靠性、执行服务条款并防止滥用。这符合 Mailchimp 的合法权益（根据 GDPR 第 6 条第 1 款 f 项），并有助于履行合同（根据 GDPR 第 6 条第 1 款 b 项）。Mailchimp 还会分析性能数据，如电子邮件发送统计和其他通信数据。这些信息用于汇编服务的使用情况和性能统计数据。Mailchimp 还从其他来源收集有关您的信息。我们会不时通过社交媒体和其他第三方数据提供商收集个人信息，收集的规模不定。我们无法控制这些过程。有关您在 ',
  [k.PRIVACY_32]: 'Mailchimp 上的选择和删除选项的更多信息，请访问',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'The 根据 GDPR 第 6 条第 1 款 a 项的规定，处理的法律依据是您的同意。您可以随时撤销对处理您个人数据的同意。您可以在所有邮件中找到撤销同意的链接。撤销同意并不影响已经进行的任何处理的合法性。',
  [k.PRIVACY_35]:
    '只要您同意，您的数据就会一直被处理。否则，在我们与 Mailchimp 之间的合同终止后，您的数据将被删除，除非法律要求必须继续保存。',
  [k.PRIVACY_36]:
    'Mailchimp 已实施国际数据传输合规措施。这些措施适用于 Mailchimp 处理欧盟个人数据的所有全球业务。这些政策基于欧盟标准合同条款 (SCC)。更多信息请访问',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    '我们还使用 Spotify AB 提供的音乐播放器 Spotify Music Player Widget 来让您收听婚礼播放列表。Spotify 音乐播放器小工具的服务提供商是 Spotify AB, Regeringsgatan 19, 111 53 Stockholm, Sweden。有关 Spotify AB 数据处理的更多信息，请访问',
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Facebook 像素',
  [k.PRIVACY_42]:
    "为了改进 EureHochzeitOnline.com，使其为更多用户所知，我们使用了 Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, USA（'Facebook'）的 Facebook Pixel。为此，Facebook 会在您的浏览器中植入 Cookie。由 Cookie 生成的有关您使用本网站的信息将被传输到 Facebook 在美国的服务器上并由其存储。Facebook 可利用此数据传输确定您的浏览器访问了某一特定页面，以及是否是通过点击广告访问的。但是，我们不会利用这些数据从我们的网站收集信息。Facebook 利用这些信息帮助我们制作更多广告并推广 EureHochzeitOnline.com。我们通过Facebook分析工具 'Facebook Analytics '评估从Facebook像素获得的数据，以进一步改进EureHochzeitOnline.com。在法律要求或第三方代表Facebook处理信息的情况下，Facebook可能会将这些信息转移给第三方。使用EureHochzeitOnline.com即表示您同意Facebook以上述方式处理您的相关信息，并用于改进EureHochzeitOnline.com。我们不会与Facebook共享您的任何个人信息、联系方式或婚礼信息。您可在以下网址了解更多有关 Facebook Pixel 安全和隐私政策的信息",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]: '您也可以点击此处退出Facebook Pixel的信息收集：',
  [k.PRIVACY_45]: '停用像素',
  [k.PRIVACY_46]: '§ 7 知情权和《德国数据保护法》规定的其他权利',
  [k.PRIVACY_47]:
    '我们很乐意随时向您提供有关我们所储存的您的个人数据的信息。根据您的要求，我们还将更正、阻止或删除您的数据。您可以随时通过以下方式与我们联系，提出上述要求或撤销声明',
  [k.PRIVACY_48]: '我们将尽快进行更改。',
  [k.PRIVACY_49]: '§ 8 数据保护声明的变更',
  [k.PRIVACY_50]:
    '互联网和技术在不断发展。毋庸置疑，我们将利用这些新的可能性使我们用户的婚礼更加难忘。这可能导致我们更新和修改我们的隐私政策。',
  [k.PRIVACY_51]: '最后更新11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: '如果您需要更多语言，请给我们发送电子邮件。',
  [k.DECIDE_LANGUAGES]: '决定您的婚礼网站应支持哪些语言。 您可以输入所有选定语言的文本。',
  [k.EDIT_LANGUAGES]: '编辑语言',
  [k.ACTIVE_VOUCHER]: `有效优惠券：`,
  [k.ENTER_VOUCHER_CODE]: `输入优惠券代码`,
  [k.VOUCHER_CODE]: `优惠码`,
  [k.INVALID_CODE]: `不幸的是没有有效的代码！`,
  [k.WANT_CUSTOM_LOGO]: `你想使用自己的婚礼标志吗？`,
  [k.INDIVIDUAL_LOGO]: `个性化标志`,
  [k.STANDARD_LOGO]: `标准标志`,
  [k.PRIVATE_PURCHASE]: `购买是私人行为`,
  [k.COMMERCIAL_PURCHASE]: `购买是商业行为`,
  [k.MAX_GUESTS]: '免费版：最多可容纳客人数',
  [k.MAX_GUESTS_REACHED]:
    '此婚礼仍在使用EureHochzeitOnline.com的免费基础版，但现在已有超过五位注册客人。根据我们的使用条款，需升级至完整或高级套餐。因此，请升级您的套餐，以便继续正常使用您的婚礼网站。',
  [k.ACTIVATE_PACKAGE]: '解锁套餐',
  [k.IF_MESSAGE_WRONGFUL]: '如果您认为此消息显示有误，请发送电子邮件至：',
};
