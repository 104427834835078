const k = require('./keys');
export default {
  [k._DOT]: '.',
  [k.HELLO]: 'Hola,',
  [k.ENTER_NEW_PASSWORD]:
    'Por favor, introduzca aquí su nueva contraseña. Se iniciará sesión automáticamente y se le redirigirá.',
  [k.MINIMUM_6_CHARACTERS]: 'Por favor, utilice un mínimo de 6 caracteres.',
  [k.SAVE]: 'Guardar',
  [k.UNABLE_TO_RESET_PASSWORD]: 'No hemos podido restablecer tu contraseña.',
  [k.PASSWORD_CHANGED_SUCCESSFULLY_TITLE]: 'Contraseña cambiada con éxito',
  [k.PASSWORD_CHANGED_SUCCESSFULLY]:
    'Hemos cambiado su contraseña. Ya puede iniciar sesión con su nueva contraseña. Ahora te estamos reenviando.',
  [k.FUNCTIONS]: 'Características',
  [k.DESIGNS]: 'Temas',
  [k.PRICES]: 'Precios',
  [k.FAQ]: 'PREGUNTAS FRECUENTES',
  [k.BLOG]: 'Blog',
  [k.CREATE_NOW_FREE]: 'Crear ahora gratis',
  [k.PERFECT_PAGE_FOR_YOU]: 'Su sitio web a su medida',
  [k.PERFECT_DESIGN_IN_FEW_CLICKS]:
    'Consigue el diseño perfecto en sólo unos clics con nuestro configurador de diseño.',
  [k.DESIGN]: 'Diseño',
  [k.HOW_DESCRIBE_YOUR_DREAM_WEDDING]: '¿Cómo describirías la boda de tus sueños?',
  [k.CLICK_ON_ONE_OF_THE_IMAGES]: 'Haga clic en una de las imágenes para iniciar el configurador de diseño',
  [k.CREATE_WEDDING_WEBSITE_NOW]: 'Crear ahora gratis',
  [k.FREQUENTLY_ASKED_QUESTIONS]: 'Preguntas frecuentes',
  [k.ANY_MORE_QUESTIONS_OR_FEEDBACK]: '¿Tiene alguna pregunta o comentario?',
  [k.WE_LOOK_FORWARD_TO_IT]: 'Estaremos encantados de atenderle.',
  [k.CONTENT_AND_DESIGN]: 'CONTENIDO Y DISEÑO',
  [k.PHOTO_GALLERY]: 'Galerías de fotos',
  [k.RSVP_CARD]: 'RSVP/Tarjeta de respuesta',
  [k.PLAYLIST]: 'Lista de reproducción',
  [k.MULTILINGUAL_OWN_DOMAIN]: 'MULTILINGÜISMO Y DOMINIO PROPIO',
  [k.DURATION_PASSWORD_PROTECTION]: 'TIEMPO DE EJECUCIÓN Y PROTECCIÓN POR CONTRASEÑA',
  [k.COST_SUPPORT]: 'COSTES Y ASISTENCIA',
  [k.WHAT_CONTENT_TYPICALLY]: '¿Qué contenido es típico para una página web de boda?',
  [k.CAN_CREATE_WEBSITE_BEFORE_DETAILS]:
    '¿Puedo crear la página web de mi boda antes de que estén ultimados todos los detalles (como la fecha de nuestra boda)?',
  [k.CAN_CUSTOMIZE_DESIGN]: '¿Puedo personalizar el diseño de la página web de mi boda?',
  [k.CHANGES_VISIBLE_AFTER_SAVE]:
    '¿Es posible hacer cambios en la página de inicio sin que sean inmediatamente visibles para todo el mundo?',
  [k.PHOTO_UPLOAD_LIMIT]: '¿Hay un límite en el número de fotos que podemos subir a la galería?',
  [k.ORIGINAL_SIZE_PHOTO_STORAGE]: '¿Se guardarán las fotos en su tamaño original?',
  [k.GUESTS_DOWNLOAD_UPLOADED_PHOTOS]: '¿Pueden los invitados descargar las fotos cargadas en el sitio?',
  [k.USE_PHOTO_GALLERY_FOR_SLIDESHOW]:
    '¿Podemos utilizar la galería de fotos para una presentación de diapositivas en directo durante nuestra fiesta?',
  [k.HOW_DOES_R_S_V_P_WORK]: '¿Cómo funciona la función de confirmación de asistencia?',
  [k.GUESTS_ADD_OWN_SONGS_TO_PLAYLIST]:
    '¿Pueden los invitados añadir sus propias canciones a la lista de reproducción?',
  [k.CAN_DJ_ACCESS_PLAYLIST]: '¿Puede nuestro DJ acceder a la lista de reproducción?',
  [k.POSSIBILITY_OF_MULTIPLE_LANGUAGES]: '¿Es posible tener nuestra página de inicio en diferentes idiomas?',
  [k.OWN_DOMAIN_POSSIBILITY]: '¿Podemos tener nuestro propio dominio para la página web de nuestra boda?',
  [k.HOW_LONG_ACCESS_WEDDING_WEBSITE]: '¿Durante cuánto tiempo estará disponible la página web de nuestra boda?',
  [k.PROTECT_SITE_WITH_PASSWORD]: '¿Podemos proteger nuestra página con una contraseña?',
  [k.IS_EUREHOCHZEITONLINE_FREE]: '¿Es gratuito el uso de EureHochzeitOnline.com?',
  [k.HOW_GET_SUPPORT_EUREHOCHZEITONLINE]:
    '¿Cómo puedo obtener ayuda para crear mi página web de boda en EureHochzeitOnline.com?',
  [k.WEDDING_CONTENT_INDIVIDUAL]:
    'El contenido de su página web de boda es tan individual como su propia boda. Además de información básica como la fecha y el lugar, puede añadir códigos de vestimenta, direcciones, peticiones especiales, información de contacto del oficiante o un calendario previsto. Para los invitados que viajen desde lejos y necesiten alojamiento, puedes incluir sugerencias de hoteles. También es una gran idea compartir tu historia de amor con los invitados o escribir palabras personales sobre tu pareja. La creatividad no tiene límites.',
  [k.YES_FEASIBLE_ADVANTAGES]:
    'Sí, es fácil de hacer e incluso ofrece importantes ventajas. Puedes añadir información y proporcionar actualizaciones en cualquier momento, incluso después de que se hayan enviado las invitaciones de boda.',
  [k.YES_EUREHOCHZEITONLINE_OFFERS]:
    'Sí, EureHochzeitOnline.com ofrece una variedad de opciones de diseño para su sitio web de la boda. Puede personalizar colores, fuentes y elementos adicionales. Póngase en contacto con nosotros si tiene alguna pregunta o comentario.',
  [k.INDEED_CHANGES_VISIBLE]:
    'De hecho, todos los cambios en su sitio web será inmediatamente visible para todos los visitantes en tiempo real. Actualmente no ofrecemos una función para programar u ocultar cambios con antelación.',
  [k.IN_FREE_VERSION_LIMITED_PHOTOS]:
    'En la versión gratuita hay un número limitado de fotos que puedes subir a tu galería. Esto se debe a que tenemos costes asociados al almacenamiento y al tráfico. Sin embargo, si adquieres los paquetes Premium o Dominio, podrás subir un número ilimitado de fotos a tu galería. Consulta nuestro blog para obtener consejos sobre cómo conseguir las fotos de boda perfectas.',
  [k.WE_GENERATE_DIFFERENT_SIZES]:
    'Cuando subes tus fotos, creamos diferentes tamaños para cada imagen. En función del tamaño de la pantalla del visitante, se selecciona automáticamente el tamaño óptimo para garantizar tiempos de carga rápidos con la mejor calidad de imagen posible. No obstante, también guardamos los archivos originales de las fotos para que usted también pueda recibirlos.',
  [k.YES_GUESTS_CAN_DOWNLOAD]: 'Sí, los visitantes pueden descargar las fotos subidas.',
  [k.YES_FEASIBLE_SCREEN_PROJECTOR]:
    'Por supuesto. De hecho, ésta es una de las funciones más populares de EureHochzeitOnline. Todo lo que necesitas es una conexión estable a Internet y, por supuesto, una pantalla o proyector.',
  [k.R_S_V_P_FUNCTION_SIMPLIFIES]:
    'La función RSVP facilita la tarea de compartir la información de acceso al sitio web de tu boda con tus invitados. Cuando visiten el sitio web de tu boda, se les pedirá que dejen sus comentarios (por ejemplo, con cuántas personas asistirán, si son niños o adultos, y cualquier petición especial de comida, alergias u otros requisitos). En el área de invitados, puede hacer un seguimiento del número de aceptaciones y cancelaciones en cualquier momento. Consulte nuestro blog para obtener instrucciones paso a paso.',
  [k.YES_YOUR_GUESTS_CAN_SUGGEST]:
    '¡Por supuesto! Tus invitados pueden sugerir sus canciones favoritas y añadirlas a la lista de reproducción. Así, no sólo podrás celebrar la diversidad de tus invitados, sino también disfrutar de la música que es importante para ti y tus seres queridos.',
  [k.NATURALLY_INVITE_DJ_TO_WEBSITE]:
    'Por supuesto. Sólo tienes que invitar a tu DJ a tu página web y todos tus deseos estarán a su disposición, incluso en directo durante tu fiesta, si quieres.',
  [k.YES_YOU_CAN_HAVE_MULTIPLE_LANGUAGES]:
    'Sí, puedes tener tu sitio web en varios idiomas. Esta función está disponible en el paquete Premium y puedes traducir toda la información a diferentes idiomas. Ponte en contacto con nosotros para que podamos establecer la configuración para tu boda.',
  [k.YES_YOU_CAN_HAVE_OWN_DOMAIN]:
    'Sí, usted puede reservar fácilmente su propio dominio (por ejemplo, www.juliaundromeo.de) para su sitio web de la boda con nuestro paquete Premium. Póngase en contacto con nosotros para que podamos reservar el dominio para usted y configurar la redirección.',
  [k.YOUR_WEDDING_WEBSITE_AVAILABLE_TWO_YEARS]:
    'La página web de tu boda estará disponible durante dos años a partir de su creación. Después, se eliminará para dejar sitio a nuevas páginas web de bodas. Se lo recordaremos con antelación. Las renovaciones están disponibles bajo petición.',
  [k.ALL_WEDDING_WEBSITES_PASSWORD_PROTECTED]:
    'Todas las páginas web de bodas están protegidas con una contraseña, que le será mostrada tras su creación.',
  [k.YES_CREATE_WEDDING_WEBSITE_FREE]:
    'Sí, puede crear y diseñar una página web de boda en EureHochzeitOnline.com de forma gratuita. Sin embargo, algunas funciones avanzadas no son gratuitas, especialmente las que requieren recursos adicionales del servidor. No hay cargos ocultos.',
  [k.IF_YOU_NEED_SUPPORT_WE_CAN_HELP]:
    'Si necesita ayuda para crear su sitio web de bodas, no dude en enviarnos un mensaje a beeni@eurehochzeitonline.com. Nos pondremos en contacto contigo lo antes posible para ayudarte.',
  [k.KEY_FEATURES_OVERVIEW]: 'Las mejores características de un vistazo',
  [k.YOUR_WEDDING_WEBSITE_OFFERS_EVERYTHING]:
    'Aquí tienes tu web de bodas con todo lo que necesitas para crear la boda de tus sueños.',
  [k.NEW_NOW]: 'Novedad:',
  [k.MULTILINGUAL_WEDDINGS]: '¡Bodas multilingües!',
  [k.ON_REQUEST_WE_PROVIDE_WEDDING_WEBSITES]: 'Ahora disponible: Sitios web multilingües!',
  [k.SCHEDULE]: 'Calendario de bodas',
  [k.LOCATIONS]: 'Lugares de la boda',
  [k.RSVP]: 'Tarjeta de respuesta',
  [k.GUESTMANAGEMENT]: 'Gestión de invitados',
  [k.HOTEL_RECOMMENDATION]: 'Recomendaciones de hoteles',
  [k.PRESENT_TABLE]: 'Mesa de regalos',
  [k.OWN_DOMAIN]: 'Dominio personalizado',
  [k.DIVERSE_LAYOUTS]: 'Innumerables disposiciones y diseños para elegir y personalizar.',
  [k.YOUR_WEDDING_DAY]: 'El día de su boda perfectamente organizado para sus invitados.',
  [k.CLEAR_DIRECTIONS]: 'Direcciones claras a todos los lugares de la boda.',
  [k.NO_GUESSWORK]: 'Se acabaron las tediosas preguntas sobre quién vendrá a la boda.',
  [k.GUEST_ADDRESSES_ALLERGIES]: 'Recopile direcciones, alergias, etc. con un solo clic.',
  [k.HOTEL_SELECTION_FOR_GUESTS]: 'Selección de hoteles para facilitar el viaje de sus invitados.',
  [k.EASY_ONLINE_GIFT_REGISTRY]: 'Su registro de boda más fácil.',
  [k.WEDDING_PICTURES_FOR_GUESTS]: 'Tus fotos de boda y las de tus invitados en un solo lugar.',
  [k.CUSTOM_SPOTIFY_PLAYLIST_CREATION]: 'Tu propia lista de reproducción de Spotify, creada por ti y tus invitados.',
  [k.WEDDING_HOMEPAGE_WITH_OWN_DOMAIN]: 'Página web de la boda con dominio propio y protección por contraseña.',
  [k.YOUR_WEDDING_WEBSITE]: 'LA WEB DE TU BODA',
  [k.UNIQUE_LIKE_YOU]: '- ¡TAN ÚNICA COMO VOSOTROS!',
  [k.EASY_SETUP_CUSTOMIZED]: 'Fácil de configurar y personalizar.',
  [k.ALL_DETAILS_OF_YOUR_CELEBRATION]: 'Todos los detalles de su evento de un vistazo - ya sea en su teléfono o PC',
  [k.GUEST_FEEDBACK_AND_WISHES]: 'Confirmaciones y solicitudes de los invitados al alcance de su mano',
  [k.YOUR_CELEBRATION_FROM_ALL_VIEWS]:
    'Tu fiesta desde todos los ángulos: galería de fotos en directo para ti y tus invitados',
  [k.MULTILINGUAL_SUPPORT]: '... por supuesto también para bodas multilingües',
  [k.FREE_CREATE]: 'Crear ahora gratis',
  [k.DEMO_WEBSITE]: 'Sitio web de demostración',
  [k.OUR_PRICING]: 'Nuestros precios',
  [k.UNLOCK_ALL_FEATURES]: 'Desbloquee todas las funciones que desee por una tarifa única',
  [k.ANY_QUESTIONS_OR_FEEDBACK]: '¿Tiene alguna pregunta o petición?',
  [k.WE_ARE_HERE_TO_HELP]: 'Estaremos encantados de atenderle.',
  [k.USER_TESTIMONIALS]: 'Voces de nuestros usuarios',
  [k.THOUSANDS_OF_PEOPLE]: '¡Miles de personas ya han celebrado su boda con EureHochzeitOnline!',
  [k.OUR_GIFT_TIP]: 'Nuestra idea de regalo:',
  [k.FOR_ALL_WITNESSES]: '',
  [k.BUY_WEDDING_WEBSITE_AS_GIFT]: 'Regale a los novios una página web de boda personalizada.',
  [k.BRIDE]: 'Novia',
  [k.F_WITNESS]: 'Dama de honor',
  [k.GROOM]: 'Novio',
  [k.TESTIMONIAL_1]:
    'La creación de nuestro sitio web fue pan comido. Nos sorprendió lo fácil que era añadir incluso información complicada. Realmente pudimos crear NUESTRA página web. ¡Nuestros invitados quedaron impresionados!',
  [k.TESTIMONIAL_2]:
    'Fue fantástico que todos nuestros invitados pudieran utilizar el sitio en su propio idioma. Les encantó la función de lista de reproducción que les permitía sugerir sus propias canciones favoritas. De este modo, la diversidad de los invitados también se celebró a través de la música de la fiesta.',
  [k.TESTIMONIAL_3]:
    'Nuestro sitio web fue una ayuda inestimable durante la planificación de nuestra boda. Pudimos actualizar información importante en cualquier momento e incluso mostrar fotos en una preciosa galería fotográfica. A nuestros invitados les encantó el pase de diapositivas en directo y a nosotros aún más.',
  [k.TESTIMONIAL_4]:
    'Más de un año después, todavía disfrutamos escuchando la lista de reproducción de Spotify de nuestra boda. Nuestro sitio web de bodas nos ayudó a tener una gran boda con recuerdos maravillosos.',
  [k.THANK_YOU]: '¡Muchas gracias!',
  [k.ACTIVATED_MAIL]: '¡Has activado tu dirección de correo electrónico!',
  [k.CLICK_HERE_TO_GO_TO_WED]: 'Haz clic aquí para ir a la página web de la boda de',
  [k.AND]: 'y',
  [k.BE_REDIRECTED]: 'para ser redirigido.',
  [k.SET_A_PASSWORD]: '¡Por favor, establezca una contraseña para que siempre pueda iniciar sesión y editar su boda!',
  [k.SET_A_PASSWORD_SHORT]: 'Por favor, elija una contraseña',
  [k.ERROR_OCCURED]:
    'Se ha producido un error. Por favor, inténtelo de nuevo. Si el error se produce repetidamente, puede enviarnos un correo electrónico a:',
  [k.PASSWORD_ALREADY_EXISTING_TITLE]: 'Ya tienes una contraseña',
  [k.PASSWORD_ALREADY_EXISTING]: 'Hemos observado que ya ha creado una contraseña. Puede',
  [k.GO_TO_WEDDING]: 'ir a la página de inicio de tu boda aquí y registrarte',
  [k.I_AGREE]: 'Acepto la',
  [k.REGISTER_NOW]: '¡Regístrese ahora!',
  [k.YOUR_FREE_WEDDING_WEBSITE]: '¡Tu página de boda gratis!',
  [k.ALREADY_CREATED_WEDDINGS]:
    'Ya has creado las siguientes páginas de boda. Probablemente prefieras editar las páginas de boda existentes en lugar de crear una nueva. Haga clic a continuación.',
  [k.WEDDING]: 'Páginas web de bodas',
  [k.ENTER_PASSWORD]: 'Introduzca su contraseña',
  [k.USER_ALREADY_EXISTING]:
    'Ya tenemos un usuario registrado con esta dirección de correo electrónico. Por favor, introduzca su contraseña.',
  [k.BACK]: 'Volver',
  [k.LOGIN]: 'Iniciar sesión',
  [k.FORGOT_PASSWORD]: '¿Ha olvidado su contraseña? Haga clic aquí.',
  [k.MAIL_SENT]:
    'Le hemos enviado un correo electrónico. Haga clic en el enlace del correo electrónico para restablecer su contraseña.',
  [k.OK]: 'OK',
  [k.PERFECT]: 'Perfecto,',
  [k.NAME_OF]: '¿Cuál es el nombre',
  [k.SPOUSE_M]: 'Tu elegido',
  [k.SPOUSE_F]: 'Su elegido',
  [k.NEXT]: 'Siguiente',
  [k.WHATS_YOUR_NAME]: '¡Impresionante! ¿Cuál es tu nombre?',
  [k.PREPARING_1]: 'Estamos preparando tu aplicación de boda...',
  [k.PREPARING_2]: 'Guardamos el diseño que has elegido...',
  [k.PREPARING_3]: 'Unos últimos retoques...',
  [k.FINISHED_ENTER_MAIL]:
    '¡Listo! Por favor, introduce tu dirección de correo electrónico para que podamos guardar tu aplicación de boda y enviarte tus datos de acceso.',
  [k.ACCEPT_TERMS]: 'Por favor, acepta las Condiciones del servicio.',
  [k.ENTER_VALID_MAIL]: 'Introduzca una dirección de correo electrónico válida.',
  [k.QUESTION_BRIDE_OR_GROOM]: '¿Es usted el novio o la novia?',
  [k.QUESTION_WEDDING_DATE]: '¿Conoce la fecha de su boda?',
  [k.YES_WE_KNOW]: 'Sí, la sabemos.',
  [k.NO_WE_DONT_KNOW]: 'No, todavía no.',
  [k.MAKE_SELECTION]: 'Por favor, haga una selección.',
  [k.QUESTION_WEDDING_LOCATION]: '¿Tienen un lugar?',
  [k.YES_WE_HAVE]: 'Sí, lo sabemos.',
  [k.ENTER_YOUR_LOCATION]: 'Por favor, introduzca el lugar de su boda.',
  [k.LOCATION_CLASSIC]: 'Clásico',
  [k.LOCATION_COUNTRY]: 'Campestre',
  [k.LOCATION_OUTDOOR]: 'Al aire libre',
  [k.LOCATION_PALACE_HOTEL]: 'Castillo',
  [k.QUESTION_WEDDING_STYLE]: '¿Qué estilo prefiere?',
  [k.HOTEL]: 'Hoteles',
  [k.INFOS]: 'Información',
  [k.LOCATION]: 'Ubicaciones',
  [k.PHOTOS]: 'Fotos',
  [k.NO_MUSIC_SAVED]: 'Aún no hay canciones guardadas...',
  [k.SEARCH_FOR_SONGS]: 'Busca tu canción favorita arriba',
  [k.LISTEN_TO_FULL_LIST]: 'Escucha la lista de reproducción completa de la boda',
  [k.OPEN_IN_SPOTIFY]: 'Abrir en Spotify',
  [k.LISTEN_TO_SINGLE_SONGS]: 'Escuchar canciones individuales de la lista de reproducción',
  [k.NO_RESULT_FOUND]: 'No se han encontrado resultados',
  [k.CANCEL]: 'Cancelar',
  [k.ADD_SONG]: 'Añadir una canción',
  [k.NO_PHOTOS_UPLOADED_YET]: 'Aún no se han subido fotos...',
  [k.ADD_SOME_PHOTOS]: 'Haz clic arriba y haz fotos',
  [k.DROP_PHOTOS_HERE_OR]: 'Guardar fotos aquí o',
  [k.SHOOT_PHOTO]: 'Haz una foto',
  [k.OVERVIEW_OF_ALL_PRESENTS]: 'Ver todos los regalos y entradas',
  [k.LOADING_BACKINGS]: 'Se han cargado entradas...',
  [k.NO_PRESENTS_YET]: 'Aún no has añadido ningún regalo a tu registro de regalos.',
  [k.PRESENT]: 'Añadir un regalo',
  [k.E_MAIL_ADDRESS]: 'Tu dirección de correo electrónico',
  [k.DATE]: 'Fecha del regalo',
  [k.NO_BACKINGS_YET]: 'Aún no hay entradas',
  [k.CLOSE]: 'cerrar',
  [k.ONLY_VISIBLE_FOR_WEDDING_COUPLE]: '(Sólo visible para los novios)',
  [k.SHOW_ALL_ENTRIES]: 'Mostrar todas las entradas',
  [k.EUR]: 'IMPORTE EUR',
  [k.ADD_BACKING]: 'Únete a',
  [k.BUY]: 'Comprar ahora',
  [k.YOUR_BACKING_SAVED]: '¡Ya has iniciado sesión!',
  [k.A_GUEST_ALREADY_BACKED]: 'Un invitado ya se ha registrado.',
  [k.DELETE_MY_BACKING]: 'Borrar mi entrada.',
  [k.OVERVIEW_OF_ALL_RSVPS]: 'Resumen de todas las inscripciones',
  [k.LOADING_RSVPS]: 'Los registros se están cargando...',
  [k.NO_RSVPS_YET]: 'Aún no se han recibido tarjetas de respuesta.',
  [k.RESPONSE]: 'Responder',
  [k.GUESTS_AND_MENU_WISH]: 'Invitados y solicitud de menú',
  [k.QUESTIONS_AND_REMARKS]: 'Pregunta/Comentario',
  [k.WILL_ATTEND]: 'Compromiso',
  [k.WILL_NOT_ATTEND]: 'Cancelación',
  [k.SHOW_ALL_RSVPS]: 'Ver todas las solicitudes',
  [k.YOU_ALREADY_SUBMITTED_ON]: 'Ya ha enviado la respuesta a',
  [k.CANNOT_CHANGE_ANY_MOR]: 'ya ha sido enviada. Los datos no se pueden modificar.',
  [k.MENU_WISH]: 'Menú de solicitudes',
  [k.RSVP_LOADING]: 'La tarjeta de respuesta se está cargando...',
  [k.LIST_EVERYONE_WE_MAY_EXPECT]: 'Por favor, haga una lista de todas las personas que podemos esperar',
  [k.LIST_EVERYONE_WE_MAY_EXPECT_WITH_MENU]:
    'Por favor, haga una lista de todas las personas que podemos esperar y también díganos sus preferencias de menú',
  [k.GUEST]: 'Invitados',
  [k.ADD_GUEST]: 'Añadir otro invitado',
  [k.SUBMIT_RSVP]: 'Enviar tarjeta de respuesta',
  [k.WEBSITE]: 'Página web',
  [k.OUR_PACKAGES]: 'Nuestros paquetes',
  [k.PACKAGE_BASIC]: 'Básico',
  [k.PACKAGE_COMPLETE]: 'Completo',
  [k.PACKAGE_PREMIUM]: 'Premium',
  [k.PACKAGE_VIP]: 'VIP',
  [k.BEST_SELLER]: 'Mejor vendedor',
  [k.SELECTED]: 'Seleccionado',
  [k.SELECT]: 'Seleccione',
  [k.CAN_UPGRADE_LATER]:
    'No se preocupe, siempre puede subir de categoría más adelante simplemente pagando la diferencia.',
  [k.LOOK_IN_FAQ]: '¿Aún tienes preguntas? Consulte nuestras preguntas frecuentes.',
  [k.PACKAGE_FEATURES_DESIGN]: 'Diseños, colores, fuentes',
  [k.PACKAGE_FEATURES_SUBDOMAIN]: 'Su subdominio con contraseña',
  [k.PACKAGE_FEATURES_MOBILE]: 'Optimizado para escritorio y móvil',
  [k.PACKAGE_FEATURES_SCHEDULE]: 'Agenda diaria con horarios',
  [k.PACKAGE_FEATURES_LOCATION]: 'Planificador de ubicaciones e información',
  [k.PACKAGE_FEATURES_INFOS]: 'Páginas de información (FAQ, Regalos, etc.)',
  [k.PACKAGE_FEATURES_RSVP]: 'Registro y gestión de invitados',
  [k.PACKAGE_FEATURES_MUSIC]: 'Peticiones de música por parte de los invitados',
  [k.PACKAGE_FEATURES_PHOTOS]: 'Subida de fotos de los invitados',
  [k.PACKAGE_FEATURES_SUPPORT]: 'Atención al cliente por correo electrónico',
  [k.PACKAGE_FEATURES_MULTILANGUAGE]: 'Sitio web multilingüe',
  [k.PACKAGE_FEATURES_OWN_DOMAIN]: 'Su propio dominio (.de)',
  [k.PACKAGE_FEATURES_CUSTOM_DESIGN]: 'Diseño personalizado por un profesional',
  [k.MAX_FIVE]: 'máx. 5',
  [k.CHECKING_PAYMENT_STATUS]: 'Comprobar el estado del pago',
  [k.PAYMENT_SUCCESSFULL]:
    '¡Gracias! El pago se ha realizado correctamente, ahora puede utilizar su aplicación de boda en el siguiente paquete:',
  [k.PAYMENT_FAILED]:
    'Desafortunadamente, el pago falló. Puede intentarlo de nuevo o enviarnos un correo electrónico a',
  [k.ACTIVATE_PACKAGE_NOW]: 'Desbloquear ahora:',
  [k.PAY]: 'Pagar',
  [k.EDITING_MODE]: 'Modo de edición',
  [k.ACTIVE]: 'está activo',
  [k.NOT_ACTIVE]: 'no está activo',
  [k.ERROR_OCCURED_WITH_MESSAGE]: 'Se ha producido un error. Comentarios técnicos:',
  [k.TRY_AGAIN_PLEASE]: 'Por favor, inténtelo de nuevo. Si el error se repite, puede enviarnos un correo electrónico a',
  [k.PACKAGE_UPGRADE]: 'Actualizar paquete',
  [k.PACKAGE_ALREADY_BOUGHT]: 'Ya ha adquirido un paquete para su boda.',
  [k.UPGRADE_TO_HIGHER_PACKAGE]:
    'Por supuesto, puede actualizar su sitio web a un paquete superior simplemente pagando la diferencia. Envíenos un breve correo electrónico y nos encargaremos de todo.',
  [k.REGISTRATION_NOT_COMPLETE]: 'El registro aún no se ha completado. Haga clic en el enlace que le hemos enviado.',
  [k.YOU_HAVE_AN_EMAIL]: 'Ha recibido un correo electrónico nuestro.',
  [k.YOU_MUST_REGISTER_FIRST]: 'Tiene que completar su registro para poder editar la página web de su boda.',
  [k.CREATOR_MUST_REGISTER]:
    'Es gratis y muy rápido: sólo tienes que hacer clic en el enlace del correo electrónico que te hemos enviado. ¿No encuentra el correo electrónico? No hay problema, haz clic aquí:',
  [k.HERE]: 'Aquí',
  [k.E_MAIL_RESENT]: 'Correo electrónico enviado de nuevo.',
  [k.CONFIRMATION_RESENT_TO]: 'Le hemos vuelto a enviar el enlace de confirmación:',
  [k.CAN_TAKE_SOME_MINUTES]:
    'El correo electrónico puede tardar unos minutos en llegarle. Si sigue teniendo problemas, envíenos un correo electrónico y le ayudaremos:',
  [k.RESENDING_MAIL]: 'El correo electrónico se enviará de nuevo.',
  [k.DEMO_WEDDING_HINT]: 'Sitio de demostración: ¡crea tu propio sitio gratis y pruébalo!',
  [k.CREATE_OWN_WEBSITE]: '¡Cree su propio sitio web!',
  [k.SOME_FEATURES_DEACTIVATED_IN_DEMO]: 'Algunas funciones están desactivadas en el sitio de demostración.',
  [k.CAN_CREATE_OWN_WEDDING_WEBSITE]:
    'Puede registrarse, crear su propio sitio web de bodas y probar todas las funciones de forma gratuita.',
  [k.NO_PACKAGE_BOUGHT_HINT]:
    'Versión básica gratuita - actualiza ahora para obtener fotos ilimitadas, invitados y más funciones.',
  [k.LETS_UPGRADE]: 'Actualicemos.',
  [k.MAX_NUMBER_FOR_BASIC_PACKAGE]: 'Has alcanzado el número máximo de fotos para el paquete básico gratuito.',
  [k.UPGRADE_FOR_UNLIMITED_PHOTOS]:
    'Puedes pasar a uno de nuestros planes Premium para subir un número ilimitado de fotos.',
  [k.UPGRADE_FOR_UNLIMITED_MUSIC]:
    'Puedes actualizar a uno de nuestros planes Premium para añadir un número ilimitado de canciones.',
  [k.CONFIRM_DELETE_TITLE]: 'Confirmar eliminación',
  [k.CONFIRM_DELETE_TEXT]: '¿Estás seguro de que quieres eliminar este elemento?',
  [k.DELETE]: 'Eliminar elemento',
  [k.ADD_ELEMENT]: 'Añadir un nuevo elemento',
  [k.WHICH_ELEMENT_TO_ADD]: '¿Qué tipo de elemento desea añadir?',
  [k.SELECT_ICON_FOR_LOCATION]: 'Seleccione un icono para la ubicación',
  [k.IMAGE_POSITION]: 'Posición de la imagen',
  [k.SELECT_IMAGE]: 'Seleccionar imagen',
  [k.UPLOAD]: 'Cargar imagen',
  [k.SELECTED_IMAGE]: 'Imagen seleccionada',
  [k.NO_IMAGE]: 'Sin imagen',
  [k.OTHER_IMAGE]: 'Otra imagen',
  [k.EDIT_WEDDING_BASE_DATA]: 'Editar detalles de la boda',
  [k.ENTER_NAME_OF_BRIDE]: 'Por favor, introduzca el nombre de la novia.',
  [k.ENTER_NAME_OF_GROOM]: 'Por favor, introduzca el nombre del novio.',
  [k.SELECT_DATE]: 'Seleccione una fecha.',
  [k.HINT_CHANGE_URL]:
    'Nota: Si desea cambiar la dirección en la que estará disponible la página web de su boda, envíenos un correo electrónico:',
  [k.EDIT_COUNTDOWN]: 'Editar cuenta atrás de boda',
  [k.EDIT_INFO_BOX]: 'Editar cuadro de información',
  [k.EDIT_BOX_WITH_LARGE_PHOTO]: 'Editar cuadro con foto grande',
  [k.EDIT_LOCATION]: 'Editar localización',
  [k.EDIT_PLACE]: 'Editar ubicación',
  [k.ADD_LOCATION]: 'Añadir ubicación',
  [k.ADD_PLACE]: 'Añadir ubicación',
  [k.SELECT_SEARCH_RESULT]: 'Seleccione un resultado de búsqueda.',
  [k.EDIT_PAGE_TITLE]: 'Editar título de página',
  [k.EDIT_PRESENTS]: 'Editar lista de regalos',
  [k.PRESENT_INTRO_TEXT]: 'Crea tu propia lista de regalos personalizada para inspirar a tus invitados.',
  [k.ADDITIONAL_ENTRY]: 'Más entradas',
  [k.EDIT_RSVP]: 'Editar RSVP',
  [k.MENU_WISHES_ACTIVATED]:
    '¿Quieres que tus invitados indiquen sus preferencias de menú cuando confirmen su asistencia?',
  [k.EDIT_SCHEDULE_ITEM]: 'Editar menú',
  [k.NAVIGATION]: 'Establecer menú',
  [k.COUPLE]: 'Novios',
  [k.MUSIC]: 'Menú Musical',
  [k.YOU_ARE_LOGGED_IN_AS_GROOM]: 'Usted está registrado como el novio.',
  [k.YOU_ARE_LOGGED_IN_AS_BRIDE]: 'Usted está registrado como la novia.',
  [k.ADDRESS_OF_YOUR_PAGE]: 'Dirección de la página web de tu boda:',
  [k.WEDDING_CODE_FOR_GUESTS]: 'Código de boda para tus invitados:',
  [k.CURRENT_PACKAGE]: 'Su paquete actual:',
  [k.UPGRADE]: 'Actualizar',
  [k.LOGOUT]: 'Cerrar sesión',
  [k.FOR_GUESTS]: 'Para los invitados',
  [k.INVISIBLE]: 'invisible',
  [k.VISIBLE]: 'Visible',
  [k.WEDDING_NOT_FOUND]: 'Página de boda no encontrada.',
  [k.WEDDING_NOT_FOUND_TEXT]:
    'Lo sentimos, no hemos podido encontrar una página de boda en la dirección que has introducido. ¿Es posible que haya escrito mal? Inténtalo de nuevo.',
  [k.IF_ERROR_REPEATS]: 'Si el error se repite, puede enviarnos un correo electrónico a',
  [k.ERROR_GENERAL]: 'Se ha producido un error',
  [k.SORRY_WRITE_MAIL]:
    'Lo sentimos. Inténtelo de nuevo. Si el error se repite, puede enviarnos un correo electrónico y le ayudaremos.',
  [k.MOVE_UP]: 'Subir',
  [k.MOVE_DOWN]: 'Bajar',
  [k.OH_SORRY]: 'Oh cielos... He cometido un error...',
  [k.ERROR_REFRESH_PAGE]:
    'Lo sentimos mucho. Por favor, vuelva a cargar la página e inténtelo de nuevo. Si el error se repite, puede enviarnos un correo electrónico a',
  [k.IF_YOU_WANT_TO_HELP]:
    'Si desea ayudarnos a encontrar y solucionar el error con mayor rapidez, puede copiar el siguiente texto en su correo electrónico:',
  [k.ENTER_DISPLAY_NAME]:
    'Estupendo. Introduce tu nombre o apodo. Aparecerá debajo de las fotos que subas, por ejemplo.',
  [k.ENTER_DISPLAY_NAME_SHORT]: 'Por favor, introduce un nombre.',
  [k.WE_SENT_EMAIL]:
    'Te hemos enviado un correo electrónico. Haz clic en el enlace del correo electrónico para restablecer tu contraseña.',
  [k.WELCOME_BACK]: 'Bienvenido de nuevo al sitio,',
  [k.ALMOST_THERE]: 'Ya casi está,',
  [k.PLEASE_SET_PASSWORD]: 'Por favor, elija una contraseña ahora.',
  [k.PLEASE_SET_PASSWORD_SHORT]: 'Elija una contraseña',
  [k.WELCOME_TO_WEDDING_OF]: 'Bienvenido al sitio de bodas de',
  [k.PLEASE_ENTER_MAIL]: '¡! Por favor, introduzca su dirección de correo electrónico.',
  [k.WELCOME_TO_EHO]:
    '¡Bienvenido a la plataforma de bodas de EureHochzeitOnline.com! Por favor, introduzca su dirección de correo electrónico.',
  [k.TO_WEDDING]: 'Para la boda',
  [k.ENTER_WEDDING_CODE]: '¡! Por favor, introduzca el código de la boda.',
  [k.ENTER_WEDDING_CODE_SHORT]: 'Por favor, introduzca el código de la boda.',
  [k.RESTART]: 'Por favor, reinicie',
  [k.UPLOAD_PREPARING]: 'Carga en preparación...',
  [k.UPLOAD_STARTED]: 'Subida en curso...',
  [k.UPLOAD_FINISHING]: 'Subida completada...',
  [k.UPLOAD_CANCELED]: 'Carga cancelada...',
  [k.INVALID_FILE_TYPE]: 'El tipo de archivo no es válido...',
  [k.MAX_FILE_NUMBER]: 'Se ha superado el número máximo de archivos...',
  [k.FILE_TOO_LARGE]: 'El archivo es demasiado grande...',
  [k.DAYS_TO_GO]: '¡Faltan días!',
  [k.DAY_TO_GO]: '¡Un día más!',
  [k.TODAY]: 'Hoy',
  [k.INVALID_DATE]: 'La fecha no es válida',
  [k.DATE_MUST_BE_IN_FUTURE]: 'La fecha debe ser futura',
  [k.ADDRESS_OR_NAME_OF_LOCATION]: 'Dirección o nombre del lugar',
  [k.ENTER_SONG_WISH]: 'Escriba aquí su canción favorita...',
  [k.NAME_OF_GUEST]: 'Nombre del invitado',
  [k.QUESTION_REMARK_MAX_1000]: 'Preguntas/Comentarios (máx. 1000 caracteres)',
  [k.LEFT]: 'Enlaces',
  [k.TOP]: 'Arriba',
  [k.RIGHT]: 'Derecha',
  [k.BOTTOM]: 'Abajo',
  [k.NAME_OF_BRIDE]: 'Nombre de la novia',
  [k.NAME_OF_GROOM]: 'Nombre del novio',
  [k.TITLE]: 'Título',
  [k.DESCRIPTION]: 'Descripción del evento',
  [k.NAME_OF_PLACE]: 'Nombre del lugar',
  [k.STREET]: 'Calle',
  [k.HOUSENUMBER]: 'No.',
  [k.ZIP]: 'CÓDIGO POSTAL',
  [k.CITY]: 'Ciudad',
  [k.COUNTRY]: 'País/Estado',
  [k.HINTS_FOR_GUESTS]: 'Notas para los invitados',
  [k.TITLE_FOR_THIS_PAGE]: 'Título de esta página',
  [k.SUBTITLE_FOR_THIS_PAGE]: 'Subtítulo de esta página',
  [k.LABEL]: 'Denominación',
  [k.AMAZON_URL]: 'URL de Amazon (opcional)',
  [k.COSTS]: 'Costes',
  [k.NEW_FOOD_OPTION]: 'Nueva opción de menú',
  [k.TIME]: 'Hora',
  [k.SCHEDULE_ITEM]: 'Elemento del programa',
  [k.YOUR_NAME_OR_NICKNAME]: 'Su nombre o apodo',
  [k.YOUR_EMAIL_ADDRESS]: 'Su dirección de correo electrónico',
  [k.YOUR_WEDDING_CODE]: 'Su código de boda',
  [k.COULD_NOT_SAVE_WEDDING]: 'Lo sentimos, pero no hemos podido guardar la boda.',
  [k.ALREADY_HAVE_WEDDING]: 'Ya tienes páginas de boda',
  [k.CREATE_NEW]: 'Crear una nueva',
  [k.COULD_NOT_LOAD_YOUR_DATA]: 'No hemos podido recuperar su información.',
  [k.COULD_NOT_SEND_MAIL]: 'No hemos podido enviar el email.',
  [k.DELETE_IMAGE]: 'Borrar imagen',
  [k.DELETE_IMAGE_ARE_YOU_SURE]: '¿Estás seguro de que quieres borrar esta imagen?',
  [k.COULD_NOT_LOGIN]: 'No hemos podido iniciar sesión.',
  [k.WRONG_PASSWORD_TITLE]: 'Contraseña incorrecta',
  [k.WRONG_PASSWORD_TEXT]: 'La contraseña introducida es incorrecta.',
  [k.WRONG_CODE_TITLE]: 'Código de boda incorrecto',
  [k.WRONG_CODE_TEXT]: 'El código de boda que has introducido es incorrecto.',
  [k.LOADING]: 'Cargando...',
  [k.GUEST_NAME_MISSING]: 'Por favor, introduzca los nombres de los siguientes invitados:',
  [k.GUEST_FOOD_MISSING]: 'Por favor, complete la solicitud de menú para los siguientes invitados:',
  [k.LIMIT_EXCEEDED_PASSWORD_RESET]: 'Ha superado el número máximo de intentos. Puede volver a intentarlo más tarde.',
  [k.EXPIRED_CODE_PASSWORD_RESET]: 'Ya ha utilizado este enlace para restablecer su contraseña.',
  [k.PLEASE_ENTER_NAME]: 'Por favor, introduzca un nombre',
  [k.COUNTDOWN_VISIBLE]: 'Cuenta atrás visible.',
  [k.COUNTDOWN_NOT_VISIBLE]: 'Cuenta atrás no visible.',
  [k.ASK_FOR_MENU_WISHES]: 'Sí, pedir peticiones de menú',
  [k.ASK_NOT_FOR_MENU_WISHES]: 'No, no pedir peticiones de menú',
  [k.SPRING]: 'Primavera',
  [k.SUMMER]: 'Verano',
  [k.AUTUMN]: 'Otoño',
  [k.WINTER]: 'Invierno',
  [k.COULD_NOT_SAVE_PASSWORD]: 'No hemos podido guardar su contraseña.',
  [k.YES_WE_WILL_ATTEND]: 'Sí, asistiremos a su boda',
  [k.NO_WE_WILL_NOT_ATTEND]: 'No, no asistiremos a su boda',
  [k.CONFIRM_DIALOG_TITLE]: '¿Está seguro?',
  [k.ALERT_DIALOG_TITLE]: 'Nota',
  [k.DEFAULT_IMAGES_TYPE_CAKE]: 'Tarta de boda',
  [k.DEFAULT_IMAGES_TYPE_CEREMONY]: 'Ceremonia de boda',
  [k.DEFAULT_IMAGES_TYPE_CHAMPAGNERECEPTION]: 'Recepción y Champán',
  [k.DEFAULT_IMAGES_TYPE_DANCE]: 'Baile y fiesta',
  [k.DEFAULT_IMAGES_TYPE_FOOD]: 'Cena de Boda y Buffet',
  [k.DEFAULT_IMAGES_TYPE_CAR]: 'Coche de boda y desfile',
  [k.DEFAULT_IMAGES_TYPE_GAME]: 'Juegos y entretenimiento',
  [k.DEFAULT_IMAGES_TYPE_DRESSCODE]: 'Vestimenta',
  [k.DEFAULT_IMAGES_TYPE_HOTEL]: 'Hotel y alojamiento',
  [k.DEFAULT_IMAGES_TYPE_PHOTO]: 'Sesión de fotos',
  [k.DEFAULT_IMAGES_TYPE_PRESENT]: 'Regalos y obsequios',
  [k.DEFAULT_IMAGES_TYPE_LOVE]: 'General y amor',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_LABEL]: 'Título de la página',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_TITLE]: 'Título de la página',
  [k.DEFAULT_ELEMENT_TEXT_PAGE_TITLE_SUBTITLE]: 'Bienvenido a esta página',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_LABEL]: 'Tema del programa',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TITLE]: 'Tema del programa',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_TIME]: 'A partir de las 15:00',
  [k.DEFAULT_ELEMENT_TEXT_SCHEDULE_ITEM_DESCRIPTION]: 'Foto de grupo',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_LABEL]: 'Caja de información',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_TITLE]: '🍰 Tarta',
  [k.DEFAULT_ELEMENT_TEXT_INFO_ITEM_DESCRIPTION]:
    ' Si desea preparar un pastel, por favor háganoslo saber. Estamos deseando reunir una colorida selección.',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_LABEL]: 'Caja con foto grande',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_TITLE]: 'Su introducción',
  [k.DEFAULT_ELEMENT_TEXT_LARGE_PHOTO_ITEM_DESCRIPTION]:
    'Aquí puedes presentarte a tus invitados. Cuéntales cómo os conocisteis...',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_MAP_LABEL]: 'Mapa de localización',
  [k.DEFAULT_ELEMENT_TEXT_LOCATION_ITEM_LABEL]: 'Lugar de la boda',

  [k.DEFAULT_STYLES_MODERN_TITLE]: `Moderno`,
  [k.DEFAULT_STYLES_MODERN_DESCRIPTION]: `Deja que tu amor brille en un ambiente que capta el pulso de los tiempos.`,
  [k.DEFAULT_STYLES_KLASSISCH_TITLE]: `Clásico`,
  [k.DEFAULT_STYLES_KLASSISCH_DESCRIPTION]: `Líneas limpias, colores armónicos y un toque de opulencia crean un ambiente que honra tu amor.`,
  [k.DEFAULT_STYLES_VINTAGE_TITLE]: `Vintage`,
  [k.DEFAULT_STYLES_VINTAGE_DESCRIPTION]: `El estilo vintage trae de vuelta el encanto de épocas pasadas, con un toque de nostalgia y elegancia.`,
  [k.DEFAULT_STYLES_FLOWER_TITLE]: `Floral`,
  [k.DEFAULT_STYLES_FLOWER_DESCRIPTION]: `Este estilo de boda celebra la belleza natural de las flores en su máximo esplendor.`,
  [k.DEFAULT_STYLES_BOHO_TITLE]: `Boho`,
  [k.DEFAULT_STYLES_BOHO_DESCRIPTION]: `Una atmósfera relajada y no convencional que combina naturaleza y creatividad – perfecta para espíritus libres.`,
  [k.DEFAULT_STYLES_BLOSSOM_TITLE]: `Florecer`,
  [k.DEFAULT_STYLES_BLOSSOM_DESCRIPTION]: `Un estilo romántico que destaca la delicada belleza de las flores y los elementos florales.`,
  [k.NOT_SURE_ABOUT_DESIGN]: `¿No estás seguro? No te preocupes, puedes cambiar el estilo más tarde sin perder los datos.`,
  [k.OWN_PHOTOS_AFTER_REGISTRATION]: `Podrás subir tus propias fotos y logotipos tras registrarte gratis.`,
  [k.YOUR_NAMES]: `Sus nombres`,
  [k.YOUR_WEDDING_DATE]: `La fecha de su boda`,
  [k.WEDDING_DATE_FIX]: `La fecha de la boda ya está fijada`,
  [k.WEDDING_DATE_NOT_FIX]: `La fecha de la boda no está fijada`,
  [k.YOUR_WEDDING_STYLE]: `El estilo de su sitio web`,
  [k.OWN_LOGO]: `Logotipo propio`,
  [k.START_PHOTO]: `Foto de la página de inicio`,
  [k.USE_START_PHOTO]: `¿Usar una foto propia?`,
  [k.WITH_START_PHOTO]: `Con foto propia`,
  [k.WITHOUT_START_PHOTO]: `Sin foto propia`,
  [k.ALL_STYLES]: `Todos los estilos`,

  [k.COOKIES_01]: '¿Algunas galletas de boda para ti?',
  [k.COOKIES_02]:
    "Al hacer clic en 'Confirmar todo' aceptará todas las cookies. También puede hacer una selección personalizada. Puede revocar su selección en cualquier momento a través de la 'Configuración de cookies'.",
  [k.COOKIES_03]: 'Cookies necesarias',
  [k.COOKIES_04]: 'Estas cookies son necesarias para el funcionamiento del sitio, por lo que no puede desactivarlas.',
  [k.COOKIES_05]: 'Más información.',
  [k.COOKIES_06]: 'Cookies de marketing y personalización',
  [k.COOKIES_07]:
    'Estas cookies le permiten recibir contenido del sitio web adaptado a su comportamiento de usuario y nos permiten adaptar nuestro sitio web a los intereses de nuestros clientes en función de las estadísticas de uso.',
  [k.COOKIES_08]: 'Aceptar selección',
  [k.COOKIES_09]: 'Aceptar todo',
  [k.TERMS_AND_CONDITIONS_00]: 'Condiciones de uso',
  [k.TERMS_AND_CONDITIONS_01]:
    "fidira Software UG (haftungsbeschränkt), en lo sucesivo también 'proveedor', 'nosotros' o 'EureHochzeitOnline.com', ofrece una plataforma para la creación de aplicaciones de boda individuales. Con estas aplicaciones, los usuarios (en adelante denominados colectivamente 'Usuarios') como anfitriones pueden crear una aplicación para su boda y ofrecer a sus invitados información sobre la boda y oportunidades de interacción a través de esta aplicación. Los siguientes términos y condiciones se aplican al uso de la plataforma.",
  [k.TERMS_AND_CONDITIONS_02]: '§ 1 Uso de la Plataforma',
  [k.TERMS_AND_CONDITIONS_03]:
    'El uso de la Plataforma es generalmente gratuito en una versión de prueba. Si la plataforma se utiliza para una boda con un número determinado de invitados o superior, deberá abonarse una tarifa única. Tanto la versión de prueba como la versión completa requieren el registro previo y la creación de una cuenta de usuario. El usuario no puede permitir que terceros utilicen su cuenta. El usuario está obligado a mantener en secreto sus datos de acceso y a protegerlos del acceso de terceros.',
  [k.TERMS_AND_CONDITIONS_04]:
    'La Wedding App estará disponible durante seis meses después de la boda. Después, desactivaremos la Wedding App para poder ofrecer la dirección deseada a otras parejas. Si necesitas mantener tu aplicación de boda en línea durante más tiempo, podemos llegar a un acuerdo especial.',
  [k.TERMS_AND_CONDITIONS_05]:
    'Una vez creada una Wedding App, sólo podrá utilizarse para una boda. Si, por ejemplo, descubrimos que un usuario ha cedido o vendido una aplicación de boda a otras parejas, nos reservamos el derecho a impedir dicho uso y a cobrar la tarifa correspondiente a otras bodas.',
  [k.TERMS_AND_CONDITIONS_06]: '§ 2 Protección de datos',
  [k.TERMS_AND_CONDITIONS_07]: 'Al utilizar el software, los usuarios aceptan la',
  [k.TERMS_AND_CONDITIONS_08]: 'protección de datos',
  [k.TERMS_AND_CONDITIONS_09]: 'de datos',
  [k.TERMS_AND_CONDITIONS_10]: '§ 3 Funciones y servicios de EureHochzeitOnline.com',
  [k.TERMS_AND_CONDITIONS_11]:
    'Las aplicaciones de boda individuales que se pueden crear a través de EureHochzeitOnline.com ofrecen las siguientes funciones',
  [k.TERMS_AND_CONDITIONS_12]: 'Personalización:',
  [k.TERMS_AND_CONDITIONS_13]:
    'El anfitrión puede configurar y diseñar ciertos aspectos de la aplicación de boda, por ejemplo, para que coincida con el diseño gráfico de las invitaciones de boda, puede elegir fondos, imágenes, colores y fuentes.',
  [k.TERMS_AND_CONDITIONS_14]: 'Galería de fotos:',
  [k.TERMS_AND_CONDITIONS_15]:
    'Los invitados pueden hacer y subir fotos antes o durante la boda. Estas fotos pueden mostrarse en una galería de imágenes en directo que se actualiza automáticamente.',
  [k.TERMS_AND_CONDITIONS_16]: 'Peticiones de música:',
  [k.TERMS_AND_CONDITIONS_17]:
    'Los invitados pueden utilizar una función de búsqueda para solicitar canciones para la boda y puntuar las peticiones. El anfitrión puede utilizar esta función para seleccionar las canciones.',
  [k.TERMS_AND_CONDITIONS_18]: 'Información sobre la boda:',
  [k.TERMS_AND_CONDITIONS_19]:
    'El anfitrión puede publicar el lugar de celebración con mapas interactivos, el horario y otra información para sus invitados en su app de boda.',
  [k.TERMS_AND_CONDITIONS_20]:
    'EureHochzeitOnline.com se reserva el derecho a realizar cambios en el alcance y el diseño de las funciones del software en cualquier momento.',
  [k.TERMS_AND_CONDITIONS_21]:
    'Para utilizar las funciones se requiere un smartphone, una tableta o un PC modernos con un navegador de Internet moderno. En particular, las funciones en directo como la subida de fotos, los concursos de bodas o las solicitudes de música requieren una conexión a Internet estable y rápida. EureHochzeitOnline.com no asume ninguna responsabilidad por la exactitud, integridad, fiabilidad, actualidad y utilidad de los contenidos ofrecidos.',
  [k.TERMS_AND_CONDITIONS_22]:
    'Durante los trabajos de mantenimiento, que EureHochzeitOnline.com anunciará a los anfitriones, la plataforma puede no estar disponible temporalmente. Hacemos todo lo posible para mantener nuestro servicio disponible y estamos orgullosos de decir que nunca hemos decepcionado a nuestros usuarios. Sin embargo, no podemos asumir más obligaciones, en particular el usuario no tiene derecho a una disponibilidad constante del servicio.',
  [k.TERMS_AND_CONDITIONS_23]: '§ 4 Obligaciones del usuario',
  [k.TERMS_AND_CONDITIONS_24]:
    'El usuario puede guardar y publicar sus propios contenidos y archivos en la plataforma EureHochzeitOnline.com como invitado o como anfitrión. El usuario es responsable de estos contenidos. El usuario se compromete a no publicar artículos, archivos, información sobre bodas u otros contenidos que atenten contra las buenas costumbres o la legislación aplicable. En particular, el usuario se compromete a no publicar ningún artículo, archivo, información sobre bodas u otro contenido',
  [k.TERMS_AND_CONDITIONS_25]: 'cuya publicación constituya una infracción penal o administrativa,',
  [k.TERMS_AND_CONDITIONS_26]: 'que infrinjan la legislación sobre derechos de autor, marcas o competencia',
  [k.TERMS_AND_CONDITIONS_27]: 'que infrinjan la Ley de Servicios Jurídicos',
  [k.TERMS_AND_CONDITIONS_28]: 'o que contengan contenidos ofensivos, racistas, discriminatorios o pornográficos',
  [k.TERMS_AND_CONDITIONS_29]: 'que contengan publicidad.',
  [k.TERMS_AND_CONDITIONS_30]:
    'EureHochzeitOnline.com tiene derecho a modificar o eliminar cualquier contenido que viole o sea sospechoso de violar estas obligaciones. EureHochzeitOnline.com también tiene derecho a bloquear el acceso del usuario y la aplicación de bodas creada si el usuario infringe las condiciones de uso. El usuario deberá indemnizar al proveedor por cualquier daño causado por el incumplimiento de sus obligaciones. El proveedor tiene derecho a reclamar al usuario una indemnización por las reclamaciones presentadas por terceros debido a la violación de un derecho por parte del usuario. El usuario está obligado a apoyar al proveedor en la defensa de dichas reclamaciones. El usuario también está obligado a asumir los costes de una defensa legal adecuada del proveedor.',
  [k.TERMS_AND_CONDITIONS_31]: '§ 5 Propiedad intelectual',
  [k.TERMS_AND_CONDITIONS_32]: 'Su propiedad intelectual',
  [k.TERMS_AND_CONDITIONS_33]:
    'El usuario es el propietario de los derechos de propiedad intelectual de los contenidos, textos, imágenes y otros materiales que publique. EureHochzeitOnline.com no reclama ningún derecho de propiedad intelectual sobre dichos contenidos. Sin embargo, usted autoriza a EureHochzeitOnline.com a acceder, copiar y distribuir este contenido con el fin de proporcionarlo a los invitados a la boda. Usted también nos autoriza a realizar cualquier otro acto necesario para proporcionar nuestros servicios, incluyendo pero no limitado a hacer copias de seguridad y proporcionar acceso al personal de apoyo.',
  [k.TERMS_AND_CONDITIONS_34]: 'Nuestra propiedad intelectual',
  [k.TERMS_AND_CONDITIONS_35]:
    'El Proveedor no concede ninguna licencia ni ningún otro derecho de uso de los derechos de propiedad intelectual del sitio web EureHochzeitOnline.com ni de la plataforma de software fuera de una aplicación para bodas creada regularmente y proporcionada de conformidad con las Condiciones de uso. La duplicación, distribución, reproducción, transmisión u otro uso de los derechos de propiedad intelectual, incluida su puesta a disposición de terceros, no está permitida sin el consentimiento previo por escrito del Proveedor.',
  [k.TERMS_AND_CONDITIONS_36]: '§ 6 Derecho de rescisión',
  [k.TERMS_AND_CONDITIONS_37]:
    'Tiene derecho a desistir del presente contrato en un plazo de catorce días sin necesidad de justificación. El plazo de desistimiento es de catorce días a partir de la celebración del contrato. Puede ejercer su derecho de desistimiento enviando un correo electrónico a',
  [k.TERMS_AND_CONDITIONS_38]:
    'con una declaración clara de su decisión de desistir del contrato. También puede utilizar el siguiente modelo de formulario en su correo electrónico:',
  [k.TERMS_AND_CONDITIONS_39]: 'Modelo de formulario de desistimiento',
  [k.TERMS_AND_CONDITIONS_40]:
    '- A fidira Software UG (haftungsbeschränkt), correo electrónico: beeni@eurehochzeitonline.com:',
  [k.TERMS_AND_CONDITIONS_41]:
    '- Yo/nosotros ( * ) por la presente revoco/revocamos el contrato celebrado por mí/nosotros ( * ) para la activación de nuestra aplicación de bodas ( * )',
  [k.TERMS_AND_CONDITIONS_42]: '- Pedido el ( * ) / Recibido el ( * )',
  [k.TERMS_AND_CONDITIONS_43]: '- Nombre del consumidor',
  [k.TERMS_AND_CONDITIONS_44]: '- Dirección del (de los) consumidor(es)',
  [k.TERMS_AND_CONDITIONS_45]: '- Firma del (de los) consumidor(es) (sólo para la notificación en papel)',
  [k.TERMS_AND_CONDITIONS_46]: '- Fecha',
  [k.TERMS_AND_CONDITIONS_47]: '(*) Táchese lo que no procedaEn orden',
  [k.TERMS_AND_CONDITIONS_48]:
    'cumplir el plazo de desistimiento, basta con enviar la notificación del ejercicio del derecho de desistimiento antes de que finalice el plazo de desistimiento.Consecuencias de la revocación:',
  [k.TERMS_AND_CONDITIONS_49]: 'Consecuencias de la revocación:',
  [k.TERMS_AND_CONDITIONS_50]:
    'Si revocas tu contrato, borraremos tu wedding app y, si ya hemos recibido pagos tuyos, te los reembolsaremos utilizando el mismo método de pago. Este reembolso será gratuito.',
  [k.TERMS_AND_CONDITIONS_51]:
    'En el caso de un contrato para la entrega de contenidos digitales que no se encuentren en un soporte físico de datos, como por ejemplo una aplicación de boda, el derecho de revocación conforme al artículo 356 (5) del Código Civil alemán (BGB) también expirará si el proveedor ha comenzado a ejecutar el contrato después de que el usuario 1. haya consentido expresamente que el proveedor comience a ejecutar el contrato antes de que expire el plazo de revocación y 2. haya confirmado que es consciente de que perderá su derecho de revocación al aceptar el comienzo de la ejecución del contrato.',
  [k.TERMS_AND_CONDITIONS_52]: '§ 7 Garantía y exención de responsabilidad',
  [k.TERMS_AND_CONDITIONS_53]:
    "El Proveedor presta sus servicios 'tal cual', 'con todos los fallos' y 'según disponibilidad' y no ofrece garantías expresas o implícitas de comerciabilidad, idoneidad para un fin determinado, rendimiento profesional, no infracción o cualquier otra garantía en la medida permitida por la ley. Tampoco se garantiza que los servicios del Proveedor (o cualquier parte, característica o contenido de los mismos) sean (o vayan a ser) completos, de una calidad determinada, fiables, seguros, precisos, compatibles con las actividades que usted pretende realizar, los dispositivos utilizados, los sistemas operativos, los navegadores o los programas (de huéspedes o anfitriones), o que cumplan con cualquier requisito legal aplicable a usted, o que los servicios estén libres de virus, errores u otros componentes dañinos o limitaciones del programa. Además, EureHochzeitOnline.com no asume responsabilidad alguna por ninguna empresa, producto o servicio (incluidos los servicios de terceros) mencionados u ofrecidos en la plataforma EureHochzeitOnline.com. No se asume ninguna responsabilidad por la pérdida de datos e información, y el proveedor no garantiza la reconstrucción de dichos datos e información. El Usuario realizará copias de seguridad de sus datos e información por su cuenta y riesgo. EureHochzeitOnline.com tampoco asume ninguna responsabilidad por los contenidos publicados por los usuarios ni por la correcta identidad de los usuarios.",
  [k.TERMS_AND_CONDITIONS_54]: '§ 8 Cláusula de Divisibilidad',
  [k.TERMS_AND_CONDITIONS_55]:
    'Si alguna de las disposiciones del presente Acuerdo se considera inválida o inaplicable por cualquier motivo o en cualquier medida, dicha invalidez o inaplicabilidad no afectará a las restantes disposiciones del Acuerdo, y la aplicación de dicha disposición se llevará a cabo en la máxima medida permitida por la ley. En caso de que EureHochzeitOnline.com renuncie a su derecho a exigir el cumplimiento del incumplimiento de una o varias de las condiciones, ello no significará que EureHochzeitOnline.com renuncie también a su derecho a exigir el cumplimiento de las demás condiciones.',
  [k.TERMS_AND_CONDITIONS_56]: '§ 9 Disposiciones finales',
  [k.TERMS_AND_CONDITIONS_57]:
    'Salvo disposición legal en contrario, los acuerdos se regirán por las leyes de la República Federal de Alemania. El lugar exclusivo de jurisdicción para todas las disputas/reclamaciones que surjan de o en relación con estos Términos de Uso será Munich. Nos reservamos el derecho a modificar estas Condiciones de uso en el futuro y a publicar la nueva versión en el mismo lugar. Tras una modificación de las Condiciones de uso, el usuario deberá confirmar la nueva versión.',
  [k.TERMS_AND_CONDITIONS_58]: 'Estado: 01.04.2018',
  [k.IMPRINT_00]: 'Pie de imprenta/Aviso legal',
  [k.IMPRINT_01]: 'fidira Software UG (responsabilidad limitada)',
  [k.IMPRINT_02]: 'EureHochzeitOnline.de es una marca comercial de fidira Software UG',
  [k.IMPRINT_03]: 'Enzianstr. 31',
  [k.IMPRINT_04]: '86343 Königsbrunn',
  [k.IMPRINT_05]: 'E-Mail:',
  [k.IMPRINT_06]: 'Web:',
  [k.IMPRINT_07]: 'https://www.eurehochzeitonline.com',
  [k.IMPRINT_08]: 'Domicilio social de la empresa: Múnich, Registro Mercantil de Múnich HRB',
  [k.IMPRINT_09]: 'Representantes autorizados:',
  [k.PRIVACY_00]: 'Política de privacidad',
  [k.PRIVACY_01]: 'Introducción e información general',
  [k.PRIVACY_02]:
    'Para EureHochzeitOnline.com la protección de su privacidad y la de los invitados a su boda es una prioridad absoluta.',
  [k.PRIVACY_03]:
    'De acuerdo con los requisitos de la Ley Federal Alemana de Protección de Datos (BDSG) y la Ley Alemana de Telemedia (TMG), nos aseguramos en todo momento de que sus datos sólo se utilicen para la creación y el uso de su aplicación de boda personal y para optimizar la experiencia de usuario de su aplicación de boda. Utilizamos la tecnología TLS/SSL (Transport Layer Security / Secure Sockets Layer) tanto para la creación como para el uso de las aplicaciones para bodas, de modo que los datos siempre están encriptados durante la transmisión. Todos nuestros servidores de aplicaciones están ubicados en la Unión Europea y cumplen la normativa de la UE sobre privacidad de datos. No procesaremos su información más allá de los fines descritos en esta Política de Privacidad.Durante el proceso de registro, se le pedirá su consentimiento para el procesamiento descrito en esta Política de Privacidad haciendo clic en la casilla de verificación. Al dar su consentimiento, confirma que podemos recopilar, procesar y utilizar sus datos personales en la medida descrita a continuación. Puede retirar su consentimiento en cualquier momento. Para ello, sólo tiene que enviar un correo electrónico a nuestro servicio de atención al cliente en la dirección',
  [k.PRIVACY_04]:
    'Durante el registro, se le pedirá su consentimiento para las operaciones de procesamiento descritas en la política de privacidad, que usted otorga al hacer clic en la casilla de verificación. Al dar su consentimiento, confirma que podemos recopilar, procesar y utilizar sus datos personales en la medida que se describe a continuación. También puede retirar su consentimiento en cualquier momento. Para hacerlo, simplemente envíe un correo electrónico a nuestro servicio de atención al cliente en',
  [k.PRIVACY_05]:
    '. Si lo solicita, nuestro servicio de atención al cliente también le proporcionará información sobre todos los datos almacenados sobre usted (de conformidad con el artículo 13 (7) de la Ley alemana de Telemedios (Telemediengesetz)) y eliminará estos datos a petición suya.',
  [k.PRIVACY_06]: '§ 1 Entidad responsable y ámbito de aplicación',
  [k.PRIVACY_07]:
    "La entidad responsable es fidira Software UG (haftungsbeschränkt), en lo sucesivo también denominada 'proveedor', 'nosotros' o 'EureHochzeitOnline.com'. Los detalles sobre la dirección y los representantes autorizados se pueden encontrar en el pie de imprenta.",
  [k.PRIVACY_08]:
    'Esta política de privacidad cubre la recopilación y el uso de datos personales al visitar y utilizar cualquiera de las plataformas del proveedor a través de navegadores de Internet. A través de estas ofertas, el Proveedor hace posible la creación de una aplicación de boda individual con la que los invitados pueden informarse sobre la boda e interactuar entre sí durante la misma (por ejemplo, compartir fotos o responder a preguntas). El Proveedor también utilizará los datos personales con fines estadísticos y de análisis de mercado. Para ello, recopilamos estadísticas anónimas, por ejemplo, sobre bodas, ubicaciones, tamaños de las bodas o uso de la aplicación, con el fin de seguir optimizando nuestra oferta.',
  [k.PRIVACY_09]: '§ 2 Enlaces a otros sitios web',
  [k.PRIVACY_10]:
    'Nuestro sitio web puede contener enlaces a otros sitios web. No tenemos ningún control sobre el contenido al que se le dirige después de hacer clic en estos enlaces, ni sobre el tratamiento de sus datos que puedan ser transmitidos a terceros (por ejemplo, su dirección IP). Por lo tanto, no podemos asumir ninguna responsabilidad por el contenido de terceros ni por el tratamiento de dichos datos por terceros.',
  [k.PRIVACY_11]: '§ 3 Uso de datos sin registro',
  [k.PRIVACY_12]:
    'Cuando visita el sitio web o la aplicación de EureHochzeitOnline.com, guardamos automáticamente cierta información básica sobre su visita: en particular, la dirección IP, las páginas visitadas y la duración de la visita. Utilizamos esta información para elaborar estadísticas anónimas sobre el comportamiento de los usuarios y las funciones más utilizadas, con el fin de mejorar continuamente nuestra oferta. Su dirección IP sólo se almacena en archivos de registro técnicos durante un período de tiempo limitado con el fin de proteger nuestros sistemas y las aplicaciones de boda que creamos contra el abuso y el fraude.',
  [k.PRIVACY_13]: '§ 4 Uso de los datos en el momento del registro',
  [k.PRIVACY_14]:
    'Para acceder a una aplicación de boda como invitado o para crear una aplicación de boda como anfitrión, el usuario debe registrarse. Durante el proceso de registro, se almacenará la dirección de correo electrónico del usuario, un nombre para mostrar (de libre elección, también puede ser un seudónimo inventado), un código de boda (si procede) y una contraseña (de libre definición). Esta información se utiliza para proteger los listados de bodas de nuestros anfitriones frente a accesos no autorizados. Al crear una aplicación de boda, los anfitriones pueden introducir información sobre su boda (por ejemplo, lugar, hora, horario) que sólo será visible para los invitados de esa boda. Para ello, el anfitrión genera códigos de boda que los invitados deben introducir para acceder a la aplicación de boda.',
  [k.PRIVACY_15]:
    'También utilizamos su dirección de correo electrónico con fines de comunicación: por ejemplo, es posible que se envíen notificaciones por correo electrónico al anfitrión en relación con nuevas inscripciones, otras noticias sobre la boda, y que se envíen correos electrónicos de servicios de EureHochzeitOnline.com a la dirección de correo electrónico (por ejemplo, encuestas de satisfacción). También mostramos las direcciones de correo electrónico de los invitados a la boda al anfitrión para que pueda utilizarlas en la organización posterior de la boda.',
  [k.PRIVACY_16]: 'Su dirección de correo electrónico nunca será compartida con terceros u otras empresas.',
  [k.PRIVACY_17]: '§ 5 Cookies',
  [k.PRIVACY_18]:
    "Con el fin de proporcionarle la mejor experiencia de usuario, utilizamos 'cookies' y el llamado 'almacenamiento local' de su navegador. Esto nos permite almacenar pequeñas cantidades de datos sobre su Wedding App en su navegador para que, por ejemplo, sus cambios no se pierdan cuando cierre su navegador, cambie al modo de pantalla completa del App Configurator, o para que no tenga que iniciar sesión cada vez. Si no desea utilizar cookies o desea eliminar las existentes, puede desactivarlas o eliminarlas a través de su navegador. Consulte las instrucciones de su navegador sobre cómo hacerlo o póngase en contacto con nosotros para obtener ayuda.",
  [k.PRIVACY_19]:
    "También utilizamos cookies para los servicios de terceros que se enumeran a continuación en '§ 7 Aplicaciones y sitios web' para ofrecerle una mejor experiencia de usuario.",
  [k.PRIVACY_20]: '§ 6 Aplicaciones y sitios web',
  [k.PRIVACY_21]: 'Google Maps',
  [k.PRIVACY_22]:
    'Utilizamos Google Maps de Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, EE.UU. para mostrar mapas interactivos. Su dirección IP, su ubicación y la información sobre su uso de nuestros servicios serán transmitidas y almacenadas por Google en servidores de Estados Unidos. Las condiciones de uso de Google Maps pueden consultarse en ',
  [k.PRIVACY_23]: 'https://www.google.com/intl/de_de/help/terms_maps.html',
  [k.PRIVACY_24]: 'HotJar',
  [k.PRIVACY_25]:
    "También utilizamos HotJar, una herramienta de análisis web de Hotjar Ltd., para recopilar y almacenar datos con fines de marketing y optimización. Los datos pueden utilizarse para crear perfiles de usuario seudónimos. Se registran visitas individuales seleccionadas aleatoriamente (sin información personal adicional, los denominados 'atributos de usuario'). De este modo se crea un registro de movimientos del ratón y clics con la intención de reproducir aleatoriamente visitas individuales al sitio web y deducir posibles mejoras para el sitio web. Los datos recogidos no se utilizarán para identificar personalmente al visitante de este sitio web, ni se fusionarán con datos personales sobre el portador del seudónimo, sin el consentimiento por separado de la persona en cuestión.Si no desea ser rastreado, puede desactivar globalmente el rastreo en todos los sitios web que utilizan HotJar para el navegador que está utilizando actualmente haciendo clic en el siguiente enlace",
  [k.PRIVACY_26]: 'https://www.hotjar.com/privacy/do-not-track/',
  [k.PRIVACY_27]: 'Mailchimp',
  [k.PRIVACY_28]:
    'Utilizamos Mailchimp de The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE Suite 5000, Atlanta, GA 30308 USA (Mailchimp) para enviar nuestro boletín de noticias. Esto nos permite contactar directamente con los suscriptores. Además, analizamos su comportamiento de uso para optimizar nuestras ofertas.',
  [k.PRIVACY_29]: 'Para ello, compartimos la siguiente información personal con Mailchimp',
  [k.PRIVACY_30]:
    'Dirección de correo electrónico [Nombre] [Fecha de la boda] [Información de la boda] [Datos de acceso].',
  [k.PRIVACY_31]:
    'Nuestros correos electrónicos incluirán un enlace para que actualices tu información personal. Mailchimp es el destinatario de sus datos personales y actúa como procesador en nuestro nombre en lo que respecta a la distribución de nuestro boletín informativo. El tratamiento de los datos descritos en esta sección no es obligatorio por ley ni por contrato. Sin su consentimiento y el envío de sus datos personales, no podemos enviarle un boletín informativo. Además, Mailchimp recopila la siguiente información personal utilizando cookies y otros métodos de seguimiento Información sobre su dispositivo (dirección IP, información del dispositivo, sistema operativo, ID del navegador, información sobre la aplicación que utiliza para leer su correo electrónico y otra información sobre su hardware y conexión a Internet. Además, se recopilan datos de uso como la fecha y hora en que abrió el correo electrónico/campaña y la actividad del navegador (por ejemplo, qué correos electrónicos/páginas web se abrieron). Mailchimp necesita esta información para garantizar la seguridad y fiabilidad de los sistemas, para hacer cumplir las Condiciones del servicio y para evitar usos indebidos. Esto corresponde al interés legítimo de Mailchimp (de conformidad con el Art. 6 párrafo 1 lit. f GDPR) y sirve para la ejecución del contrato (de conformidad con el Art. 6 párrafo 1 lit. b GDPR). Mailchimp también analiza los datos de rendimiento, como las estadísticas de entrega de correos electrónicos y otros datos de comunicación. Esta información se utiliza para recopilar estadísticas de uso y rendimiento de los Servicios. Mailchimp también recopila información sobre ti de otras fuentes. De vez en cuando y a escala indeterminada, se recopila información personal a través de las redes sociales y otros proveedores de datos de terceros. No tenemos ningún control sobre estos procesos. ',
  [k.PRIVACY_32]: 'Para obtener más información sobre sus opciones y opciones de eliminación con Mailchimp, visite',
  [k.PRIVACY_33]: 'https://mailchimp.com/legal/privacy/#3._Privacy_for_Contacts',
  [k.PRIVACY_34]:
    'base legal para este procesamiento es su consentimiento de conformidad con el Art.6 para.1 lit. a GDPR.Usted puede retirar su consentimiento para el procesamiento de sus datos personales en cualquier momento.Encontrará un enlace para hacerlo en todos los correos.También puede revocar su consentimiento utilizando las opciones de contacto proporcionadas. La revocación no afectará a la legalidad del tratamiento ya efectuado.',
  [k.PRIVACY_35]:
    'Sus datos serán tratados mientras haya dado su consentimiento. De lo contrario, se eliminarán una vez finalizado el contrato entre nosotros y Mailchimp, a menos que los requisitos legales hagan necesario un almacenamiento adicional.',
  [k.PRIVACY_36]:
    'Mailchimp ha implementado medidas de cumplimiento para las transferencias internacionales de datos. Estas medidas se aplican a todas las operaciones globales en las que Mailchimp procesa datos personales de individuos en la UE. Estas políticas se basan en las Cláusulas Contractuales Tipo (CCT) de la UE. Más información en',
  [k.PRIVACY_37]: 'https://mailchimp.com/legal/data-processing-addendum/',
  [k.PRIVACY_38]: 'Spotify',
  [k.PRIVACY_39]:
    'También utilizamos el Widget Spotify Music Player, un reproductor de música de Spotify AB, para permitirle escuchar la lista de reproducción de la boda. El proveedor del servicio Spotify Music Player Widget es Spotify AB, Regeringsgatan 19, 111 53 Estocolmo, Suecia. Encontrará más información sobre el tratamiento de datos por parte de Spotify AB en',
  [k.PRIVACY_40]: 'https://www.spotify.com/de/legal/privacy-policy/',
  [k.PRIVACY_41]: 'Píxel de Facebook',
  [k.PRIVACY_42]:
    "Con el fin de mejorar EureHochzeitOnline.com y darlo a conocer a un mayor grupo de usuarios, utilizamos el píxel de Facebook de Facebook Inc, 1601 South California Avenue, Palo Alto, CA 94304, EE.UU. ('Facebook'). Para ello, Facebook coloca una cookie en su navegador. La información generada por esta cookie sobre su uso de este sitio web será transmitida y almacenada por Facebook en servidores de Estados Unidos. Facebook puede utilizar esta transferencia de datos para determinar que se accedió a una página concreta desde su navegador y si se hizo haciendo clic en un anuncio. Sin embargo, no utilizamos estos datos para recopilar información de nuestro sitio. Facebook utiliza esta información para ayudarnos a crear anuncios adicionales y para promocionar EureHochzeitOnline.com. Evaluamos los datos obtenidos del píxel de Facebook en la herramienta de análisis de Facebook 'Facebook Analytics' para seguir mejorando EureHochzeitOnline.com. Facebook puede transferir esta información a terceros cuando así lo exija la ley, o cuando dichos terceros procesen la información en nombre de Facebook. Al utilizar EureHochzeitOnline.com, consientes el tratamiento de tus datos por parte de Facebook en la forma descrita y con el fin de mejorar EureHochzeitOnline.com. No compartimos ningún dato personal, de contacto o de tu boda con Facebook. Puedes obtener más información sobre las políticas de seguridad y privacidad del píxel de Facebook en",
  [k.PRIVACY_43]: 'https://www.facebook.com/policy.php',
  [k.PRIVACY_44]:
    'También puedes desactivar la recopilación de información por parte de Facebook Pixel haciendo clic aquí:',
  [k.PRIVACY_45]: 'Desactivar Pixel',
  [k.PRIVACY_46]: '§ 7 Derecho a la información y otros derechos según la BDSG',
  [k.PRIVACY_47]:
    'Estaremos encantados de proporcionarle información sobre sus datos personales almacenados por nosotros en cualquier momento. A petición suya, también corregiremos, bloquearemos o eliminaremos sus datos. Puede ponerse en contacto con nosotros en cualquier momento con estas solicitudes o con declaraciones de revocación en',
  [k.PRIVACY_48]: 'y realizaremos los cambios lo antes posible. ',
  [k.PRIVACY_49]: '§ 8 Modificaciones de la declaración de protección de datos',
  [k.PRIVACY_50]:
    'Internet y las posibilidades técnicas evolucionan constantemente. No hace falta decir que utilizaremos estas nuevas posibilidades para hacer que las bodas de nuestros usuarios sean aún más inolvidables.Esto puede llevarnos a actualizar y modificar nuestra Política de Privacidad.Publicaremos dichas actualizaciones aquí.',
  [k.PRIVACY_51]: 'Última actualización: 11/28/2022',
  [k.IF_YOU_NEED_MORE_LANGUAGES]: 'Si necesita más idiomas, por favor escríbanos un correo electrónico.',
  [k.DECIDE_LANGUAGES]:
    'Decida qué idiomas debería admitir el sitio web de su boda. Puede introducir sus textos para todos los idiomas seleccionados.',
  [k.EDIT_LANGUAGES]: `Editar idiomas`,
  [k.ACTIVE_VOUCHER]: `Bono activo:`,
  [k.ENTER_VOUCHER_CODE]: `Introduce el código de cupón`,
  [k.VOUCHER_CODE]: `Código de descuento`,
  [k.INVALID_CODE]: `Lamentablemente no es un código válido!`,
  [k.WANT_CUSTOM_LOGO]: `¿Quieren utilizar un logo propio para la boda?`,
  [k.INDIVIDUAL_LOGO]: `Logo personalizado`,
  [k.STANDARD_LOGO]: `Logo estándar`,
  [k.PRIVATE_PURCHASE]: `La compra es privada`,
  [k.COMMERCIAL_PURCHASE]: `La compra es comercial`,
  [k.MAX_GUESTS]: 'Free Version: Maximum Number of Guests',
  [k.MAX_GUESTS_REACHED]:
    'This wedding is still using the free basic version of EureHochzeitOnline.com but now has more than five registered guests. According to our terms of use, an upgrade to a complete or premium package is required. Therefore, please upgrade your package to continue using your wedding website as usual.',
  [k.ACTIVATE_PACKAGE]: 'Unlock Package',
  [k.IF_MESSAGE_WRONGFUL]: 'If you believe this message is displayed incorrectly, please send us an email at:',
};
